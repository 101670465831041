// src/components/User/ResetPassword.js

import React, {useState} from 'react';
import {useParams, useNavigate} from 'react-router-dom';
import InputWithLabel from "../Form/Input/InputWithLabel";
import api from '../../api';
import {useNotification} from '../../context/NotificationContext'; // Импортируем хук уведомлений

const ResetPassword = () => {
    // Получаем параметры из URL (uid и token)
    const {uid, token} = useParams();

    // Состояние формы
    const [formData, setFormData] = useState({
        password: '',
        confirmPassword: ''
    });

    // Состояние ошибок
    const [errors, setErrors] = useState({});

    // Состояние загрузки
    const [loading, setLoading] = useState(false);

    // Получаем функцию showNotification из контекста уведомлений
    const {showNotification} = useNotification();

    // Инициализируем навигацию
    const navigate = useNavigate();

    // Обработчик изменения полей формы
    const handleChange = (e) => {
        const {name, value} = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const validatePassword = (password) => {
        const errors = [];

        if (password.length < 8) {
            errors.push('Password must be at least 8 characters long.');
        }
        if (!/[A-Z]/.test(password)) {
            errors.push('Password must contain at least one uppercase letter.');
        }
        if (!/[a-z]/.test(password)) {
            errors.push('Password must contain at least one lowercase letter.');
        }
        if (!/\d/.test(password)) {
            errors.push('Password must contain at least one digit.');
        }
        if (!/[!@#$%^&*(),.?":{}|<>]/.test(password)) {
            errors.push('Password must contain at least one special character (!@#$%^&*(),.?":{}|<>).');
        }
        // Запрет на пробелы
        if (/\s/.test(password)) {
            errors.push('Password must not contain spaces.');
        }
        // Запрет на дополнительные запрещённые символы
        const forbiddenCharacters = /["']/; // Например, запрет кавычек
        if (forbiddenCharacters.test(password)) {
            errors.push('Password must not contain forbidden characters like " or \'.');
        }

        return errors;
    };

    // Функция валидации формы
    const validateForm = () => {
        const newErrors = {};

        const passwordErrors = validatePassword(formData.password);
        if (passwordErrors.length > 0) {
            newErrors.password = passwordErrors.join(' ');
        }
        
        if (!formData.confirmPassword) {
            newErrors.confirmPassword = 'Please confirm your password.';
        } else if (formData.password !== formData.confirmPassword) {
            newErrors.confirmPassword = 'Passwords do not match.';
        }

        return newErrors;
    };

    // Обработчик отправки формы
    const handleSubmit = async (e) => {
        e.preventDefault();
        setErrors({});

        // Валидация формы
        const validationErrors = validateForm();

        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);

            // Собираем все сообщения об ошибках
            const errorMessages = Object.values(validationErrors).join(' ');

            // Отображаем уведомление об ошибке
            showNotification(
                'Please correct the errors in the form.',
                errorMessages, // Передаем собранные сообщения об ошибках
                null, // Можно добавить дополнительное сообщение, если необходимо
                'error'
            );

            return;
        }

        setLoading(true);

        try {
            const response = await api.post('/password-reset-confirm/', {
                uid,
                token,
                new_password: formData.password
            });

            if (response.status === 200) {
                // Отображаем уведомление об успешном сбросе пароля
                showNotification(
                    'Your password has been reset successfully.',
                    'You will be redirected to the Sign In page shortly.',
                    null,
                    'success'
                );

                // Перенаправление на страницу входа через 5 секунд
                setTimeout(() => {
                    navigate('/sign-in');
                }, 5000);
            } else {
                // Обработка неожиданных статусов ответа
                showNotification(
                    'Password reset failed. Please try again.',
                    null,
                    null,
                    'error'
                );
            }
        } catch (error) {
            console.error('Error response:', error.response);

            if (error.response && error.response.data) {
                const {status, data} = error.response;

                if (status === 400 && data.error) {
                    showNotification(
                        'Password reset failed.',
                        data.error,
                        null,
                        'error'
                    );
                    setErrors({password: data.error});
                } else {
                    showNotification(
                        'Something went wrong. Please try again later.',
                        null,
                        null,
                        'error'
                    );
                }
            } else {
                // Отображаем уведомление о сетевой ошибке
                showNotification(
                    'Network error.',
                    'Please check your connection.',
                    null,
                    'error'
                );
            }
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            {/* Рендеринг контейнера формы */}
            <div className="main-form-container">
                {/* Секция изображения */}
                <div
                    className="main-form__img"
                    style={{
                        backgroundColor: '#f5f5f5',
                        backgroundImage: "url('/assets/sign-in-image.png')",
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: '50% 50%',
                        backgroundSize: 'cover',
                    }}
                ></div>

                {/* Секция формы */}
                <div className="main-form__right">
                    <form className="main-form__content" onSubmit={handleSubmit}>
                        <div className="scroll-wrap">
                            <h2>Reset Password</h2>

                            {/* Поле ввода нового пароля */}
                            <InputWithLabel
                                label='New Password'
                                name='password'
                                value={formData.password}
                                onChange={handleChange}
                                type='password'
                                hasError={!!errors.password} // Используем hasError для отображения ошибки
                            />

                            {/* Поле подтверждения нового пароля */}
                            <InputWithLabel
                                label='Confirm New Password'
                                name='confirmPassword'
                                value={formData.confirmPassword}
                                onChange={handleChange}
                                type='password'
                                hasError={!!errors.confirmPassword} // Используем hasError для отображения ошибки
                            />

                            {/* Кнопка отправки формы */}
                            <button className="button button-black" type="submit" disabled={loading}>
                                {loading ? 'Resetting...' : 'Reset Password'}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
};

export default ResetPassword;
