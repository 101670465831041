import React, { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import api from '../../api'; // Axios instance
import { useNotification } from '../../context/NotificationContext'; // Notification context

const VerifyEmail = () => {
    const { token } = useParams();
    const navigate = useNavigate();
    const { showNotification } = useNotification();

    useEffect(() => {
        const verifyEmail = async () => {
            try {
                const response = await api.get(`/verify-email/${token}/`);
                if (response.status === 200) {
                    showNotification(
                        'Email Verified',
                        'Your email has been successfully verified.',
                        null,
                        'success'
                    );
                    navigate('/sign-up'); // Редирект на sign-up
                }
            } catch (error) {
                console.error('Error verifying email:', error);
                showNotification(
                    'Verification Failed',
                    'The verification token is invalid or expired.',
                    null,
                    'error'
                );
                navigate('/sign-up'); // Редирект на sign-up
            }
        };

        if (token) {
            verifyEmail();
        } else {
            showNotification(
                'Invalid Token',
                'No verification token provided.',
                null,
                'error'
            );
            navigate('/');
        }
    }, [token, navigate, showNotification]);

    return (
        <>
        </>
    );
};

export default VerifyEmail;
