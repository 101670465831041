// src/components/User/SignUp.js

import React, {useState, useEffect} from 'react';
import {useNavigate, Link} from 'react-router-dom';
import InputWithLabel from "../Form/Input/InputWithLabel";
import api from '../../api';
import {useNotification} from '../../context/NotificationContext';
import {useUser} from '../../context/UserContext';
import Loader from "../Loader";
import GoogleButton from '../Form/GoogleButton';
import FacebookButton from '../Form/FacebookButton';
import getLogoUrl from '../../utils/getLogoUrl';
import RegistrationToggle from './RegistrationToggle'; // Импортируем новый компонент

const SignUp = () => {
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        confirmPassword: '',
        agreeToTerms: false
    });
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const [registrationType, setRegistrationType] = useState(null); // 'vendor' или 'lawFirm'
    const [signUpImage, setSignUpImage] = useState(''); // Default image
    const {showNotification} = useNotification();
    const {setAuthTokens, setCreateVendorFlag, setCreateLawFirmFlag} = useUser();
    const navigate = useNavigate();

    useEffect(() => {
        // Redirect to profile if already authenticated
        const accessToken = localStorage.getItem('access_token');
        if (accessToken) {
            navigate('/profile');
        }
    }, [navigate]);

    useEffect(() => {
        // Fetch a random background image for SignUp
        const fetchSignUpImage = async () => {
            try {
                const response = await api.get('/random-signup-photo/'); // Reuse the same endpoint

                if (response.data.image) {
                    const logoUrl = getLogoUrl(response.data.image);
                    setSignUpImage(logoUrl);
                } else {
                    // Use default image if none returned
                    setSignUpImage('/assets/sign-in-image.png');
                }
            } catch (error) {
                console.error('Error fetching sign-up image:', error);
                // Use default image in case of error
                setSignUpImage('/assets/sign-in-image.png');
            }
        };

        fetchSignUpImage();
    }, []);

    const handleToggle = (type) => {
        setRegistrationType(prevType => (prevType === type ? null : type));
    };

    const handleChange = (e) => {
        const {name, value, type, checked} = e.target;

        // Удаляем лишние пробелы
        const trimmedValue = value
            .replace(/^\s+/, '') // Убираем пробелы в начале
            .replace(/\s{2,}/g, ' ') // Убираем лишние пробелы между словами
            .replace(/\s+$/, ''); // Убираем пробелы в конце

        // Допустимые символы: буквы, пробел, дефис, точка
        const validCharacters = /^[a-zA-Zа.\- ]*$/;
        const correctedValue = trimmedValue.endsWith('. ') ? trimmedValue.slice(0, -2) + ' ' : trimmedValue;
        let isValid = true; // Флаг валидации

        if (name === 'firstName' || name === 'lastName') {
            // Валидация для firstName и lastName: только буквы, пробел, дефис, точка
            const validCharacters = /^[a-zA-Zа.\- ]*$/;
            isValid = validCharacters.test(trimmedValue);
        }

        if (name === 'email') {
            // Валидация для email: разрешить буквы, цифры, @, _, ., и дефис
            const validEmailCharacters = /^[a-zA-Z0-9@_.\-]*$/;
            isValid = validEmailCharacters.test(trimmedValue);
        }


        if (isValid || type === 'checkbox') {
            const trimmedValue = value
                .replace(/^\s+/, '') // Убираем пробелы в начале
                .replace(/\s{2,}/g, '') // Убираем лишние пробелы между словами

            setFormData({
                ...formData,
                [name]: type === 'checkbox' ? checked : trimmedValue
            });
        }
    };

    const validatePassword = (password) => {
        const errors = [];

        if (password.length < 8) {
            errors.push('Password must be at least 8 characters long.');
        }
        // if (!/[A-Z]/.test(password)) {
        //     errors.push('Password must contain at least one uppercase letter.');
        // }
        if (!/[a-z]/.test(password)) {
            errors.push('Password must contain at least one lowercase letter.');
        }
        // if (!/\d/.test(password)) {
        //     errors.push('Password must contain at least one digit.');
        // }
        // if (!/[!@#$%^&*(),.?":{}|<>]/.test(password)) {
        //     errors.push('Password must contain at least one special character (!@#$%^&*(),.?":{}|<>).');
        // }
        // Запрет на пробелы
        if (/\s/.test(password)) {
            errors.push('Password must not contain spaces.');
        }
        // Запрет на дополнительные запрещённые символы
        const forbiddenCharacters = /["']/; // Например, запрет кавычек
        if (forbiddenCharacters.test(password)) {
            errors.push('Password must not contain forbidden characters like " or \'.');
        }

        return errors;
    };


    const validateForm = () => {
        const newErrors = {};

        // Проверяем пароль
        const passwordErrors = validatePassword(formData.password);
        if (passwordErrors.length > 0) {
            newErrors.password = passwordErrors.join(' ');
        }

        if (!formData.firstName.trim()) {
            newErrors.firstName = 'First name is required.';
        }

        if (!formData.lastName.trim()) {
            newErrors.lastName = 'Last name is required.';
        }
        // Проверка допустимых символов
        const validCharacters = /^[a-zA-Zа.\- ]*$/;

        if (!validCharacters.test(formData.firstName)) {
            newErrors.firstName = 'First name contains invalid characters.';
        }

        if (!validCharacters.test(formData.lastName)) {
            newErrors.lastName = 'Last name contains invalid characters.';
        }

        if (!formData.email) {
            newErrors.email = 'Email is required.';
        } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
            newErrors.email = 'Email is invalid.';
        }

        // if (!formData.password) {
        //     newErrors.password = 'Password is required.';
        // } else if (formData.password.length < 8) {
        //     newErrors.password = 'Password must be at least 8 characters.';
        // }

        if (!formData.confirmPassword) {
            newErrors.confirmPassword = 'Please confirm your password.';
        } else if (formData.password !== formData.confirmPassword) {
            newErrors.confirmPassword = 'Passwords do not match.';
        }

        if (!formData.agreeToTerms) {
            newErrors.agreeToTerms = 'You must agree to the terms of service and privacy policy.';
        }

        return newErrors;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setErrors({});
        // Удаляем пробелы только перед валидацией
        const cleanedFormData = {
            ...formData,
            firstName: formData.firstName.trim(),
            lastName: formData.lastName.trim(),
            email: formData.email.trim().replace(/\.$/, ''),
            password: formData.password.trim(),
            confirmPassword: formData.confirmPassword.trim(),
        };

        const validationErrors = validateForm(cleanedFormData);

        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);

            const errorMessages = Object.values(validationErrors).join(' ');

            showNotification(
                'Please correct the errors in the form.',
                errorMessages,
                null,
                'error'
            );

            return;
        }

        setLoading(true);

        try {
            const emailLower = formData.email.toLowerCase();

            const response = await api.post('/register/', {
                first_name: formData.firstName,
                last_name: formData.lastName,
                email: emailLower,
                password: formData.password,
                confirm_password: formData.confirmPassword,
                registration_type: registrationType // Передаём тип регистрации
            });

            if (response.status === 201) {
                showNotification(
                    'Your account has been created successfully.',
                    'Please check your email to verify your account.',
                    null,
                    'success'
                );

                try {
                    const loginResponse = await api.post('/token/', {
                        username: emailLower,
                        password: formData.password
                    });

                    if (loginResponse.status === 200) {
                        const tokens = {
                            access: loginResponse.data.access,
                            refresh: loginResponse.data.refresh
                        };

                        setAuthTokens(tokens);
                        localStorage.setItem('access_token', tokens.access);
                        localStorage.setItem('refresh_token', tokens.refresh);
                        if (registrationType === 'vendor') {
                            setCreateVendorFlag();
                        }
                        if (registrationType === 'lawFirm') {
                            setCreateLawFirmFlag();
                        }

                        navigate('/profile');
                    } else {
                        showNotification(
                            'Login failed after registration. Please sign in manually.',
                            null,
                            null,
                            'error'
                        );

                        setTimeout(() => {
                            navigate('/sign-in');
                        }, 5000);
                    }
                } catch (loginError) {
                    showNotification(
                        'Automatic login failed. Please sign in manually.',
                        null,
                        null,
                        'error'
                    );

                    setTimeout(() => {
                        navigate('/sign-in');
                    }, 5000);
                }
            } else {
                setErrors({
                    email: response.data.detail || 'Registration failed.',
                    password: ' '
                });

                showNotification(
                    'Registration failed. Please try again.',
                    null,
                    null,
                    'error'
                );
            }
        } catch (error) {
            if (error.response && error.response.data) {
                const errorMessage = error.response.data.error || 'An error occurred during registration.';
                showNotification(
                    'Registration Error',
                    errorMessage,
                    null,
                    'error'
                );

                setErrors(error.response.data);
            } else {
                showNotification(
                    'Something went wrong. Please try again later.',
                    'Unable to connect to the server.',
                    null,
                    'error'
                );
            }
        } finally {
            setLoading(false);
        }
    };


    if (loading) {
        return <Loader/>
    }

    return (
        <div className="main-form-container">
            <div
                className="main-form__img"
                style={{
                    backgroundColor: '#f5f5f5',
                    backgroundImage: `url(${signUpImage})`, // Use the fetched random image
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: '50% 50%',
                    backgroundSize: 'cover',
                }}
            ></div>

            <div className="main-form__right">
                <form className="main-form__content" onSubmit={handleSubmit}>
                    <div className="scroll-wrap">
                        <h2>Create Account</h2>
                        <div className="sign-up-link">
                            <span>Already have an account? </span>
                            <Link to="/sign-in">Sign In</Link>
                        </div>

                        <InputWithLabel
                            label='First Name'
                            name='firstName'
                            value={formData.firstName}
                            onChange={handleChange}
                            type='text'
                            autoCorrect="off" // Отключение автокоррекции
                            maxLength={50} // Ограничение символов
                            hasError={!!errors.firstName}
                        />

                        <InputWithLabel
                            label='Last Name'
                            name='lastName'
                            value={formData.lastName}
                            onChange={handleChange}
                            type='text'
                            autoCorrect="off" // Отключение автокоррекции
                            maxLength={50} // Ограничение символов
                            hasError={!!errors.lastName}
                        />

                        <InputWithLabel
                            label='E-mail'
                            name='email'
                            value={formData.email}
                            onChange={handleChange}
                            type='email'
                            maxLength={100} // Ограничение символов
                            hasError={!!errors.email}
                        />

                        <InputWithLabel
                            label='Password'
                            name='password'
                            value={formData.password}
                            onChange={handleChange}
                            type='password'
                            hasError={!!errors.password}
                        />

                        <InputWithLabel
                            label='Confirm Password'
                            name='confirmPassword'
                            value={formData.confirmPassword}
                            onChange={handleChange}
                            type='password'
                            maxLength={100}
                            hasError={!!errors.confirmPassword}
                        />

                        <div className="checkbox-container">
                            <label className="label-checkbox">
                                <input
                                    type="checkbox"
                                    name="agreeToTerms"
                                    checked={formData.agreeToTerms}
                                    onChange={handleChange}
                                    maxLength={100}
                                />
                                <div className="psevdocheckbox"></div>
                            </label>
                            <span className="text-left">
                                By signing up you agree to our&nbsp;
                                <a href="/terms-of-use" className="checkbox__link" target="_blank"
                                   rel="noopener noreferrer">Terms of Use</a> and&nbsp;
                                <a href="/privacy-policy" className="checkbox__link" target="_blank"
                                   rel="noopener noreferrer">Privacy Policy</a>.
                            </span>
                        </div>

                        {/* Переключатели для Vendor и Law Firm Registration */}
                        <div className='registration-toggle-container'>
                            <RegistrationToggle
                                label="Register as Vendor"
                                type="vendor"
                                isChecked={registrationType === 'vendor'}
                                onToggle={() => handleToggle('vendor')}
                            />
                            <RegistrationToggle
                                label="Register as Law Firm"
                                type="lawFirm"
                                isChecked={registrationType === 'lawFirm'}
                                onToggle={() => handleToggle('lawFirm')}
                            />
                        </div>

                        <button className="button button-black" type="submit" disabled={loading}>
                            {loading ? 'Registering...' : 'Sign Up'}
                        </button>

                        {/* Third-Party Authentication Buttons */}
                        <GoogleButton redirectPath="/profile"/>
                        {/* <FacebookButton redirectPath="/profile" /> */}
                    </div>
                </form>
            </div>
        </div>
    );
};

export default SignUp;
