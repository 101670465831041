import React, { useState, useEffect } from 'react';
import api from '../api'; // Импортируем модуль API

const baseUrl = process.env.REACT_APP_BASE_URL;

const LawCompanyAdvertise = () => {
    const [advertise, setAdvertise] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchAdvertise = async () => {
            try {
                const response = await api.get('/advertise/');
                setAdvertise(response.data);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching the advertise data:', error);
                setLoading(false);
            }
        };

        fetchAdvertise();
    }, []);

    const formatPhoneNumber = (phoneNumber) => {
        let cleaned = ('' + phoneNumber).replace(/\D/g, '');
        if (!cleaned.startsWith('1')) {
            cleaned = '1' + cleaned;
        }
        const match = cleaned.match(/^(\d{1})(\d{3})(\d{3})(\d{4})$/);
        if (match) {
            return `+${match[1]} (${match[2]}) ${match[3]}-${match[4]}`;
        }
        return phoneNumber;
    };

    const getLogoUrl = (logo) => {
        // Проверяем, является ли logo полным URL
        if (logo && !logo.startsWith('http://') && !logo.startsWith('https://')) {
            const modifiedLogo = `${baseUrl}${logo}`;
            return modifiedLogo;
        }

        // Если logo уже содержит полный URL, возвращаем его без изменений
        return logo;
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    if (!advertise) {
        return <div>Advertise not found</div>;
    }

    const {
        title,
        service_or_business,
        description,
        logo,
        phone,
        social_media,
        address,
        link
    } = advertise;

    const formattedAddress = address
        ? `${address.street_address}, ${address.city.name}, ${address.state.name}`
        : 'Address not available';

    const googleMapsUrl = `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(formattedAddress)}`;
    const appleMapsUrl = `http://maps.apple.com/?q=${encodeURIComponent(formattedAddress)}`;

    return (
        <div className={`vendor vendor-advertise`}>
            <div className="container">
                <div className="justify">
                    {logo && (
                        <div className="vendor__logo js--show-popup" data-popup="attorney-type1">
                            <picture>
                                <source type="image/webp" srcSet={`${getLogoUrl(logo)} 1x, ${getLogoUrl(logo)} 2x`} />
                                <source type="image/jpg" srcSet={`${getLogoUrl(logo)} 1x, ${getLogoUrl(logo)} 2x`} />
                                <img srcSet={`${getLogoUrl(logo)} 1x, ${getLogoUrl(logo)} 2x`}
                                     alt={title} />
                            </picture>
                        </div>
                    )}
                    <div
                        className="vendor__data"
                        onClick={() => window.open(link, '_blank')}
                        style={{ cursor: 'pointer' }}
                    >
                        <div className="vendor__header">
                            <h4 className="vendor__name">{title}</h4>
                            <div className="verified">
                                <img src="assets/icon--verified-white.svg" alt="Verified" />
                            </div>
                        </div>
                        <span className="vendor__attorney">{service_or_business}</span>
                        <p className="vendor__description">{description}</p>
                    </div>
                    <div className="vendor__contacts">
                        <div className="row">
                            <a className="vendor__link" href={googleMapsUrl} target="_blank"
                               rel="noopener noreferrer">{formattedAddress}</a>
                            <span className="vendor__label">Address</span>
                        </div>
                        <div className="row">
                            <a className="vendor__link" href={`tel:${phone}`}>{formatPhoneNumber(phone)}</a>
                            <span className="vendor__label">Phone</span>
                        </div>
                        <div className="row">
                            <ul className="social">
                                {social_media.map((sm, index) => (
                                    <li key={index}>
                                        <a href={sm.url} target="_blank" rel="noopener noreferrer">
                                            <img
                                                src={`${getLogoUrl(sm.platform.icon)}`}
                                                alt={sm.platform.name} />
                                        </a>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LawCompanyAdvertise;
