// SearchContext.js
import React, { createContext, useState, useMemo, useCallback } from 'react';
import api from '../api';

export const SearchContext = createContext();

export const SearchProvider = ({ children }) => {
    const [searchSended, setSearchSended] = useState(false);
    const [searchVendorSended, setSearchVendorSended] = useState(false);
    const [searchLawFirmSended, setSearchLawFirmSended] = useState(false);
    const [loading, setLoading] = useState(false);
    const [loadingMore, setLoadingMore] = useState(false);
    const [cityForTitle, setCityForTitle] = useState();
    const [companiesForTitle, setCompaniesForTitle] = useState();
    const [searchResults, setSearchResults] = useState([]);
    const [lawFirmResults, setLawFirmResults] = useState([]);
    const [vendorResults, setVendorResults] = useState([]);
    const [selectedCompany, setSelectedCompany] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [lawFirmPage, setLawFirmPage] = useState(1);
    const [vendorPage, setVendorPage] = useState(1);
    const [totalResults, setTotalResults] = useState(0);
    const [lawFirmTotalResults, setLawFirmTotalResults] = useState(0);
    const [vendorTotalResults, setVendorTotalResults] = useState(0);
    const [totalPages, setTotalPages] = useState(1);
    const [lawFirmTotalPages, setLawFirmTotalPages] = useState(1);
    const [vendorTotalPages, setVendorTotalPages] = useState(1);
    const [specialization, setSpecialization] = useState(null);
    const [problem, setProblem] = useState(null);
    const [cities, setCities] = useState([]);
    const [states, setStates] = useState([]);
    const [zipCode, setZipCode] = useState(null);
    const [language, setLanguage] = useState([]);
    const [freeConsultation, setFreeConsultation] = useState(null);
    const [foundationYears, setFoundationYears] = useState([]);
    const [suggestion, setSuggestion] = useState('');
    const [testCount, setTestCount] = useState(22);
    const [bannerHeight, setBannerHeight] = useState(0); // Новое состояние для высоты баннера
    const [showAdvanced, setShowAdvanced] = useState(false); // Добавляем состояние showAdvanced
    const [searchSource, setSearchSource] = useState(''); // Хранит источник поиска

    const calculateTotalPages = useCallback((count, pageSize) => {
        return Math.ceil(count / pageSize);
    }, []);

    const updateSearchResults = useCallback(
        ({ results, count, page, suggestion }, specializationData) => {
            const pageSize = 7;
            const calculatedTotalPages = calculateTotalPages(count, pageSize);

            setSearchResults((prevResults) => {
                const newResults = page === 1 ? results : [...prevResults, ...results];
                return newResults;
            });

            // Убедимся, что сохраняем полный объект специализации
            if (specializationData) {
                setSpecialization(specializationData);
            }

            setCurrentPage(page);
            setTotalResults(count);
            setTotalPages(calculatedTotalPages);
            setSuggestion(suggestion);
        },
        [calculateTotalPages]
    );

    const updateLawFirmResults = useCallback(
        ({ results, count, page, suggestion }) => {
            const pageSize = 7;
            const calculatedTotalPages = calculateTotalPages(count, pageSize);

            setLawFirmResults((prevResults) => {
                const updatedResults = page === 1 ? results : [...prevResults, ...results];
                return updatedResults;
            });

            setLawFirmPage(page);
            setLawFirmTotalResults(count);
            setLawFirmTotalPages(calculatedTotalPages);
            setSuggestion(suggestion);
        },
        [calculateTotalPages]
    );

    const updateVendorResults = useCallback(
        ({ results, count, page, suggestion }) => {
            const pageSize = 7;
            const calculatedTotalPages = calculateTotalPages(count, pageSize);

            setVendorResults((prevResults) => {
                const newResults = page === 1 ? results : [...prevResults, ...results];
                return newResults;
            });
            setVendorPage(page);
            setVendorTotalResults(count);
            setVendorTotalPages(calculatedTotalPages);
            setSuggestion(suggestion);
        },
        [calculateTotalPages]
    );

    const fetchMoreCompanies = useCallback(
        async (page) => {
            console.log('Fetching more companies with specialization:', specialization);
            setLoadingMore(true);
            try {
                const requestData = {
                    specialization: specialization || { id: null, name: null }, // Обеспечиваем наличие объекта
                    problem,
                    cities: cities.map((city) => city.city_id),
                    states: states.map((state) => state.state_id),
                    zip_code: zipCode,
                    language,
                    free_consultation: freeConsultation,
                    foundation_years: foundationYears,
                    page,
                };

                const response = await api.post(`/get-filtered-specialization/`, requestData);
                const { results, count, suggestion } = response.data;
                updateSearchResults({ results, count, page, suggestion }, specialization);
            } catch (error) {
                console.error('Error fetching more companies:', error);
            } finally {
                setLoadingMore(false);
            }
        },
        [
            specialization,
            problem,
            cities,
            states,
            zipCode,
            language,
            freeConsultation,
            foundationYears,
            updateSearchResults,
        ]
    );

    const fetchMoreLawFirms = useCallback(
        async (page) => {
            setLoadingMore(true);
            try {
                const requestData = {
                    specialization,
                    cities: cities.map((city) => city.city_id),
                    states: states.map((state) => state.state_id),
                    zip_code: zipCode,
                    language,
                    free_consultation: freeConsultation,
                    foundation_years: foundationYears,
                    page,
                };
                const response = await api.post(`/law-firms/`, requestData);
                const { results, count } = response.data;
                updateLawFirmResults({ results, count, page });
            } catch (error) {
                console.error('Error fetching more law firms:', error);
            } finally {
                setLoadingMore(false);
            }
        },
        [
            specialization,
            cities,
            states,
            zipCode,
            language,
            freeConsultation,
            foundationYears,
            updateLawFirmResults,
        ]
    );

    const fetchMoreVendors = useCallback(
        async (page) => {
            setLoadingMore(true);
            try {
                const requestData = {
                    specialization,
                    cities: cities.map((city) => city.city_id),
                    states: states.map((state) => state.state_id),
                    zip_code: zipCode,
                    language,
                    free_consultation: freeConsultation,
                    foundation_years: foundationYears,
                    page,
                };
                const response = await api.post(`/vendors-searching/`, requestData);
                const { results, count } = response.data;
                updateVendorResults({ results, count, page });
            } catch (error) {
                console.error('Error fetching more vendors:', error);
            } finally {
                setLoadingMore(false);
            }
        },
        [
            specialization,
            cities,
            states,
            zipCode,
            language,
            freeConsultation,
            foundationYears,
            updateVendorResults,
        ]
    );

    const openPopup = useCallback(
        (companyId) => {
            const company =
                searchResults.find((c) => c.id === companyId) ||
                lawFirmResults.find((c) => c.id === companyId) ||
                vendorResults.find((c) => c.id === companyId);
            setSelectedCompany(company);
        },
        [searchResults, lawFirmResults, vendorResults]
    );

    const closePopup = useCallback(() => {
        setSelectedCompany(null);
    }, []);

    const changeSearchLawFirmSended = useCallback(() => setSearchLawFirmSended(true), []);
    const changeSearchVendorSended = useCallback(() => setSearchVendorSended(true), []);
    const changeSearchSended = useCallback(() => setSearchSended(true), []);
    const changeCityForTitle = useCallback((data) => setCityForTitle(data), []);
    const changeCompaniesForTitle = useCallback((data) => setCompaniesForTitle(data), []);

    const contextValue = useMemo(
        () => ({
            searchVendorSended,
            changeSearchVendorSended,
            searchLawFirmSended,
            changeSearchLawFirmSended,
            searchResults,
            setSearchResults: updateSearchResults,
            lawFirmResults,
            setLawFirmResults: updateLawFirmResults,
            vendorResults,
            setVendorResults: updateVendorResults,
            fetchMoreCompanies,
            fetchMoreLawFirms,
            fetchMoreVendors,
            openPopup,
            closePopup,
            selectedCompany,
            searchSended,
            changeSearchSended,
            loading,
            setLoading,
            loadingMore,
            cityForTitle,
            changeCityForTitle,
            companiesForTitle,
            changeCompaniesForTitle,
            currentPage,
            setCurrentPage,
            lawFirmPage,
            setLawFirmPage,
            vendorPage,
            setVendorPage,
            totalResults,
            lawFirmTotalResults,
            vendorTotalResults,
            totalPages,
            lawFirmTotalPages,
            vendorTotalPages,
            specialization,
            setSpecialization,
            problem,
            setProblem,
            cities,
            setCities,
            states,
            setStates,
            zipCode,
            setZipCode,
            language,
            setLanguage,
            freeConsultation,
            setFreeConsultation,
            foundationYears,
            setFoundationYears,
            suggestion,
            testCount,
            bannerHeight,
            setBannerHeight,
            showAdvanced,
            setShowAdvanced,
            searchSource,
            setSearchSource,
            setSearchSended,
        }),
        [
            searchVendorSended,
            changeSearchVendorSended,
            searchLawFirmSended,
            changeSearchLawFirmSended,
            searchResults,
            updateSearchResults,
            lawFirmResults,
            updateLawFirmResults,
            vendorResults,
            updateVendorResults,
            fetchMoreCompanies,
            fetchMoreLawFirms,
            fetchMoreVendors,
            openPopup,
            closePopup,
            selectedCompany,
            searchSended,
            changeSearchSended,
            loading,
            setLoading,
            loadingMore,
            cityForTitle,
            changeCityForTitle,
            companiesForTitle,
            changeCompaniesForTitle,
            currentPage,
            setCurrentPage,
            lawFirmPage,
            setLawFirmPage,
            vendorPage,
            setVendorPage,
            totalResults,
            lawFirmTotalResults,
            vendorTotalResults,
            totalPages,
            lawFirmTotalPages,
            vendorTotalPages,
            specialization,
            setSpecialization,
            problem,
            setProblem,
            cities,
            setCities,
            states,
            setStates,
            zipCode,
            setZipCode,
            language,
            setLanguage,
            freeConsultation,
            setFreeConsultation,
            foundationYears,
            setFoundationYears,
            suggestion,
            testCount,
            bannerHeight,
            setBannerHeight,
            showAdvanced,
            setShowAdvanced,
            searchSource,
            setSearchSource,
            setSearchSended,
        ]
    );

    return <SearchContext.Provider value={contextValue}>{children}</SearchContext.Provider>;
};
