// src/components/SendMessageModal.jsx
import React, {useState, useCallback, useEffect} from 'react';
import ReactDOM from 'react-dom'; // For using portals
import InputWithLabel from "../Form/Input/InputWithLabel";
import TextareaWithLabel from "../Form/Input/TextareaWithLabel";
import InputFile from "../Form/Input/InputFile";
import api from '../../api';
import {useUser} from '../../context/UserContext';

export default function SendMessageModal({show, recipient, recipientType, onClose}) {
    const {user, isAuthenticated} = useUser(); // Assuming you have a UserContext or authentication hook
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone_number: '',
        subject: '',
        message: '',
        specialization: ''
    });
    const [specializations, setSpecializations] = useState([]);
    const [errors, setErrors] = useState({});
    const [files, setFiles] = useState([]);
    const [emailSent, setEmailSent] = useState(false);
    const [verificationRequired, setVerificationRequired] = useState(false);

    useEffect(() => {
        if (isAuthenticated && user) {
            setFormData(prevData => ({
                ...prevData,
                name: user.name || `${user.first_name} ${user.last_name}` || '',
                email: user.email || '',
                phone_number: user.phone_number || user.phone || '',
            }));
        }
    }, [isAuthenticated, user]);

    useEffect(() => {
        // Load the list of specializations from the API
        const fetchSpecializations = async () => {
            try {
                let response;
                if (recipientType === 'legalcompany' && recipient && recipient.id) {
                    // Fetch specializations associated with the legal company's attorneys
                    response = await api.get(`/company/${recipient.id}/specializations/`);
                    console.log('Specializations fetched (legalcompany):', response.data); // Debug log
                } else if (recipientType === 'attorney' && recipient && recipient.attorney_id) {
                    // Fetch specializations associated with the attorney
                    response = await api.get(`/attorney/${recipient.attorney_id}/specializations/`);
                    console.log('Specializations fetched (attorney):', response.data); // Debug log
                } else {
                    // Fetch all specializations
                    response = await api.get('/get-specialization/');
                    console.log('Specializations fetched (all):', response.data); // Debug log
                }
                // Transform the data to match the standard select's expected format
                const transformedSpecializations = response.data.map(spec => ({
                    value: spec.specialization_id, // Ensure this matches your data structure
                    label: spec.name,
                }));
                // Optionally, add a default empty option
                setSpecializations([{value: '', label: 'Select a Specialization'}, ...transformedSpecializations]);
            } catch (error) {
                console.error('Error loading specializations:', error);
            }
        };

        fetchSpecializations();
    }, [recipientType, recipient]);

    const handleChange = (e) => {
        const {name, value} = e.target;
        console.log(`Changing field: ${name}, value: ${value}`);  // Debug log
        setFormData((prevData) => ({
            ...prevData,
            [name]: value
        }));
    };

    const removeFile = (file) => {
        setFiles(files.filter((item) => item.name !== file.name));
    };

    const validateForm = () => {
        const newErrors = {};

        if (!formData.subject) {
            newErrors.subject = 'Subject is required';
        }

        if (!formData.message) {
            newErrors.message = 'Message cannot be empty';
        }

        if (!formData.specialization) {
            newErrors.specialization = 'Please select a specialization';
        }

        // If the user is not logged in, validate name and email
        if (!isAuthenticated) {
            if (!formData.name) {
                newErrors.name = 'Name is required';
            }

            if (!formData.email) {
                newErrors.email = 'Email is required';
            } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
                newErrors.email = 'Invalid email format';
            }
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!validateForm()) {
            return;
        }

        const formDataToSend = new FormData();
        Object.keys(formData).forEach((key) => {
            if (formData[key]) formDataToSend.append(key, formData[key]);
        });

        const recipientId = recipientType === 'attorney' ? recipient.attorney_id : recipient.id;
        formDataToSend.append('recipient_id', recipientId);
        formDataToSend.append('recipient_type', recipientType);

        // Append files with correct field name
        files.forEach((file) => {
            formDataToSend.append('attached_files', file); // Changed to 'attached_files'
        });

        // Log the data being sent
        for (let pair of formDataToSend.entries()) {
            if (pair[0] === 'attached_files') {
                console.log(`${pair[0]}: ${pair[1].name}`); // Log file names
            } else {
                console.log(`${pair[0]}: ${pair[1]}`);
            }
        }

        try {
            const response = await api.post('/send-message/', formDataToSend, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            console.log('Response:', response.data); // Debug log

            if (response.data.status === 'verification_required') {
                setVerificationRequired(true);
            } else if (response.data.status === 'message_sent') {
                setEmailSent(true);
                // Reset form after successful submission
                setFormData({
                    name: '',
                    email: '',
                    phone_number: '',
                    subject: '',
                    message: '',
                    specialization: ''
                });
                setFiles([]);
            }
        } catch (error) {
            console.error('Error sending message', error.response ? error.response.data : error);
            // You can add additional error handling here
        }
    };

    const handleClose = useCallback(() => {
        onClose();
    }, [onClose]);

    return ReactDOM.createPortal(
        <>
            {show && (
                <div className="modal visible">
                    <div className="modal__overlay" onClick={handleClose}></div>
                    <div className="modal__content">
                        <button onClick={handleClose} className="button no-style close js--close-modal">
                            <img src="/assets/icon--close-CN6QlgAA.svg" alt="Close Modal"/>
                        </button>
                        <div className="modal__form">
                            <form className="content" onSubmit={handleSubmit}>
                                {emailSent ? (
                                    <div>
                                        <form className="content">
                                            <img src="/assets/icon--sendok.svg" alt=""/>
                                            <h2>Your message has been sent successfully</h2>
                                            {/*<span>We will contact you soon and clarify the details</span>*/}
                                            <button type='button' onClick={handleClose}
                                                    className="button button-black">Okay
                                            </button>
                                        </form>
                                    </div>
                                ) : verificationRequired ? (
                                    <div>
                                        <form className="content">
                                            <img src="/assets/icon--sendok.svg" alt=""/>
                                            <h2>Your message has been sent successfully</h2>
                                            <span>Please verify your email. We have sent you an email with instructions.</span>
                                            <button type='button' onClick={handleClose}
                                                    className="button button-black">Okay
                                            </button>
                                        </form>
                                    </div>
                                ) : (
                                    <>
                                        <h2>Send Message</h2>
                                        {(!isAuthenticated || !user) && (
                                            <>
                                                <InputWithLabel
                                                    name="name"
                                                    label="Name*"
                                                    value={formData.name}
                                                    onChange={handleChange}
                                                    error={errors.name}
                                                />
                                                <InputWithLabel
                                                    name="email"
                                                    label="E-mail*"
                                                    value={formData.email}
                                                    onChange={handleChange}
                                                    type="email"
                                                    error={errors.email}
                                                />
                                                <InputWithLabel
                                                    name="phone_number"
                                                    label="Phone Number"
                                                    value={formData.phone_number}
                                                    onChange={handleChange}
                                                    type="tel"
                                                    error={errors.phone_number}
                                                />
                                            </>
                                        )}
                                        {isAuthenticated && user && (
                                            <>
                                                <InputWithLabel
                                                    name="name"
                                                    label="Name*"
                                                    value={formData.name}
                                                    onChange={handleChange}
                                                    error={errors.name}
                                                    disabled={true} // Make the field read-only
                                                />
                                                <InputWithLabel
                                                    name="email"
                                                    label="E-mail*"
                                                    value={formData.email}
                                                    onChange={handleChange}
                                                    type="email"
                                                    error={errors.email}
                                                    disabled={true}
                                                />
                                                <InputWithLabel
                                                    name="phone_number"
                                                    label="Phone Number"
                                                    value={formData.phone_number}
                                                    onChange={handleChange}
                                                    type="tel"
                                                    error={errors.phone_number}
                                                />
                                            </>
                                        )}
                                        <InputWithLabel
                                            name="subject"
                                            label="Subject*"
                                            value={formData.subject}
                                            onChange={handleChange}
                                            error={errors.subject}
                                        />
                                        <TextareaWithLabel
                                            name="message"
                                            label="Message*"
                                            value={formData.message}
                                            onChange={handleChange}
                                            error={errors.message}
                                        />

                                        {/* Replacing SingleSelect with standard <select> */}
                                        <select
                                            className={`input ${errors.specialization ? 'input-error' : ''}`}
                                            name="specialization"
                                            id="specialization"
                                            value={formData.specialization}
                                            onChange={handleChange}
                                        >
                                            {specializations.map((spec) => (
                                                <option key={spec.value} value={spec.value}>
                                                    {spec.label}
                                                </option>
                                            ))}
                                        </select>
                                        {errors.specialization && (
                                            <small className="error-message">{errors.specialization}</small>
                                        )}
                                        <InputFile files={files} setFiles={setFiles} removeFile={removeFile}/>
                                        <button type="submit" className="button button-black">
                                            Send
                                        </button>
                                    </>
                                )}
                            </form>
                        </div>
                    </div>
                </div>
            )}
        </>,
        document.body // Portal to body
    );
}
