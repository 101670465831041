// components/Popup.js
import React, {useState, useEffect, useContext, useCallback} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import PopupAttorney from './PopupAttorney';
import {Helmet} from 'react-helmet';
import './Popup.css';
import api from '../api';
import ZipCodes from './Header/ZipCodes';
import {SearchContext} from '../context/SearchContext';
import {useUser} from '../context/UserContext';
import EditLegalCompanyProfilePopup from './popups/EditProfilePopup';
import AddAttorneysPopup from './popups/AddAttorneysPopup.js';
import EditAwardsPopup from "./popups/EditAwardsPopup.js";
import Cookies from "js-cookie";
import EditServiceRegionsPopup from "./popups/EditServiceRegionsPopup";
import {useNotification} from '../context/NotificationContext';
import Notification from "./Notification";
import {validateImage} from '../utils/validateImage';
import {validateAndScaleImage} from '../utils/validateAndScaleImage';
import Loader from './Loader';
import SendMessageModal from "./popups/SendMessageModal";

// Импортируем CropImageModal
import CropImageModal from './popups/CropImageModal';

const IMAGE_REQUIREMENTS = {
    'legal_company_logo': {
        'min_width': 456,
        'min_height': 256,
        'aspect_ratio': 1.78,
        'ratio_tolerance': 0.05,
    },
    'background_company': {
        'min_width': 1440,
        'min_height': 280,
        'aspect_ratio': 5.14,
        'ratio_tolerance': 0.05,
    },
};

const Popup = ({visible, data, onClose, showSharePopup, origin, handleShowPopup}) => {
    const [loading, setLoading] = useState(true);
    const [showAllAttorneys, setShowAllAttorneys] = useState(false);
    const [selectedAttorney, setSelectedAttorney] = useState(null);
    const [expandedCities, setExpandedCities] = useState({});
    const location = useLocation();
    const [currentLegalCompany, setCurrentLegalCompany] = useState(data);
    const navigate = useNavigate();
    const [showEditProfilePopup, setShowEditProfilePopup] = useState(false);
    const [showEditAwardsPopup, setShowEditAwardsPopup] = useState(false);
    const [showAddAttorneysPopup, setShowAddAttorneysPopup] = useState(false);
    const [currentLogo, setLogo] = useState(data.logo);
    const [backgroundImage, setBackgroundImage] = useState(data.background_image);
    const [showEditServiceRegionsPopup, setShowEditServiceRegionsPopup] = useState(false);
    const {user} = useUser();
    const {showNotification} = useNotification();
    const [logoError, setLogoError] = useState(null);
    const [backgroundImageError, setBackgroundImageError] = useState(null);
    const [errors, setErrors] = useState({});
    const [showSendMessageModal, setShowSendMessageModal] = useState(false);

    // Состояния для кроппера
    const [showCropModal, setShowCropModal] = useState(false);
    const [imageToCrop, setImageToCrop] = useState(null);
    const [currentFieldToUpdate, setCurrentFieldToUpdate] = useState(null);
    const [currentAspectRatio, setCurrentAspectRatio] = useState(1.78); // По умолчанию для логотипа

    const getRequestConfig = () => {
        const token = localStorage.getItem('authToken');
        const csrfToken = Cookies.get('csrftoken');
        return {
            headers: {
                'Authorization': `Bearer ${token}`,
                'X-CSRFToken': csrfToken,
            },
            withCredentials: true,
        };
    };

    useEffect(() => {
        const query = new URLSearchParams(location.search);
        const companyId = query.get('companyId');
        const sessionId = query.get('session_id');
        const status = query.get('status');

        if (sessionId && companyId) {
            showNotification(
                'Subscription successfully completed!',
                'Your subscription has been activated.',
                null,
                'success'
            );
            navigate('/?companyId=' + companyId, {replace: true});
        } else if (status === 'cancel') {
            showNotification(
                'Subscription cancelled.',
                'You have cancelled the subscription process.',
                null,
                'warning'
            );
            navigate('/', {replace: true});
        }
    }, [location.search, navigate, showNotification]);

    const updateCompanyData = async (companyId, data, config) => {
        try {
            const response = await api.patch(`/legal-companies/${companyId}/update/`, data, config);
            return response;
        } catch (error) {
            console.error('Error updating company:', error);
            throw error;
        }
    };
    const handleFieldUpdate = async (fieldName, value) => {
        const formData = new FormData();
        formData.append(fieldName, value);

        try {
            const config = getRequestConfig();
            if (fieldName === 'logo' || fieldName === 'background_image') {
                const tempURL = URL.createObjectURL(value);
                if (fieldName === 'logo') {
                    setLogo(tempURL);
                } else if (fieldName === 'background_image') {
                    setBackgroundImage(tempURL);
                }
            }

            const response = await updateCompanyData(currentLegalCompany.id, formData, config);

            if (response.status === 200) {
                await fetchLegalCompanyData();
            }
        } catch (error) {
            console.error('Error updating field:', error);
        }
    };

    // При выборе лого запускаем кроп
    const handleLogoChange = async (event) => {
        const file = event.target.files[0];
        if (file) {
            const tempURL = URL.createObjectURL(file);
            setImageToCrop(tempURL);
            setCurrentFieldToUpdate('logo');
            setCurrentAspectRatio(IMAGE_REQUIREMENTS['legal_company_logo'].aspect_ratio);
            setShowCropModal(true);
        }
    };

    // При выборе фоновой
    const handleBackgroundImageChange = async (event) => {
        const file = event.target.files[0];
        if (file) {
            const tempURL = URL.createObjectURL(file);
            setImageToCrop(tempURL);
            setCurrentFieldToUpdate('background_image');
            setCurrentAspectRatio(IMAGE_REQUIREMENTS['background_company'].aspect_ratio);
            setShowCropModal(true);
        }
    };

    // Обработка результата после кропа
    // При успешном завершении кропа:
// Убедитесь, что путь к файлу соответствует вашей структуре проекта

// ...

    const handleCroppedImageComplete = async (croppedImageBlob) => {
        try {
            const file = new File([croppedImageBlob], 'cropped_image.jpg', {type: 'image/jpeg'});
            const requirements = (currentFieldToUpdate === 'logo')
                ? IMAGE_REQUIREMENTS['legal_company_logo']
                : IMAGE_REQUIREMENTS['background_company'];

            // Валидируем и при необходимости масштабируем файл
            const validatedAndScaledFile = await validateAndScaleImage(
                file,
                requirements.min_width,
                requirements.min_height,
                requirements.aspect_ratio,
                requirements.ratio_tolerance
            );

            // Если прошли все проверки (и возможно масштабирование), отправляем данные на сервер
            await handleFieldUpdate(currentFieldToUpdate, validatedAndScaledFile);

        } catch (error) {
            showNotification('Image Validation Error', error, null, 'error');
        }

        setShowCropModal(false);
        setImageToCrop(null);
        setCurrentFieldToUpdate(null);
    };


    const handleSaveProfile = async (updatedData) => {
        try {
            const config = getRequestConfig();
            const response = await updateCompanyData(currentLegalCompany.id, updatedData, config);

            if (response.status === 200) {
                await fetchLegalCompanyData();
                handlerCloseEditProfilePopup();
            }
        } catch (error) {
            console.error('Error saving profile:', error);
        }
    };

    const isCurrentUserCompanyOwner = () => {
        return user && user.id === data.user;
    };
    const {bannerHeight} = useContext(SearchContext);

    const formatPhoneNumber = (phoneNumber) => {
        let cleaned = ('' + phoneNumber).replace(/\D/g, '');
        if (!cleaned.startsWith('1')) {
            cleaned = '1' + cleaned;
        }
        const match = cleaned.match(/^(\d{1})(\d{3})(\d{3})(\d{4})$/);
        if (match) {
            return `+${match[1]} (${match[2]}) ${match[3]}-${match[4]}`;
        }
        return phoneNumber;
    };

    const formatAmount = (amount) => {
        return new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            maximumFractionDigits: 0
        }).format(amount);
    };

    const [isFavorite, setIsFavorite] = useState(false);
    const stateAbbreviations = {
        "Alabama": "AL", "Alaska": "AK", "Arizona": "AZ", "Arkansas": "AR", "California": "CA", "Colorado": "CO",
        "Connecticut": "CT", "Delaware": "DE", "Florida": "FL", "Georgia": "GA", "Hawaii": "HI", "Idaho": "ID",
        "Illinois": "IL", "Indiana": "IN", "Iowa": "IA", "Kansas": "KS", "Kentucky": "KY", "Louisiana": "LA",
        "Maine": "ME", "Maryland": "MD", "Massachusetts": "MA", "Michigan": "MI", "Minnesota": "MN",
        "Mississippi": "MS", "Missouri": "MO", "Montana": "MT", "Nebraska": "NE", "Nevada": "NV",
        "New Hampshire": "NH", "New Jersey": "NJ", "New Mexico": "NM", "New York": "NY", "North Carolina": "NC",
        "North Dakota": "ND", "Ohio": "OH", "Oklahoma": "OK", "Oregon": "OR", "Pennsylvania": "PA",
        "Rhode Island": "RI", "South Carolina": "SC", "South Dakota": "SD", "Tennessee": "TN", "Texas": "TX",
        "Utah": "UT", "Vermont": "VT", "Virginia": "VA", "Washington": "WA", "West Virginia": "WV",
        "Wisconsin": "WI", "Wyoming": "WY"
    };

    useEffect(() => {
        setCurrentLegalCompany(data);
        setLogo(data.logo);
        setBackgroundImage(data.background_image);
    }, [data]);

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const attorneyId = params.get('attorneyId');

        if (attorneyId && data && data.attorneys) {
            const attorney = data.attorneys.find(att => att.attorney_id === attorneyId);
            setSelectedAttorney(attorney);
        } else {
            setSelectedAttorney(null);
        }
    }, [location.search, data]);

    const fetchLegalCompanyData = async () => {
        setLoading(true);
        try {
            if (!currentLegalCompany || !currentLegalCompany.id) {
                console.error('No company ID found');
                return;
            }

            const token = localStorage.getItem('authToken');
            const config = {
                headers: {
                    'Authorization': `Bearer ${token}`,
                }
            };
            const response = await api.get(`/legal-companies/${currentLegalCompany.id}/`, config);
            setCurrentLegalCompany(response.data);
            setLogo(response.data.logo);
            setBackgroundImage(response.data.background_image);
        } catch (error) {
            console.error('Error fetching legal company data:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (visible && data) {
            document.title = data.name || "Law Firm";

            const updateMetaTag = (property, content) => {
                let metaTag = document.querySelector(`meta[property='${property}']`);
                if (metaTag) {
                    metaTag.setAttribute('content', content);
                } else {
                    metaTag = document.createElement('meta');
                    metaTag.setAttribute('property', property);
                    metaTag.setAttribute('content', content);
                    document.head.appendChild(metaTag);
                }
            };

            updateMetaTag('og:title', data.name);
            updateMetaTag('og:description', data.description);
            updateMetaTag('og:image', data.logo);
            updateMetaTag('og:url', window.location.href);
        }
    }, [visible, data]);

    const {
        name = "Sunshine State Law Firm",
        description = "Sunshine State Law Firm offers comprehensive legal services...",
        address = {
            street_address: "505 Los Angeles Rd",
            city: {name: "Alameda"},
            state: {name: "California"},
            country: {name: "USA"},
            zip_codes: "90001"
        },
        email = "info@goldenstatelawfirm.com",
        phone_number = "+14244679862",
        additional_phones = [],
        web_site = "https://befaster.online",
        social_media = [],
        working_hours = [],
        detailed_service_regions = [],
        attorneys = [],
        logo = null,
        background_image = '/assets/bg--profile-attorney-B9GKd-yI.jpg',
        free_consultation = false,
        awards = [],
        foundation_year = null,
        is_favorite = false,
    } = currentLegalCompany || {};

    useEffect(() => {
        fetchLegalCompanyData();
    }, [data]);

    const formattedAddress = `${address.street_address}, ${address.city.name}, ${address.state.name}, ${address.country.name}, ${address.zip_code ? address.zip_code.zip_code : ''}`;
    const googleMapsUrl = `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(formattedAddress)}`;

    useEffect(() => {
        const checkFavoriteStatus = async () => {
            try {
                const token = localStorage.getItem('authToken');
                const config = {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    }
                };
                const response = await api.get(`/check-favorite-lawcompany/${data.id}/`, config);
                setIsFavorite(response.data.is_favorite);
            } catch (error) {
                console.error('Error checking favorite status:', error);
            }
        };

        checkFavoriteStatus();
    }, [data.company_id]);

    const handleAddToFavorites = async () => {
        try {
            const response = await api.post('/add-favorite-lawcompany/', {company_id: data.id});
            if (response.status === 200 || response.status === 201) {
                setIsFavorite(true);
            }
        } catch (error) {
            console.error('Error adding to favorites:', error);
        }
    };

    const handleRemoveFromFavorites = async () => {
        try {
            const response = await api.post('/remove-favorite-lawcompany/', {company_id: data.id});
            if (response.status === 200) {
                setIsFavorite(false);
            }
        } catch (error) {
            console.error('Error removing from favorites:', error);
        }
    };

    const handleToggleFavorites = () => {
        if (isFavorite) {
            handleRemoveFromFavorites();
        } else {
            handleAddToFavorites();
        }
    };

    const formatTimeToAMPM = (time) => {
        const [hour, minute] = time.split(':').map(Number);
        const period = hour >= 12 ? 'PM' : 'AM';
        const adjustedHour = hour % 12 || 12;
        return `${adjustedHour}:${minute < 10 ? '0' + minute : minute} ${period}`;
    };

    const groupWorkingHours = (working_hours) => {
        const daysOfWeek = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
        const hoursMap = daysOfWeek.reduce((map, day) => {
            map[day] = 'Closed';
            return map;
        }, {});

        working_hours.forEach(({working_hours: {day_of_week, start_time, end_time}}) => {
            hoursMap[day_of_week] = `${formatTimeToAMPM(start_time)} - ${formatTimeToAMPM(end_time)}`;
        });

        const groupedHours = [];
        let currentRange = null;
        let currentDays = [];

        const finalizeGroup = () => {
            if (currentDays.length > 0) {
                if (currentDays.length === 1) {
                    groupedHours.push(`${currentDays[0]}: ${currentRange}`);
                } else {
                    groupedHours.push(`${currentDays[0]}-${currentDays[currentDays.length - 1]}: ${currentRange}`);
                }
            }
        };

        daysOfWeek.forEach(day => {
            const hours = hoursMap[day];
            if (currentRange === hours) {
                currentDays.push(day);
            } else {
                finalizeGroup();
                currentDays = [day];
                currentRange = hours;
            }
        });

        finalizeGroup();

        return groupedHours;
    };

    const workingHoursFormatted = groupWorkingHours(working_hours);
    const displayedAttorneys = showAllAttorneys ? attorneys : attorneys.slice(0, 2);
    const remainingAttorneysCount = attorneys.length - 2;

    const handleAttorneyClick = (attorney) => {
        handleShowPopup(attorney.attorney_id, 'attorney', true, data);
    };

    const toggleCityExpansion = (city) => {
        setExpandedCities(prevState => ({
            ...prevState,
            [city]: !prevState[city]
        }));
    };

    const renderFreeConsultation = () => {
        if (free_consultation) {
            return (
                <span className="vendor__status vendor__status-active">FREE CONSULTATION</span>
            );
        }
        return null;
    };

    const groupedServiceRegions = detailed_service_regions.reduce((acc, region) => {
        const cityState = `${region.city.name}, ${stateAbbreviations[region.state.name]}`;
        if (!acc[cityState]) {
            acc[cityState] = [];
        }
        acc[cityState].push(region.zip_code.zip_code);
        return acc;
    }, {});

    const handlerShowEditProfilePopup = useCallback(() => {
        setShowEditProfilePopup(true);
    }, []);

    const handlerCloseEditProfilePopup = useCallback(() => {
        setShowEditProfilePopup(false);
    }, []);

    const handlerShowEditAwardsPopup = useCallback(() => {
        setShowEditAwardsPopup(true);
    }, []);

    const handlerCloseEditAwardsPopup = useCallback(() => {
        setShowEditAwardsPopup(false);
    }, []);

    const handlerShowAddAttorneysPopup = useCallback(() => {
        setShowAddAttorneysPopup(true);
    }, []);

    const handlerCloseAddAttorneysPopup = useCallback(() => {
        setShowAddAttorneysPopup(false);
    }, []);
    const handleShowEditServiceRegionsPopup = useCallback(() => {
        setShowEditServiceRegionsPopup(true);
    }, []);

    const handleCloseEditServiceRegionsPopup = useCallback(() => {
        setShowEditServiceRegionsPopup(false);
    }, []);
    const handleSaveServiceRegions = async (updatedRegions) => {
        try {
            const config = getRequestConfig();
            const response = await api.patch(`/legal-companies/${currentLegalCompany.id}/update-regions/`, {regions: updatedRegions}, config);
            if (response.status === 200) {
                await fetchLegalCompanyData();
                handleCloseEditServiceRegionsPopup();
            }
        } catch (error) {
            console.error('Error saving service regions:', error);
        }
    };

    const handleSaveAwards = async (formDataToSend) => {
        try {
            const config = getRequestConfig();
            const response = await api.patch(`/legal-company/${currentLegalCompany.id}/awards/`, formDataToSend, config);

            if (response.status === 200) {
                await fetchLegalCompanyData();
                handlerCloseEditAwardsPopup();
            }
        } catch (error) {
            console.error('Error saving awards:', error);
        }
    };

    const handleSendMessageClick = () => {
        setShowSendMessageModal(true);
    };

    const handleCloseSendMessageModal = () => {
        setShowSendMessageModal(false);
    };

    return (
        <div className={`popup ${visible ? 'visible' : ''}`} id="company-type1" style={{top: `${bannerHeight + 50}px`}}>
            <Notification/>
            <button className="close js--hide-popup" onClick={onClose}>
                <img src="/assets/icon--close-CN6QlgAA.svg" alt="Close"/>
            </button>
            {loading && <Loader/>}
            <div className="profile profile-pro">
                <div className="profile__headline profile__headline-company"
                     style={{background: `url(${backgroundImage})`}}>
                    <div className="container">
                        <div className="profile__logo">
                            {currentLogo && (
                                <picture>
                                    <source type="image/webp" srcSet={currentLogo}/>
                                    <source type="image/jpg" srcSet={currentLogo}/>
                                    <img src={currentLogo} alt={`${name} logo`}/>
                                </picture>
                            )}
                            {isCurrentUserCompanyOwner() && (
                                <label className="userpick__edit"><img src="/assets/icon--edit-bg-BVfPEI-f.svg"
                                                                       alt="edit"/>
                                    <input type="file" onChange={handleLogoChange}/>
                                </label>
                            )}
                        </div>
                        {isCurrentUserCompanyOwner() && (
                            <label className="bg__edit">
                                <div className="button no-style"><img src="/assets/icon--edit-Z9QfuMgm.svg"
                                                                      alt="edit"/> Edit Big Photo
                                </div>
                                <input type="file" onChange={handleBackgroundImageChange}/>
                            </label>
                        )}
                    </div>
                </div>
                <div className="profile__block">
                    <div className="container">
                        <div className="profile__header">
                            <div className="vendor__header">
                                <h1 className="vendor__name">{name}</h1>
                                <button
                                    className={`favorites ${isFavorite ? 'added' : ''}`}
                                    onClick={handleToggleFavorites}
                                >
                                    <svg>
                                        <path fillRule="evenodd" clipRule="evenodd"
                                              d="M1.58733 3.10072C3.70393 0.940385 7.13582 0.940385 9.25246 3.10072L10.0323 3.89665L10.7475 3.16683C12.8641 1.0065 16.296 1.0065 18.4127 3.16683C20.5293 5.32717 20.5292 8.82998 18.4127 10.9904L10.4695 19.0901C10.4418 19.1184 10.5003 19.068 10.4695 19.0901C10.2068 19.2849 9.79324 19.2991 9.5566 19.0576L1.58757 10.9239C-0.52903 8.76353 -0.529268 5.26111 1.58733 3.10072Z"></path>
                                    </svg>
                                </button>
                                <div className="vendor__attorney">
                                    {attorneys.length > 0 && `${attorneys.length} attorneys on staff `}
                                    {foundation_year && (
                                        <span>• Founded In {foundation_year} </span>
                                    )}
                                    {renderFreeConsultation()}
                                </div>
                            </div>
                            {isCurrentUserCompanyOwner() && (
                                <button className="button no-style" onClick={handlerShowEditProfilePopup}>
                                    <img src="/assets/icon--edit-Z9QfuMgm.svg" alt="Edit"/> Edit profile
                                </button>
                            )}
                        </div>
                        <div className="profile__content userdata">
                            <div className="col-2">
                                <div className="data">
                                    <p>{description}</p>
                                </div>
                                <div className="profile__contacts">
                                    <ul className="contacts">
                                        {address && address.street_address && (
                                            <li className="contacts__el">
                                                <div className="contacts__label">Address</div>
                                                <div className="contacts__value">
                                                    <a href={googleMapsUrl} target="_blank"
                                                       rel="noopener noreferrer">{formattedAddress}</a>
                                                </div>
                                            </li>
                                        )}
                                        {working_hours.length > 0 && (
                                            <li className="contacts__el">
                                                <div className="contacts__label">Working Hours</div>
                                                <div className="contacts__value">
                                                    <ul className="hours">
                                                        {workingHoursFormatted.length > 0 ? workingHoursFormatted.map((item, index) => {
                                                            const [day, time] = item.split(': ');
                                                            return (
                                                                <li key={index}>
                                                                    <span className="working-hours__day">{day}:</span>
                                                                    <span className="working-hours__time">{time}</span>
                                                                </li>
                                                            );
                                                        }) : <li>Closed</li>}
                                                    </ul>
                                                </div>
                                            </li>
                                        )}
                                        {email && (
                                            <li className="contacts__el">
                                                <div className="contacts__label">Email</div>
                                                <div className="contacts__value">
                                                    {isCurrentUserCompanyOwner() ? (
                                                        <a href={`mailto:${email}`}>{email}</a>
                                                    ) : (
                                                        <button
                                                            onClick={handleSendMessageClick}
                                                            type='button'
                                                            className='button button-black'
                                                            style={{
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                justifyContent: 'center'
                                                            }}
                                                        >
                                                            Send Message
                                                        </button>
                                                    )}
                                                </div>
                                            </li>
                                        )}
                                        {phone_number && (
                                            <li className="contacts__el">
                                                <div className="contacts__label">Phone</div>
                                                <div className="contacts__value">
                                                    <a href={`tel:${phone_number}`}>{formatPhoneNumber(phone_number)}</a>
                                                </div>
                                            </li>
                                        )}
                                        {additional_phones.length > 0 && (
                                            additional_phones.map((phone, index) => (
                                                <li key={index} className="contacts__el">
                                                    <div className="contacts__label">{phone.phone_type.name}</div>
                                                    <div className="contacts__value">
                                                        <a href={`tel:${phone.phone_number}`}>{formatPhoneNumber(phone.phone_number)}</a>
                                                    </div>
                                                </li>
                                            ))
                                        )}
                                        {web_site && (
                                            <li className="contacts__el">
                                                <div className="contacts__label">Web Site</div>
                                                <div className="contacts__value">
                                                    <a href={web_site} target="_blank"
                                                       rel="noopener noreferrer">{web_site}</a>
                                                </div>
                                            </li>
                                        )}
                                        {social_media.length > 0 && (
                                            <li className="contacts__el">
                                                <div className="contacts__label">Social Media</div>
                                                <div className="contacts__value">
                                                    <ul className="social">
                                                        {social_media.map((sm, index) => (
                                                            <li key={index}>
                                                                <a href={sm.url} target="_blank"
                                                                   rel="noopener noreferrer">
                                                                    {sm.platform.icon ? (
                                                                        <img
                                                                            src={sm.platform.icon}
                                                                            alt={sm.platform.name}/>
                                                                    ) : (
                                                                        sm.platform.name
                                                                    )}
                                                                </a>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </div>
                                            </li>
                                        )}
                                    </ul>
                                    <button onClick={() => showSharePopup({
                                        companyName: name,
                                        telephone: phone_number,
                                        address: formattedAddress,
                                        specializations: data.specializations ? data.specializations.map(spec => spec.name) : []
                                    })} type='button'
                                            className='profile__share button no-style'>
                                        Share Profile
                                        <img src="/assets/icon--share.svg" alt="Share Icon"/>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {(isCurrentUserCompanyOwner() || detailed_service_regions.length) > 0 && (
                    <div className="profile__block">
                        <div className="container">
                            <div className="profile__header">
                                <h2>Service Regions</h2>
                                {isCurrentUserCompanyOwner() && (
                                    <button className="button no-style" onClick={handleShowEditServiceRegionsPopup}>
                                        <img src="/assets/icon--edit-Z9QfuMgm.svg" alt="Edit Regions"/>
                                        {(detailed_service_regions.length === 0) ? 'Add Regions' : 'Edit Regions'}
                                    </button>
                                )}
                            </div>
                            <div className="profile__content">
                                <ul className="params__list">
                                    {Object.keys(groupedServiceRegions).map((cityState, index) => (
                                        <li key={index}>
                                            <div
                                                onClick={() => toggleCityExpansion(cityState)}
                                                style={{cursor: 'pointer', transition: 'height 0.3s ease'}}
                                            >
                                                {cityState} <ZipCodes zipCodes={groupedServiceRegions[cityState]}/>
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                )}
                {(isCurrentUserCompanyOwner() || attorneys.length > 0) && (
                    <div className="profile__block">
                        <div className="container">
                            <div className="profile__header"><h2>{attorneys.length} attorneys on staff</h2>
                                {isCurrentUserCompanyOwner() && (
                                    <button className="button no-style" onClick={handlerShowAddAttorneysPopup}>
                                        <img src="/assets/icon--edit-Z9QfuMgm.svg" alt="Edit Regions"/>
                                        Add Attorney
                                    </button>
                                )}
                            </div>
                            <div className="profile__content">
                                <div className="attorneys">
                                    <ul className="attorneys__list">
                                        {displayedAttorneys.map((attorney, index) => (
                                            <li key={index} className="attorney"
                                                onClick={() => handleAttorneyClick(attorney)}>
                                                <div className="attorney__avatar">
                                                    <div className="avatar">
                                                        <picture>
                                                            {attorney.photo && (
                                                                <>
                                                                    <source type="image/webp"
                                                                            srcSet={attorney.photo}/>
                                                                    <source type="image/jpg"
                                                                            srcSet={attorney.photo}/>
                                                                    <img
                                                                        src={attorney.photo}
                                                                        alt={`${attorney.first_name} ${attorney.last_name}`}/>
                                                                </>
                                                            )}
                                                        </picture>
                                                    </div>
                                                </div>
                                                <div className="attorney__content">
                                                    <div>
                                                        <h3 className="attorney__name">{attorney.first_name} {attorney.last_name}</h3>
                                                        <div className="attorney__info">
                                                            {attorney.license_year ? `Licensed in ${attorney.license_year}` : ''}
                                                            {attorney.total_won_amount && (
                                                                <span> • Total won amount: {formatAmount(attorney.total_won_amount)}</span>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <ul className="params__list">
                                                        {attorney.specializations.slice(0, 5).map((spec, specIndex) => (
                                                            <li key={specIndex}>{spec.name}</li>
                                                        ))}
                                                        {attorney.specializations.length > 5 && (
                                                            <li>+ {attorney.specializations.length - 5} more</li>
                                                        )}
                                                    </ul>
                                                </div>
                                                {isCurrentUserCompanyOwner() && (
                                                    <button className="button no-style">
                                                        <img src="/assets/icon--delete-gray-BAOsHS3n.svg" alt="Delete"/>
                                                    </button>
                                                )}
                                            </li>
                                        ))}
                                    </ul>
                                    {attorneys.length > 2 && (
                                        <div className="show-all">
                                            <a href="#" onClick={(e) => {
                                                e.preventDefault();
                                                setShowAllAttorneys(!showAllAttorneys);
                                            }}>
                                                {showAllAttorneys ? 'Show Less' : `Show More Attorneys • ${remainingAttorneysCount} (${attorneys.length})`}
                                            </a>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                {(isCurrentUserCompanyOwner() || awards.length > 0) && (
                    <div className="profile__block">
                        <div className="container">
                            <div className="profile__header"><h2>Awards and Articles</h2>
                                {isCurrentUserCompanyOwner() && (
                                    <button className="button no-style" onClick={handlerShowEditAwardsPopup}>
                                        <img src="/assets/icon--edit-Z9QfuMgm.svg" alt="Edit Regions"/>
                                        {(currentLegalCompany.awards.length === 0) ? 'Add Awards' : 'Edit Awards'}
                                    </button>
                                )}
                            </div>
                            <div className="profile__content">
                                <div className="col-2 decor-list">
                                    {currentLegalCompany.awards.map((award, index) => (
                                        <div key={index} className="vendor vendor-award decor-list__el">
                                            <div className="vendor__award">
                                                {award.image && <img src={award.image} alt={award.title}/>}
                                            </div>
                                            <div className="vendor__header">
                                                <h4 className="vendor__name">{award.title}</h4>
                                                <span className="vendor__attorney">{award.subtitle}</span>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
            {selectedAttorney && (
                <PopupAttorney
                    visible={!!selectedAttorney}
                    attorney={selectedAttorney}
                />
            )}
            {showEditAwardsPopup && (
                <EditAwardsPopup
                    show={showEditAwardsPopup}
                    onClose={handlerCloseEditAwardsPopup}
                    initialData={currentLegalCompany}
                    onSave={handleSaveAwards}
                />
            )}
            {showEditProfilePopup && (
                <EditLegalCompanyProfilePopup
                    show={showEditProfilePopup}
                    closeEditProfilePopup={handlerCloseEditProfilePopup}
                    initialData={currentLegalCompany}
                    onSave={handleSaveProfile}
                />
            )}
            {showAddAttorneysPopup && (
                <AddAttorneysPopup
                    show={showAddAttorneysPopup}
                    onClose={handlerCloseAddAttorneysPopup}
                    legalCompanyId={currentLegalCompany.id}
                    onAttorneyAdded={fetchLegalCompanyData}
                />
            )}
            {showEditServiceRegionsPopup && (
                <EditServiceRegionsPopup
                    show={showEditServiceRegionsPopup}
                    onClose={handleCloseEditServiceRegionsPopup}
                    serviceRegions={groupedServiceRegions}
                    legalCompanyId={currentLegalCompany.id}
                    onSave={handleSaveServiceRegions}
                />
            )}
            {showSendMessageModal && (
                <SendMessageModal
                    show={showSendMessageModal}
                    recipient={currentLegalCompany}
                    recipientType="legalcompany"
                    onClose={handleCloseSendMessageModal}
                />
            )}

            {/* Модалка для кропа изображения */}
            <CropImageModal
                show={showCropModal}
                onClose={() => setShowCropModal(false)}
                image={imageToCrop}
                aspect={currentAspectRatio}
                onComplete={handleCroppedImageComplete}
            />
        </div>
    );
};

export default Popup;
