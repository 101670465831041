import React, {useState, useEffect} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import api from '../../api';
import externalApi from '../../api/externalApi';
import {useNotification} from '../../context/NotificationContext';
import {useUser} from '../../context/UserContext';

const VerifyNewPromoEmailToken = () => {
    const navigate = useNavigate();
    const {showNotification} = useNotification();
    const {token} = useParams();
    const {setAuthTokens, setUser, setAttorneyCompanyData} = useUser();
    const [loading, setLoading] = useState(true);
    const [attorneyData, setAttorneyData] = useState(null);
    const [activated, setActivated] = useState(false); // Флаг для предотвращения повторной активации

    useEffect(() => {
        const fetchAttorneyData = async () => {
            try {
                const response = await externalApi.get(`/promo-attorney-detail/${token}/`);
                console.log("Full backend response:", response); // Логируем весь ответ сервера

                if (response.status >= 200 && response.status < 300) {
                    console.log("Attorney data payload:", response.data); // Логируем только полезные данные
                    setAttorneyData(response.data);
                } else {
                    showNotification('Error', 'Unable to retrieve lawyer details.', null, 'error');
                }
            } catch (error) {
                console.error('Error fetching attorney data:', error);
                showNotification('Error', 'There was an error retrieving the attorney\'s details.', null, 'error');
            } finally {
                setLoading(false);
            }
        };


        const checkTokenValidity = async () => {
            if (!token || token.trim() === '') {
                // Нет токена — просто пробуем получить данные адвоката
                fetchAttorneyData();
                return;
            }

            try {
                const checkResponse = await api.get(`/check-promo-token/${token}/`);
                if (checkResponse.status >= 200 && checkResponse.status < 300) {
                    if (checkResponse.data.valid) {
                        // Токен валиден и уже был использован/активирован
                        showNotification('Token Found', 'The token is valid. Redirecting to sign-in.', null, 'info');
                        navigate('/sign-in/');
                        return;
                    } else {
                        // Токен невалиден — но попытаемся получить данные адвоката
                        fetchAttorneyData();
                    }
                } else {
                    // Невалидный токен — но всё же попытаемся получить данные адвоката
                    fetchAttorneyData();
                }
            } catch (error) {
                console.error('Error checking token validity:', error);
                // Ошибка при проверке — всё равно попытаемся получить данные
                fetchAttorneyData();
            }
        };

        checkTokenValidity();
    }, [showNotification, token, navigate]);

    useEffect(() => {
        // Если загрузка закончилась, есть данные и токен, и мы ещё не активировали аккаунт — активируем
        if (!loading && attorneyData && token && token.trim() !== '' && !activated) {
            setActivated(true); // Устанавливаем флаг, чтобы не вызвать повторную активацию
            activateAccount();
        } else if (!loading && (!attorneyData || !token)) {
            // Нет данных для активации или нет токена, перенаправляем на sign-up
            navigate('/sign-up/');
        }
    }, [loading, attorneyData, token, navigate, activated]);

    const activateAccount = async () => {
        setLoading(true);
        try {
            const activationUrl = `/activate-new-promo-token/${token}/`;
            const response = await api.post(activationUrl, attorneyData);
            if (response.status >= 200 && response.status < 300) {
                showNotification(
                    'Activation Successful!',
                    response.data.detail || 'Your account has been activated.',
                    null,
                    'success'
                );

                const {tokens} = response.data;
                if (tokens && tokens.access && tokens.refresh) {
                    setAuthTokens({access: tokens.access, refresh: tokens.refresh});
                    localStorage.setItem('access_token', tokens.access);
                    localStorage.setItem('refresh_token', tokens.refresh);

                    try {
                        const userResponse = await api.get('/user/');
                        if (userResponse.status >= 200 && userResponse.status < 300) {
                            setUser(userResponse.data);
                        } else {
                            showNotification(
                                'Authentication Warning',
                                'Account activated, but failed to fetch user data.',
                                null,
                                'warning'
                            );
                        }
                    } catch (userError) {
                        console.error('Error fetching user data:', userError);
                        showNotification(
                            'User Data Error',
                            'Account activated, but an error occurred while fetching user data.',
                            null,
                            'error'
                        );
                    }
                } else {
                    showNotification(
                        'Activation Warning',
                        'Activation succeeded, but tokens are missing.',
                        null,
                        'error'
                    );
                }

                // Сохраняем данные адвоката в контекст
                setAttorneyCompanyData(attorneyData);

                // Перенаправляем на профиль
                navigate('/profile/');
            } else {
                showNotification(
                    'Activation Failed',
                    response.data.detail || 'Failed to activate your account.',
                    null,
                    'error'
                );
                navigate('/sign-up/');
            }
        } catch (error) {
            console.error('Error activating account:', error);
            if (error.response) {
                showNotification(
                    'Activation Error',
                    error.response.data.detail || 'An error occurred during activation.',
                    null,
                    'error'
                );
            } else {
                showNotification(
                    'Server Error',
                    'An error occurred while activating your account. Please try again later.',
                    null,
                    'error'
                );
            }
            navigate('/sign-up/');
        } finally {
            setLoading(false);
        }
    };

    return null; // Ничего не рендерим
};

export default VerifyNewPromoEmailToken;
