import React from 'react';
import {createRoot} from 'react-dom/client';
import {BrowserRouter as Router} from 'react-router-dom';
import AppWrapper from './AppWrapper';
import './main5vr2.css';
import {UserProvider} from "./context/UserContext";

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
    <Router>
        <UserProvider>
            <AppWrapper/>
        </UserProvider>
    </Router>
);
