import React, {useState, useEffect, useCallback} from 'react';
import InputWithLabel from "../Form/Input/InputWithLabel";
import TextareaWithLabel from "../Form/Input/TextareaWithLabel";
import api from '../../api';
import Cookies from 'js-cookie';
import MultiSelectInColumnWithLabel from "../MultiSelects/MultiSelectInColumnWithLabel";
import ReactDOM from 'react-dom'; // Import ReactDOM for portals

const CreateVendorProfile = ({show, onClose, userId, existingVendor}) => {
    const currentYear = new Date().getFullYear();

    // Инициализация состояния формы
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        foundation_year: '',
        phone_number: '',
        web_site: '',
        description: '',
        social_media: [],
        additional_phones: [],
        address: {
            country_id: '1',
            state_id: '',
            city_id: '',
            zip_code_id: '',
            street_address: '',
        },
        free_consultation: false,
        working_hours: [],
        status: 'inactive',
        service_subtypes: [],
    });

    // Состояние для идентификатора поставщика
    const [vendorId, setVendorId] = useState(null);

    // Состояния для ошибок и загрузки
    const [errors, setErrors] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);

    // Состояния для выпадающих списков и выборов
    const [platforms, setPlatforms] = useState([]);
    const [contactMethods, setContactMethods] = useState([]);
    const [states, setStates] = useState([]);
    const [cities, setCities] = useState([]);
    const [zipCodes, setZipCodes] = useState([]);

    const [selectedServiceTypeId, setSelectedServiceTypeId] = useState('');
    const [serviceTypes, setServiceTypes] = useState([]);
    const [tariffServices, setTariffServices] = useState([]);
    const [selectedTariffServiceId, setSelectedTariffServiceId] = useState('');
    const [selectedTariffService, setSelectedTariffService] = useState(null);
    const [billingPeriod, setBillingPeriod] = useState('annually');
    const [serviceSubtypesOptions, setServiceSubtypesOptions] = useState([]);

    // Функция для получения конфигурации запроса с токеном
    const getRequestConfig = useCallback(() => {
        const token = localStorage.getItem('authToken');
        const csrfToken = Cookies.get('csrftoken');

        return {
            headers: {
                'Authorization': `Bearer ${token}`,
                'X-CSRFToken': csrfToken,
            },
            withCredentials: true,
        };
    }, []);

    // Функция для загрузки городов по ID штата
    const fetchCities = useCallback(async (state_id) => {
        if (state_id) {
            try {
                const response = await api.get(`/cities-filtered/?state_id=${state_id}`);
                setCities(response.data);
            } catch (error) {
                console.error('Error fetching cities:', error);
            }
        } else {
            setCities([]);
        }
    }, []);

    // Функция для загрузки почтовых индексов по ID города
    const fetchZipCodes = useCallback(async (city_id) => {
        if (city_id) {
            try {
                const response = await api.get(`/zip-codes/?city_id=${city_id}`);
                setZipCodes(response.data);
            } catch (error) {
                console.error('Error fetching zip codes:', error);
            }
        } else {
            setZipCodes([]);
        }
    }, []);

    // Загрузка начальных данных при монтировании компонента
    useEffect(() => {
        // Загрузка платформ
        const fetchPlatforms = async () => {
            try {
                const response = await api.get('/platforms/');
                setPlatforms(response.data);
            } catch (error) {
                console.error('Error fetching platforms:', error);
            }
        };
        fetchPlatforms();

        // Загрузка типов услуг
        const fetchServiceTypes = async () => {
            try {
                const response = await api.get('/service-types/');
                setServiceTypes(response.data);
            } catch (error) {
                console.error('Error fetching service types:', error);
            }
        };
        fetchServiceTypes();

        // Загрузка методов контакта
        const fetchContactMethods = async () => {
            try {
                const response = await api.get('/contact-methods/');
                setContactMethods(response.data);
            } catch (error) {
                console.error('Error fetching contact methods:', error);
            }
        };
        fetchContactMethods();

        // Загрузка штатов
        const fetchStates = async () => {
            try {
                const response = await api.get(`/states-filtered/?country_id=1`);
                setStates(response.data);
            } catch (error) {
                console.error('Error fetching states:', error);
            }
        };
        fetchStates();
    }, []);

    // Загрузка городов при изменении штата
    useEffect(() => {
        fetchCities(formData.address.state_id);
    }, [formData.address.state_id, fetchCities]);

    // Загрузка почтовых индексов при изменении города
    useEffect(() => {
        fetchZipCodes(formData.address.city_id);
    }, [formData.address.city_id, fetchZipCodes]);

    // Функция для загрузки подтипов услуг по ID типа услуги
    const fetchServiceSubtypes = useCallback(async (serviceTypeId) => {
        try {
            const response = await api.get(`/service-subtypes/?service_type_id=${serviceTypeId}`);
            setServiceSubtypesOptions(response.data);
        } catch (error) {
            console.error('Error fetching service subtypes:', error);
        }
    }, []);

    // Загрузка тарифных услуг при изменении типа услуги
    useEffect(() => {
        if (selectedServiceTypeId) {
            const fetchTariffServices = async () => {
                try {
                    const response = await api.get(`/tariff-services/?service_type_id=${selectedServiceTypeId}`);
                    const fetchedTariffs = response.data;

                    // Фильтрация активных тарифов
                    const activeTariffs = fetchedTariffs.filter(tariff => tariff.status === 'active');

                    setTariffServices(fetchedTariffs);

                    if (activeTariffs.length > 0) {
                        const firstActiveTariff = activeTariffs[0];
                        setSelectedTariffServiceId(firstActiveTariff.tariff_service_id);
                        setSelectedTariffService(firstActiveTariff);
                    } else {
                        setSelectedTariffServiceId('');
                        setSelectedTariffService(null);
                    }
                } catch (error) {
                    console.error('Error fetching tariff services:', error);
                }
            };
            fetchTariffServices();
        } else {
            setTariffServices([]);
            setSelectedTariffServiceId('');
            setSelectedTariffService(null);
        }
    }, [selectedServiceTypeId]);

    // Загрузка подтипов услуг при изменении типа услуги
    useEffect(() => {
        if (selectedServiceTypeId) {
            fetchServiceSubtypes(selectedServiceTypeId);

            // Сбрасываем выбранные подтипы услуг
            setFormData(prevData => ({
                ...prevData,
                service_subtypes: [],
            }));
        } else {
            setServiceSubtypesOptions([]);
        }
    }, [selectedServiceTypeId, fetchServiceSubtypes]);

    // Загрузка существующего поставщика при наличии
    useEffect(() => {
        if (existingVendor) {
            // Извлечение service_type_id и service_subtypes из existingVendor
            const serviceTypeId = existingVendor.service_type?.service_type_id || '';
            const serviceSubtypes = existingVendor.service_subtypes || [];

            // Преобразование serviceSubtypes в массив ID подтипов
            const serviceSubtypeIds = serviceSubtypes.map(subtype => subtype.service_subtype_id);

            const updatedFormData = {
                ...formData, // Сохранение остальных полей
                name: existingVendor.name || '',
                email: existingVendor.email || '',
                foundation_year: existingVendor.foundation_year || '',
                phone_number: existingVendor.phone_number || '',
                web_site: existingVendor.web_site || '',
                description: existingVendor.description || '',
                social_media: existingVendor.social_media || [],
                additional_phones: existingVendor.additional_phones || [],
                address: {
                    country_id: existingVendor.address?.country?.country_id ? String(existingVendor.address.country.country_id) : '1',
                    state_id: existingVendor.address?.state?.state_id ? String(existingVendor.address.state.state_id) : '',
                    city_id: existingVendor.address?.city?.city_id ? String(existingVendor.address.city.city_id) : '',
                    zip_code_id: existingVendor.address?.zip_code?.id ? String(existingVendor.address.zip_code.id) : '',
                    street_address: existingVendor.address?.street_address || '',
                },
                free_consultation: existingVendor.free_consultation || false,
                working_hours: existingVendor.working_hours || [],
                status: existingVendor.status || 'inactive',
                service_subtypes: serviceSubtypeIds, // Установка service_subtypes
            };

            setFormData(updatedFormData);
            setVendorId(existingVendor.vendor_id);
            setIsSubmitted(true);

            // Установка выбранного типа сервиса и тарифа
            setSelectedServiceTypeId(serviceTypeId); // Использование извлечённого serviceTypeId
            setSelectedTariffServiceId(existingVendor.tariff_service_id || '');
            setSelectedTariffService(existingVendor.tariff_service || null);

            // Загрузка зависимых данных
            if (updatedFormData.address.state_id) {
                fetchCities(updatedFormData.address.state_id);
            }

            if (updatedFormData.address.city_id) {
                fetchZipCodes(updatedFormData.address.city_id);
            }

            // Загрузка доступных подтипов услуг для выбранного типа
            if (serviceTypeId) {
                fetchServiceSubtypes(serviceTypeId);
            }
        }
    }, [existingVendor, fetchCities, fetchZipCodes, fetchServiceSubtypes]);

    // Обработка изменений в полях формы
    const handleChange = (e) => {
        const {name, value} = e.target;
        setFormData(prevData => ({
            ...prevData,
            [name]: value,
        }));
    };

    // Обработка изменений в адресе
    const handleAddressChange = (name, value) => {
        setFormData(prevData => ({
            ...prevData,
            address: {
                ...prevData.address,
                [name]: value,
            },
        }));
    };

    // Обработка изменения типа услуги
    const handleServiceTypeChange = (e) => {
        const serviceTypeId = e.target.value;
        setSelectedServiceTypeId(serviceTypeId);
        setSelectedTariffServiceId('');
        setSelectedTariffService(null);

        // Сбрасываем выбранные подтипы услуг
        setFormData(prevData => ({
            ...prevData,
            service_subtypes: [],
        }));
    };

    // Обработка выбора тарифной услуги
    const handleTariffServiceChange = (e) => {
        const tariffServiceId = e.target.value;
        const selectedTariff = tariffServices.find(tariff => tariff.tariff_service_id === Number(tariffServiceId));
        setSelectedTariffServiceId(tariffServiceId);
        setSelectedTariffService(selectedTariff);
    };

    // Переключение периода оплаты
    const handleBillingToggle = () => {
        setBillingPeriod(prev => (prev === 'monthly' ? 'annually' : 'monthly'));
    };

    // Валидация данных формы
    const validateForm = useCallback(() => {
        const newErrors = {};

        if (!formData.name.trim()) {
            newErrors.name = 'Vendor name is required';
        }

        if (!formData.email.trim()) {
            newErrors.email = 'Email is required';
        } else {
            // Простая валидация формата email
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (!emailRegex.test(formData.email)) {
                newErrors.email = 'Invalid email format.';
            }
        }

        if (!formData.foundation_year) {
            newErrors.foundation_year = 'Foundation year is required';
        }

        if (!formData.phone_number.trim()) {
            newErrors.phone_number = 'Phone number is required';
        }

        if (!selectedServiceTypeId) {
            newErrors.service_type_id = 'Service Type is required';
        }


        // Проверка на наличие точки и текста после нее
        const domainContainsDotRegex = /\..+$/;
        if (formData.web_site && !domainContainsDotRegex.test(formData.web_site)) {
            newErrors.web_site = 'Website must contain a dot followed by a domain, like ".com".';
        }

        // Валидация адреса
        if (!formData.address.state_id) {
            newErrors.address = {...newErrors.address, state_id: 'State is required'};
        }
        if (!formData.address.city_id) {
            newErrors.address = {...newErrors.address, city_id: 'City is required'};
        }
        if (!formData.foundation_year) {
            newErrors.foundation_year = 'Foundation year is required';
        }
        if (!formData.address.zip_code_id) {
            newErrors.address = {...newErrors.address, zip_code_id: 'Zip Code is required'};
        }
        if (!formData.address.street_address.trim()) {
            newErrors.street_address = 'Street and House Number is required';
        }

        if (formData.description.trim().length < 100) {
            newErrors.description = 'Description must be at least 100 characters';
        }

        // Дополнительная валидация других полей при необходимости
        // Например:
        // if (!formData.address.state_id) {
        //     newErrors.state_id = 'State is required';
        // }

        setErrors(newErrors);

        if (Object.keys(newErrors).length > 0) {
        }

        return Object.keys(newErrors).length === 0;
    }, [formData]);

    // Обработка сохранения изменений (обновление существующего поставщика)
    const handleSaveChanges = async () => {
        // Проверка наличия vendorId для обновления
        if (!vendorId) {
            setErrors({general: 'Cannot save changes without a vendor ID. Please create a vendor first.'});
            return;
        }

        // Валидация формы перед сохранением
        if (!validateForm()) {
            return;
        }

        setIsLoading(true); // Установка состояния загрузки

        // Подготовка данных для обновления
        const updateData = {
            ...formData,
            status: 'inactive', // Установка статуса как 'inactive' для финального сохранения
            user_id: userId, // Добавление user_id, полученного из пропсов
            service_type_id: selectedServiceTypeId, // Добавление выбранного типа сервиса
            tariff_services: selectedTariffServiceId ? [{
                tariff_service_id: selectedTariffServiceId,
                billing_period: billingPeriod
            }] : [], // Добавление выбранных тарифов, если они есть
        };


        // Использование эндпоинта для обновления существующего поставщика
        try {
            // Отправка PUT-запроса на сервер для обновления поставщика
            const response = await api.put(`/vendor/${vendorId}/update/`, updateData, getRequestConfig());

            if (response.status === 200) {
                setIsSubmitted(true);
                onClose();
            } else {
                console.error('Error updating changes:', response);
                setErrors({general: 'Error updating changes. Please try again.'});
            }
        } catch (error) {
            console.error('Error updating changes:', error.response ? error.response.data : error.message);
            setErrors({general: 'An unexpected error occurred while updating changes. Please try again.'});
        } finally {
            setIsLoading(false); // Снятие состояния загрузки
        }
    };

    // Обработка отправки формы при создании Vendor
    const handleSubmit = async (e) => {
        e.preventDefault();

        if (formData.web_site && !formData.web_site.startsWith('https://')) {
            setFormData(prevData => ({
                ...prevData,
                web_site: 'https://' + prevData.web_site
            }));
        }

        if (!validateForm()) {
            return;
        }

        setIsLoading(true);

        const submissionData = {
            name: formData.name,
            email: formData.email,
            foundation_year: formData.foundation_year,
            phone_number: formData.phone_number,
            web_site: formData.web_site,
            description: formData.description,
            service_type_id: selectedServiceTypeId,
            tariff_services: selectedTariffServiceId ? [{
                tariff_service_id: selectedTariffServiceId,
                billing_period: billingPeriod
            }] : [],
            user_id: userId,
            address: formData.address,
            social_media: formData.social_media.map(sm => ({
                platform_id: sm.platform_id,
                url: sm.url
            })),
            additional_phones: formData.additional_phones.map(phone => ({
                phone_type_id: phone.phone_type_id,
                phone_number: phone.phone_number
            })),
            working_hours: formData.working_hours.map(wh => ({
                day_of_week: wh.day_of_week,
                start_time: wh.start_time,
                end_time: wh.end_time
            })),
            service_subtypes: formData.service_subtypes // Предполагается, что это массив ID подтипов
        };


        try {
            const response = await api.post('/vendors/create/', submissionData, getRequestConfig());

            if (response.status === 201) {
                setIsSubmitted(true);
                setVendorId(response.data.vendor_id); // Предполагается, что API возвращает vendor_id
            } else {
                console.error('Error creating vendor:', response);
                setErrors({general: 'Error creating vendor. Please try again.'});
            }
        } catch (error) {
            console.error('Error creating vendor:', error.response ? error.response.data : error.message);
            setErrors({general: 'An unexpected error occurred. Please try again.'});
        } finally {
            setIsLoading(false);
        }
    };


    // Обработка отправки на утверждение
    const handleSendToApproval = async (e) => {
        e.preventDefault();

        if (!validateForm()) {
            return;
        }

        if (!vendorId && !(existingVendor && existingVendor.vendor_id)) {
            setErrors({general: 'Cannot submit for approval without a vendor ID. Please save the vendor first.'});
            return;
        }

        setIsLoading(true);

        try {
            const vendor_id = vendorId || existingVendor.vendor_id;
            const response = await api.post(`/vendors/${vendor_id}/submit-for-approval/`, {
                tariff_service_id: selectedTariffServiceId,
                billing_period: billingPeriod,
            }, getRequestConfig());

            if (response.data.checkout_url) {
                window.location.href = response.data.checkout_url;
            } else {
                setErrors({general: 'Failed to create payment session.'});
            }
        } catch (error) {
            console.error('Error submitting for approval:', error.response ? error.response.data : error.message);
            setErrors({general: 'An unexpected error occurred. Please try again.'});
        } finally {
            setIsLoading(false);
        }
    };

    // Предотвращение отправки формы при нажатии клавиши Enter
    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
        }
    };

    // Рендеринг компонента
    if (!show) return null;

    return ReactDOM.createPortal(
        <div className="modal visible" id="create-vendor-profile">
            <div className="modal__overlay" onClick={onClose}></div>
            <div className="modal__content">
                <button onClick={onClose} className="button no-style close js--close-modal">
                    <img src="/assets/icon--close-CN6QlgAA.svg" alt="Close"/>
                </button>
                <div className="modal__form">
                    <form className="content" onSubmit={handleSubmit} onKeyDown={handleKeyDown}>
                        <h2>{vendorId ? 'Edit Vendor Profile' : 'Create Vendor Profile'}</h2>

                        {/* Общие ошибки */}
                        {errors.general && (
                            <div className="error-message">
                                {errors.general}
                            </div>
                        )}

                        {/* Имя поставщика */}
                        <InputWithLabel
                            label='Vendor Name'
                            name='name'
                            value={formData.name}
                            onChange={handleChange}
                            error={errors.name}
                        />

                        {/* Выбор типа услуги */}
                        <label className="form-label">Service Type</label>
                        <select
                            name="service_type_id"
                            value={selectedServiceTypeId}
                            onChange={handleServiceTypeChange}
                            className={`input ${errors.service_type_id ? 'error-message' : ''}`}
                        >
                            <option value="">Select Service Type</option>
                            {serviceTypes.map((type) => (
                                <option key={type.service_type_id} value={type.service_type_id}>
                                    {type.service_type_name}
                                </option>
                            ))}
                        </select>
                        {errors.service_type_id && <span className="error-message">{errors.service_type_id}</span>}

                        {/* Год основания */}
                        <InputWithLabel
                            label='Foundation Year'
                            name='foundation_year'
                            type='number'
                            value={formData.foundation_year}
                            onChange={handleChange}
                            min="1800"
                            max={currentYear}
                            error={errors.foundation_year}
                        />

                        {/* Электронная почта */}
                        <InputWithLabel
                            label='Email'
                            name='email'
                            type='email'
                            value={formData.email}
                            onChange={handleChange}
                            error={errors.email}
                        />

                        {/* Номер телефона */}
                        <InputWithLabel
                            label='Phone Number'
                            name='phone_number'
                            type='tel'
                            value={formData.phone_number}
                            onChange={handleChange}
                            error={errors.phone_number}
                        />

                        {/* Веб-сайт */}
                        <InputWithLabel
                            label='Website'
                            name='web_site'
                            // type='url'
                            value={formData.web_site}
                            onChange={handleChange}
                            error={errors.web_site}
                        />

                        {/* Описание */}
                        <TextareaWithLabel
                            label='Description'
                            name='description'
                            value={formData.description}
                            onChange={handleChange}
                            error={errors.description}
                        />

                        <span>Address</span>

                        {/* Штат */}
                        <select
                            className={`input ${errors.address?.state_id ? 'input-error' : ''}`}
                            value={formData.address.state_id}
                            onChange={(e) => handleAddressChange('state_id', e.target.value)}
                        >
                            <option value="" disabled>Select State</option>
                            {states.map((state) => (
                                <option key={state.state_id} value={state.state_id}>
                                    {state.name}
                                </option>
                            ))}
                        </select>
                        {errors.address?.state_id && <span className="error-message">{errors.address.state_id}</span>}

                        {/* Город */}
                        {formData.address.state_id && (
                            <select
                                className={`input ${errors.address?.city_id ? 'input-error' : ''}`}
                                value={formData.address.city_id}
                                onChange={(e) => handleAddressChange('city_id', e.target.value)}
                            >
                                <option value="" disabled>Select City</option>
                                {cities.map((city) => (
                                    <option key={city.city_id} value={city.city_id}>
                                        {city.name}
                                    </option>
                                ))}
                            </select>
                        )}
                        {formData.address.state_id && errors.address?.city_id && (
                            <span className="error-text">{errors.address.city_id}</span>
                        )}

                        {/* Почтовый индекс */}
                        {formData.address.city_id && (
                            <select
                                className={`input ${errors.address?.zip_code_id ? 'input-error' : ''}`}
                                value={formData.address.zip_code_id}
                                onChange={(e) => handleAddressChange('zip_code_id', e.target.value)}
                            >
                                <option value="" disabled>Select Zip Code</option>
                                {zipCodes.map((zip) => (
                                    <option key={zip.id} value={zip.id}>
                                        {zip.zip_code}
                                    </option>
                                ))}
                            </select>
                        )}
                        {formData.address.city_id && errors.address?.zip_code_id && (
                            <span className="error-text">{errors.address.zip_code_id}</span>
                        )}

                        {/* Улица и номер дома */}
                        {formData.address.zip_code_id && (
                            <InputWithLabel
                                label='Street and House Number'
                                name='street_address'
                                value={formData.address.street_address}
                                onChange={(e) => handleAddressChange('street_address', e.target.value)}
                                error={errors.street_address}
                            />
                        )}

                        {/* Кнопки отправки формы */}
                        {!vendorId ? (
                            <button
                                className="button button-black create-vendor-button"
                                type="submit"
                                disabled={isLoading}
                            >
                                {isLoading ? 'Creating Vendor...' : 'Create Vendor'}
                            </button>
                        ) : (
                            <>
                                {/* Переключатель периода оплаты */}
                                <div className="billing-toggle-container">
                                    <div className="billing-toggle">
                                        <span
                                            className={`billing-text ${billingPeriod === 'monthly' ? 'active' : 'inactive'}`}
                                        >
                                            Monthly
                                        </span>
                                        <div
                                            className={`toggle ${billingPeriod === 'annually' ? 'checked' : ''}`}
                                            onClick={handleBillingToggle}
                                            role="switch"
                                            aria-checked={billingPeriod === 'annually'}
                                            tabIndex={0}
                                            onKeyPress={(e) => {
                                                if (e.key === 'Enter' || e.key === ' ') {
                                                    handleBillingToggle();
                                                }
                                            }}
                                        >
                                            <div className="toggle__container">
                                                <div className="toggle__circle"></div>
                                            </div>
                                        </div>
                                        <span
                                            className={`billing-text ${billingPeriod === 'annually' ? 'active' : 'inactive'}`}
                                        >
                                            Annually
                                        </span>
                                    </div>
                                    <div className="billing-prices">
                                        {selectedTariffService ? (
                                            billingPeriod === 'monthly' ? (
                                                <span className="billing-price active">
                                                    ${selectedTariffService.monthly_price}/month
                                                </span>
                                            ) : (
                                                <>
                                                    <span className="billing-price inactive">
                                                        ${selectedTariffService.monthly_price}
                                                    </span>
                                                    <span className="billing-price active">
                                                        ${(selectedTariffService.annual_price / 12).toFixed(0)}/month
                                                    </span>
                                                </>
                                            )
                                        ) : (
                                            <span className="billing-price">
                                                Please select a service type
                                            </span>
                                        )}
                                    </div>
                                </div>

                                <div className="button-row">
                                    <button
                                        className="button button-border save-draft-button"
                                        type="button"
                                        onClick={handleSaveChanges}
                                        disabled={isLoading}
                                    >
                                        {isLoading ? 'Saving...' : 'Save Draft'}
                                    </button>
                                    <button
                                        className="button button-black create-profile-button"
                                        type="button"
                                        onClick={handleSendToApproval}
                                        disabled={isLoading}
                                    >
                                        Submit for Approval
                                    </button>
                                </div>
                            </>
                        )}
                    </form>
                </div>
            </div>
        </div>,
        document.body // Портал в body
    );
};

export default CreateVendorProfile;
