import React, {
    createContext,
    useContext,
    useState,
    useEffect,
    useCallback,
    useMemo,
    useRef
} from 'react';
import api from '../api'; // Ваш настроенный экземпляр Axios

const UserContext = createContext();

export const UserProvider = ({children}) => {
    const [user, setUser] = useState(null);
    const [authTokens, setAuthTokens] = useState(() => {
        const accessToken = localStorage.getItem('access_token');
        const refreshToken = localStorage.getItem('refresh_token');
        return accessToken && refreshToken ? {access: accessToken, refresh: refreshToken} : null;
    });
    const [loading, setLoading] = useState(true);
    const [attorneyCompanyData, setAttorneyCompanyData] = useState(null);


    const tokensRef = useRef(authTokens);
    useEffect(() => {
        tokensRef.current = authTokens;
    }, [authTokens]);

    const logout = useCallback(() => {
        setAuthTokens(null);
        setUser(null);
        localStorage.removeItem('access_token');
        localStorage.removeItem('refresh_token');
        console.log("User has been logged out.");
    }, []);

    const refreshTokenFunc = useCallback(async () => {
        const currentRefreshToken = tokensRef.current?.refresh;
        if (!currentRefreshToken) {
            console.error("No refresh token available.");
            logout();
            return null;
        }
        try {
            console.log("Attempting to refresh tokens...");
            const response = await api.post('/token/refresh/', {
                refresh: currentRefreshToken
            });
            const newAccessToken = response.data.access;
            const newRefreshToken = response.data.refresh || currentRefreshToken;
            const newTokens = {
                access: newAccessToken,
                refresh: newRefreshToken,
            };
            if (newTokens.access !== tokensRef.current?.access) {
                setAuthTokens(newTokens);
                localStorage.setItem('access_token', newTokens.access);
                localStorage.setItem('refresh_token', newTokens.refresh);
                console.log("Tokens refreshed successfully.");
            }
            return newAccessToken;
        } catch (error) {
            console.error("Failed to refresh token:", error.response?.status, error.response?.data);
            logout();
            return null;
        }
    }, [logout]);

    const isRefreshing = useRef(false);
    const failedQueue = useRef([]);

    const processQueue = useCallback((error, token = null) => {
        failedQueue.current.forEach(prom => {
            if (error) {
                prom.reject(error);
            } else {
                prom.resolve(token);
            }
        });
        failedQueue.current = [];
    }, []);

    useEffect(() => {
        const requestInterceptor = api.interceptors.request.use(
            config => {
                if (tokensRef.current?.access) {
                    config.headers['Authorization'] = `Bearer ${tokensRef.current.access}`;
                }
                return config;
            },
            error => Promise.reject(error)
        );

        const responseInterceptor = api.interceptors.response.use(
            response => response,
            async error => {
                const originalRequest = error.config;

                if (error.response?.status === 401 && !originalRequest._retry) {
                    if (isRefreshing.current) {
                        return new Promise((resolve, reject) => {
                            failedQueue.current.push({resolve, reject});
                        }).then(token => {
                            originalRequest.headers['Authorization'] = `Bearer ${token}`;
                            return api(originalRequest);
                        }).catch(err => Promise.reject(err));
                    }

                    originalRequest._retry = true;
                    isRefreshing.current = true;

                    return new Promise(async (resolve, reject) => {
                        try {
                            const newAccessToken = await refreshTokenFunc();
                            if (!newAccessToken) {
                                throw new Error("Unable to refresh token.");
                            }
                            originalRequest.headers['Authorization'] = `Bearer ${newAccessToken}`;
                            processQueue(null, newAccessToken);
                            resolve(api(originalRequest));
                        } catch (err) {
                            processQueue(err, null);
                            reject(err);
                        } finally {
                            isRefreshing.current = false;
                        }
                    });
                }

                return Promise.reject(error);
            }
        );

        return () => {
            api.interceptors.request.eject(requestInterceptor);
            api.interceptors.response.eject(responseInterceptor);
        };
    }, [refreshTokenFunc, processQueue]);

    useEffect(() => {
        const fetchUserData = async () => {
            setLoading(true);
            if (!authTokens) {
                setLoading(false);
                return;
            }
            try {
                const response = await api.get('/user/');
                console.log("User data from /user/:", response.data);
                setUser(response.data);
                console.log("User data fetched successfully.");
            } catch (error) {
                if (error.response?.status === 401) {
                    console.warn("Access token expired, attempting to refresh.");
                    const newAccessToken = await refreshTokenFunc();
                    if (newAccessToken) {
                        try {
                            const response = await api.get('/user/');
                            console.log("User data from /user/:", response.data);
                            setUser(response.data);
                            console.log("User data fetched successfully after token refresh.");
                        } catch (error2) {
                            console.error("Error fetching user data after refresh:", error2);
                            setUser(null);
                        }
                    } else {
                        console.warn("Refresh token invalid or expired. Logging out.");
                        setUser(null);
                    }
                } else {
                    console.error('Error fetching user data:', error);
                    setUser(null);
                }
            } finally {
                setLoading(false);
            }
        };

        fetchUserData();
    }, [authTokens, refreshTokenFunc]);

    const termsNotAccepted = useMemo(() => {
        return user && user.terms_of_use_accepted === false;
    }, [user]);

    const acceptTermsOfUse = useCallback(async () => {
        if (!user) return;
        try {
            await api.patch('/profile/', {terms_of_use_accepted: true});
            setUser(prev => ({
                ...prev,
                terms_of_use_accepted: true
            }));
            console.log("User accepted Terms of Use");
        } catch (error) {
            console.error("Failed to update terms_of_use_accepted:", error);
        }
    }, [user]);

    // Дополнительные состояния и методы для попапов (примерно, как было у вас)
    const [isVendorPopupOpen, setIsVendorPopupOpen] = useState(false);
    const [isLawFirmPopupOpen, setIsLawFirmPopupOpen] = useState(false);
    const [shouldOpenCreateVendorPopup, setShouldOpenCreateVendorPopup] = useState(false);
    const [shouldOpenCreateLawFirmPopup, setShouldOpenCreateLawFirmPopup] = useState(false);

    const openVendorPopup = useCallback(() => setIsVendorPopupOpen(true), []);
    const closeVendorPopup = useCallback(() => setIsVendorPopupOpen(false), []);
    const openLawFirmPopup = useCallback(() => setIsLawFirmPopupOpen(true), []);
    const closeLawFirmPopup = useCallback(() => setIsLawFirmPopupOpen(false), []);

    const setCreateVendorFlag = useCallback(() => {
        setShouldOpenCreateVendorPopup(true);
    }, []);
    const setCreateLawFirmFlag = useCallback(() => {
        setShouldOpenCreateLawFirmPopup(true);
    }, []);

    const resetCreateVendorFlag = useCallback(() => {
        setShouldOpenCreateVendorPopup(false);
    }, []);
    const resetCreateLawFirmFlag = useCallback(() => {
        setShouldOpenCreateLawFirmPopup(false);
    }, []);

    const [showCreateLawFirmQuickPopup, setShowCreateLawFirmQuickPopup] = useState(false);
    const [editingLawFirmProfile, setEditingLawFirmProfile] = useState(null);

    const openCreateLawFirmQuickPopup = useCallback((profile = null) => {
        setEditingLawFirmProfile(profile);
        setShowCreateLawFirmQuickPopup(true);
    }, []);

    const closeCreateLawFirmQuickPopup = useCallback(() => {
        setShowCreateLawFirmQuickPopup(false);
        setEditingLawFirmProfile(null);
    }, []);

    const value = useMemo(() => ({
        user,
        setUser,
        authTokens,
        setAuthTokens,
        refreshToken: refreshTokenFunc,
        logout,
        loading,
        isAuthenticated: !!user,
        termsNotAccepted,
        acceptTermsOfUse,
        attorneyCompanyData,
        setAttorneyCompanyData,

        // Ваши состояния для попапов
        isVendorPopupOpen,
        isLawFirmPopupOpen,
        openVendorPopup,
        openLawFirmPopup,
        closeVendorPopup,
        closeLawFirmPopup,
        shouldOpenCreateVendorPopup,
        shouldOpenCreateLawFirmPopup,
        setCreateVendorFlag,
        setCreateLawFirmFlag,
        resetCreateVendorFlag,
        resetCreateLawFirmFlag,
        showCreateLawFirmQuickPopup,
        openCreateLawFirmQuickPopup,
        closeCreateLawFirmQuickPopup,
        editingLawFirmProfile
    }), [
        user,
        authTokens,
        refreshTokenFunc,
        logout,
        loading,
        termsNotAccepted,
        acceptTermsOfUse,
        isVendorPopupOpen,
        isLawFirmPopupOpen,
        openVendorPopup,
        openLawFirmPopup,
        closeVendorPopup,
        closeLawFirmPopup,
        shouldOpenCreateVendorPopup,
        shouldOpenCreateLawFirmPopup,
        setCreateVendorFlag,
        setCreateLawFirmFlag,
        resetCreateVendorFlag,
        resetCreateLawFirmFlag,
        showCreateLawFirmQuickPopup,
        openCreateLawFirmQuickPopup,
        closeCreateLawFirmQuickPopup,
        editingLawFirmProfile,
        attorneyCompanyData
    ]);

    return (
        <UserContext.Provider value={value}>
            {children}
        </UserContext.Provider>
    );
};

export const useUser = () => {
    return useContext(UserContext);
};
