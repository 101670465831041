import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import api from '../../api';
import externalApi from '../../api/externalApi';
import { useNotification } from '../../context/NotificationContext';
import { useUser } from '../../context/UserContext'; // Ensure this is imported
import getLogoUrl from '../../utils/getLogoUrl';
import './ActivateTokenAccount.css';

const VerifyPromoEmailToken = () => {
    const navigate = useNavigate();
    const { showNotification } = useNotification();
    const { token } = useParams();
    const { setAuthTokens, setUser, openCreateLawFirmQuickPopup } = useUser(); // Destructure the new function
    const [loading, setLoading] = useState(true);
    const [attorneyData, setAttorneyData] = useState(null);
    const [agree, setAgree] = useState(false);
    const [testImage, setTestImage] = useState('');

    useEffect(() => {
        const checkTokenValidity = async () => {
            if (!token || token.trim() === '') {
                // No token - proceed without redirection
                fetchActivationImage();
                fetchAttorneyData();
                return;
            }

            try {
                const checkResponse = await api.get(`/check-promo-token/${token}/`);
                if (checkResponse.status >= 200 && checkResponse.status < 300) {
                    if (checkResponse.data.valid) {
                        // Token is valid - notify and redirect to sign-in
                        showNotification(
                            'Token Found',
                            'The token is valid. Redirecting to sign-in.',
                            null,
                            'info'
                        );
                        navigate('/sign-in/');
                        return;
                    } else {
                        // Invalid token - proceed to load activation page
                        fetchActivationImage();
                        fetchAttorneyData();
                    }
                } else {
                    // Non-2xx status - treat as invalid token
                    fetchActivationImage();
                    fetchAttorneyData();
                }
            } catch (error) {
                console.error('Error checking token validity:', error);
                // Error checking token - proceed without token
                fetchActivationImage();
                fetchAttorneyData();
            }
        };

        const fetchActivationImage = async () => {
            try {
                const response = await api.get('/random-signup-photo/');
                if (response.data.image) {
                    const imageUrl = getLogoUrl(response.data.image);
                    setTestImage(imageUrl);
                } else {
                    setTestImage('/assets/sign-in-image.png');
                }
            } catch (error) {
                console.error('Error fetching activation image:', error);
                setTestImage('/assets/sign-in-image.png');
            }
        };

        const fetchAttorneyData = async () => {
            try {
                const response = await externalApi.get(`/promo-attorney-detail/${token}/`);
                if (response.status >= 200 && response.status < 300) {
                    setAttorneyData(response.data);
                } else {
                    showNotification(
                        'Error',
                        'Unable to retrieve lawyer details.',
                        null,
                        'error'
                    );
                }
            } catch (error) {
                console.error('Error fetching attorney data:', error);
                if (error.response) {
                    showNotification(
                        'Error',
                        error.response.data.message || 'There was an error retrieving the attorney\'s details.',
                        null,
                        'error'
                    );
                } else if (error.request) {
                    showNotification(
                        'Network error',
                        'No response from the server. Check your internet connection.',
                        null,
                        'error'
                    );
                } else {
                    showNotification(
                        'Error',
                        'There was an error setting up the request.',
                        null,
                        'error'
                    );
                }
            } finally {
                setLoading(false);
            }
        };

        checkTokenValidity();
    }, [showNotification, token, navigate]);

    const handleActivate = async () => {
        if (!agree) {
            showNotification(
                'Agreement Required',
                'You must agree to the Terms of Use and Privacy Policy.',
                null,
                'error'
            );
            return;
        }

        if (!token || token.trim() === '') {
            showNotification(
                'Invalid Token',
                'The activation token is missing or invalid.',
                null,
                'error'
            );
            navigate('/sign-up/');
            return;
        }

        setLoading(true);

        try {
            console.log('Token from URL:', token);
            const activationUrl = `/activate-promo-token/${token}/`;
            console.log('Activation URL:', activationUrl);

            const response = await api.post(activationUrl, attorneyData);
            console.log('Activation response status:', response.status);
            console.log('Activation response data:', response.data);

            if (response.status >= 200 && response.status < 300) {
                showNotification(
                    'Activation Successful!',
                    response.data.detail || 'Your account has been activated.',
                    null,
                    'success'
                );

                const { tokens } = response.data;
                if (tokens && tokens.access && tokens.refresh) {
                    setAuthTokens({ access: tokens.access, refresh: tokens.refresh });
                    localStorage.setItem('access_token', tokens.access);
                    localStorage.setItem('refresh_token', tokens.refresh);
                    console.log('Tokens saved successfully.');

                    try {
                        const userResponse = await api.get('/user/');
                        console.log('User response status:', userResponse.status);
                        console.log('User response data:', userResponse.data);
                        if (userResponse.status >= 200 && userResponse.status < 300) {
                            setUser(userResponse.data);
                            console.log("User successfully authenticated.");
                        } else {
                            console.warn('Failed to fetch user data:', userResponse);
                            showNotification(
                                'Authentication Warning',
                                'Account activated, but failed to fetch user data.',
                                null,
                                'warning'
                            );
                        }
                    } catch (userError) {
                        console.error('Error fetching user data:', userError);
                        showNotification(
                            'User Data Error',
                            'Account activated, but an error occurred while fetching user data.',
                            null,
                            'error'
                        );
                    }
                } else {
                    console.warn('Tokens not found in the response.');
                    showNotification(
                        'Activation Warning',
                        'Activation succeeded, but tokens are missing.',
                        null,
                        'error'
                    );
                }

                // **Set the flag to show the popup**
                openCreateLawFirmQuickPopup(); // Add this line

                setTimeout(() => {
                    navigate('/profile/');
                }, 3000);
            } else {
                showNotification(
                    'Activation Failed',
                    response.data.detail || 'Failed to activate your account.',
                    null,
                    'error'
                );

                setTimeout(() => {
                    navigate('/sign-up/');
                }, 3000);
            }
        } catch (error) {
            console.error('Error activating account:', error);
            if (error.response) {
                console.error('Error response status:', error.response.status);
                console.error('Error response data:', error.response.data);
                showNotification(
                    'Activation Error',
                    error.response.data.detail || 'An error occurred during activation.',
                    null,
                    'error'
                );
            } else {
                showNotification(
                    'Server Error',
                    'An error occurred while activating your account. Please try again later.',
                    null,
                    'error'
                );
            }

            setTimeout(() => {
                navigate('/sign-up/');
            }, 3000);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="main-form-container">
            <div
                className="main-form__img"
                style={{
                    backgroundColor: '#f5f5f5',
                    backgroundImage: `url(${testImage})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: '50% 50%',
                    backgroundSize: 'cover',
                }}
            ></div>

            <div className="main-form__right">
                <form className="main-form__content" onSubmit={(e) => e.preventDefault()}>
                    <div className="scroll-wrap">
                        <h2>Account Activation</h2>

                        {loading ? (
                            <p>Loading...</p>
                        ) : (
                            attorneyData && (
                                <div className="attorney-card">
                                    <img
                                        src={attorneyData.photo}
                                        alt="Attorney Photo"
                                        onError={(e) => {
                                            e.target.src = '/assets/attorney.jpeg';
                                        }}
                                        loading="lazy"
                                    />
                                    <div className="attorney-info">
                                        <h3>{attorneyData.first_name} {attorneyData.last_name}</h3>
                                        <p>{attorneyData.address}</p>
                                        <p>Bar Number: {attorneyData.bar_code}</p>
                                    </div>
                                </div>
                            )
                        )}

                        <div className="checkbox-container">
                            <label className="label-checkbox">
                                <input
                                    type="checkbox"
                                    name="agreeToTerms"
                                    checked={agree}
                                    onChange={(e) => setAgree(e.target.checked)}
                                />
                                <div className="psevdocheckbox"></div>
                            </label>
                            <span className="text-left">
                                {attorneyData ? (
                                    `I, ${attorneyData.first_name} ${attorneyData.last_name}, agree to AI Revolution LLC `
                                ) : (
                                    'I agree to our '
                                )}
                                <a href="/terms-of-use" className="checkbox__link" target="_blank" rel="noopener noreferrer">
                                    Terms of Use
                                </a>
                                &nbsp;and&nbsp;
                                <a href="/privacy-policy" className="checkbox__link" target="_blank" rel="noopener noreferrer">
                                    Privacy Policy
                                </a>.
                            </span>
                        </div>

                        <button
                            className="button button-black"
                            onClick={handleActivate}
                            disabled={loading || !agree}
                        >
                            {loading ? 'Activating...' : 'Activate Account'}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default VerifyPromoEmailToken;
