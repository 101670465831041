// src/utils/validateAndScaleImage.js

export const validateAndScaleImage = (file, minWidth, minHeight, expectedRatio, tolerance) => {
    return new Promise((resolve, reject) => {
        const img = new Image();
        const objectUrl = URL.createObjectURL(file);

        img.onload = () => {
            const width = img.width;
            const height = img.height;
            const actualRatio = width / height;

            const cleanup = () => {
                URL.revokeObjectURL(objectUrl);
            };

            // Функция масштабирования, если нужно
            const scaleImage = (img, targetWidth, targetHeight) => {
                return new Promise((res, rej) => {
                    const canvas = document.createElement('canvas');
                    canvas.width = targetWidth;
                    canvas.height = targetHeight;

                    const ctx = canvas.getContext('2d');
                    ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

                    canvas.toBlob((blob) => {
                        if (!blob) {
                            rej('Scaling failed.');
                            return;
                        }

                        const scaledFile = new File([blob], 'scaled_image.jpg', {type: 'image/jpeg'});
                        res(scaledFile);
                    }, 'image/jpeg');
                });
            };

            const checkAspectRatio = (ratio) => {
                return (expectedRatio - tolerance <= ratio && ratio <= expectedRatio + tolerance);
            };

            // Проверка минимальных размеров
            if (width < minWidth || height < minHeight) {
                // Нужно масштабировать
                const scaleX = minWidth / width;
                const scaleY = minHeight / height;
                const scale = Math.max(scaleX, scaleY);

                const newWidth = Math.round(width * scale);
                const newHeight = Math.round(height * scale);

                scaleImage(img, newWidth, newHeight)
                    .then((scaledFile) => {
                        cleanup();

                        // Проверим соотношение сторон для масштабированного изображения
                        const scaledImg = new Image();
                        const scaledObjectUrl = URL.createObjectURL(scaledFile);

                        scaledImg.onload = () => {
                            const scaledRatio = scaledImg.width / scaledImg.height;
                            URL.revokeObjectURL(scaledObjectUrl);

                            if (!checkAspectRatio(scaledRatio)) {
                                reject(`Invalid aspect ratio after scaling. Expected approximately ${expectedRatio}:1.`);
                                return;
                            }

                            resolve(scaledFile);
                        };
                        scaledImg.onerror = () => {
                            URL.revokeObjectURL(scaledObjectUrl);
                            reject('Error loading scaled image.');
                        };

                        scaledImg.src = scaledObjectUrl;
                    })
                    .catch((error) => {
                        cleanup();
                        reject(error);
                    });
            } else {
                // Изображение достаточно большое по размеру. Проверим соотношение сторон.
                if (!checkAspectRatio(actualRatio)) {
                    cleanup();
                    reject(`Invalid aspect ratio. Expected approximately ${expectedRatio}:1 (min. size: ${minWidth}x${minHeight} pixels).`);
                    return;
                }

                cleanup();
                resolve(file);
            }
        };

        img.onerror = () => {
            URL.revokeObjectURL(objectUrl);
            reject('Invalid image file.');
        };

        img.src = objectUrl;
    });
};
