import React, {useState} from 'react';
import InputWithLabel from '../Form/Input/InputWithLabel';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom'; // Import ReactDOM for portals
import CropImageModal from "../popups/CropImageModal";
import {useNotification} from "../../context/NotificationContext";
import {validateAndScaleImage} from '../../utils/validateAndScaleImage';

const EditWorkersPopup = ({show, onClose, onSave, initialData}) => {
    const {showNotification} = useNotification();
    const [formData, setFormData] = useState(() => ({
        workers: initialData?.workers?.map(worker => ({
            ...worker,
            specializations: worker.specializations?.length ? worker.specializations : [{name: ''}],
            positions: worker.positions?.length ? worker.positions : [{title: ''}],
            photo: worker.photo || '',
        })) || []
    }));

    const [errors, setErrors] = useState([]);

    // Состояния для кроппера
    const [showCropModal, setShowCropModal] = useState(false);
    const [imageToCrop, setImageToCrop] = useState(null);
    const [currentWorkerIndex, setCurrentWorkerIndex] = useState(null);
    const [isCircleCrop] = useState(true); // Круглый кроп
    const [currentAspectRatio] = useState(1); // 1:1 для круга

    // Минимальные требования к изображению
    const MIN_WIDTH = 208;
    const MIN_HEIGHT = 208;
    const REQUIRED_ASPECT = 1.0;
    const RATIO_TOLERANCE = 0.05;

    const getWorkerImageUrl = (image) => {
        if (image instanceof File) {
            return URL.createObjectURL(image);
        }
        return image || '/assets/worker-default.png';
    };

    const handleWorkerChange = (index, name, value) => {
        const updatedWorkers = formData.workers.map((worker, i) => {
            if (i === index) {
                if (name === 'specialization') {
                    return {
                        ...worker,
                        specializations: [{name: value}]
                    };
                }
                if (name === 'position') {
                    return {
                        ...worker,
                        positions: [{title: value}]
                    };
                }
                return {...worker, [name]: value};
            }
            return worker;
        });

        setFormData((prevData) => ({
            ...prevData,
            workers: updatedWorkers
        }));

        // Очистка ошибок при изменении значения
        setErrors(prevErrors => {
            const newErrors = [...prevErrors];
            if (newErrors[index]) {
                delete newErrors[index][name];
            }
            return newErrors;
        });
    };

    const handleImageChange = (index, e) => {
        const file = e.target.files[0];
        if (file) {
            const tempURL = URL.createObjectURL(file);
            setImageToCrop(tempURL);
            setCurrentWorkerIndex(index);
            setShowCropModal(true);
        } else {
            // Если файл удалён
            const updatedWorkers = formData.workers.map((worker, i) => {
                if (i === index) {
                    return {...worker, photo: ''};
                }
                return worker;
            });

            setFormData((prevData) => ({
                ...prevData,
                workers: updatedWorkers
            }));

            setErrors(prevErrors => {
                const newErrors = [...prevErrors];
                if (newErrors[index]) {
                    delete newErrors[index].photo;
                }
                return newErrors;
            });
        }
    };

    const handleCroppedImageComplete = async (croppedImageBlob) => {
        if (currentWorkerIndex === null) return;

        try {
            let file = new File([croppedImageBlob], 'worker_cropped.jpg', {type: 'image/jpeg'});

            // Используем validateAndScaleImage для проверки и масштабирования
            file = await validateAndScaleImage(
                file,
                MIN_WIDTH,
                MIN_HEIGHT,
                REQUIRED_ASPECT,
                RATIO_TOLERANCE
            );

            // Если все прошло хорошо, сохраняем файл в стейте
            const updatedWorkers = formData.workers.map((worker, i) => {
                if (i === currentWorkerIndex) {
                    return {...worker, photo: file};
                }
                return worker;
            });

            setFormData(prevData => ({
                ...prevData,
                workers: updatedWorkers
            }));

            // Очистим ошибки по фото, если они были
            setErrors(prevErrors => {
                const newErrors = [...prevErrors];
                if (newErrors[currentWorkerIndex]) {
                    delete newErrors[currentWorkerIndex].photo;
                }
                return newErrors;
            });

        } catch (error) {
            // Если не удалось пройти валидацию или масштабирование
            setErrors(prevErrors => {
                const newErrors = [...prevErrors];
                newErrors[currentWorkerIndex] = {
                    ...newErrors[currentWorkerIndex],
                    photo: error.message || 'Invalid image.'
                };
                return newErrors;
            });
            showNotification('Image Validation Error', error.message || String(error), null, 'error');
        }

        setShowCropModal(false);
        setImageToCrop(null);
        setCurrentWorkerIndex(null);
    };

    const handleAddWorker = () => {
        setFormData((prevData) => ({
            ...prevData,
            workers: [...prevData.workers, {
                id: null,
                first_name: '',
                last_name: '',
                specializations: [{name: ''}],
                positions: [{title: ''}],
                photo: ''
            }]
        }));
        setErrors(prevErrors => [...prevErrors, {}]);
    };

    const handleDeleteWorker = (index) => {
        const updatedWorkers = formData.workers.filter((_, i) => i !== index);
        setFormData((prevData) => ({
            ...prevData,
            workers: updatedWorkers
        }));
        setErrors(prevErrors => prevErrors.filter((_, i) => i !== index));
    };

    const validateWorker = (worker) => {
        const workerErrors = {};

        if (!worker.first_name.trim()) {
            workerErrors.first_name = 'First name is required.';
        }

        if (!worker.last_name.trim()) {
            workerErrors.last_name = 'Last name is required.';
        }

        if (!worker.specializations || worker.specializations.length === 0 || !worker.specializations[0].name.trim()) {
            workerErrors.specialization = 'Specialization is required.';
        }

        if (!worker.positions || worker.positions.length === 0 || !worker.positions[0].title.trim()) {
            workerErrors.position = 'Position is required.';
        }

        return workerErrors;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const newErrors = formData.workers.map(worker => validateWorker(worker));
        setErrors(newErrors);

        const hasErrors = newErrors.some(workerErrors => Object.keys(workerErrors).length > 0);
        if (hasErrors) {
            console.error("There are errors in the form.");
            return;
        }

        const formDataToSend = new FormData();
        let hasValidWorker = false;

        for (let index = 0; index < formData.workers.length; index++) {
            const worker = formData.workers[index];

            if (worker.first_name.trim() === '' && worker.last_name.trim() === '') continue;

            if (worker.id) {
                formDataToSend.append(`workers[${index}][id]`, worker.id);
            }
            formDataToSend.append(`workers[${index}][first_name]`, worker.first_name);
            formDataToSend.append(`workers[${index}][last_name]`, worker.last_name);

            if (worker.specializations && worker.specializations.length > 0) {
                formDataToSend.append(`workers[${index}][specialization]`, worker.specializations[0].name);
            }

            if (worker.positions && worker.positions.length > 0) {
                formDataToSend.append(`workers[${index}][position]`, worker.positions[0].title);
            }

            if (worker.photo instanceof File) {
                formDataToSend.append(`workers[${index}][photo]`, worker.photo);
            } else if (!worker.id && !worker.photo) {
                const response = await fetch('/assets/worker-default.png');
                const blob = await response.blob();
                const defaultImageFile = new File([blob], 'worker-default.png', {type: 'image/png'});
                formDataToSend.append(`workers[${index}][photo]`, defaultImageFile);
            }

            hasValidWorker = true;
        }

        if (hasValidWorker) {
            await onSave(formDataToSend);
        } else {
            console.error("No valid workers to save.");
        }
    };

    if (!show) return null;

    const renderInputWithLabel = (index, field, label) => {
        let fieldValue = '';

        if (field === 'specialization' && Array.isArray(formData.workers[index].specializations)) {
            fieldValue = formData.workers[index].specializations[0]?.name || '';
        } else if (field === 'position' && Array.isArray(formData.workers[index].positions)) {
            fieldValue = formData.workers[index].positions[0]?.title || '';
        } else {
            fieldValue = formData.workers[index][field] || '';
        }

        return (
            <InputWithLabel
                name={field}
                value={fieldValue}
                onChange={(e) => handleWorkerChange(index, field, e.target.value)}
                label={label}
                error={errors[index]?.[field]}
            />
        );
    };

    return ReactDOM.createPortal(
        <div className="modal visible" id="edit-workers">
            <div className="modal__overlay" onClick={onClose}></div>
            <div className="modal__content">
                <button onClick={onClose} className="button no-style close js--close-modal" aria-label="Close Popup">
                    <img src="/assets/icon--close-CN6QlgAA.svg" alt="Close"/>
                </button>
                <div className="modal__form">
                    <form className="content" onSubmit={handleSubmit} noValidate>
                        <h2>Edit Workers</h2>
                        {formData.workers.map((worker, index) => (
                            <React.Fragment key={index}>
                                {renderInputWithLabel(index, 'first_name', 'First Name')}
                                {renderInputWithLabel(index, 'last_name', 'Last Name')}
                                {renderInputWithLabel(index, 'specialization', 'Specialization')}
                                {renderInputWithLabel(index, 'position', 'Position')}

                                <div className="profile__logo">
                                    <picture>
                                        <img src={getWorkerImageUrl(worker.photo)}
                                             alt={`${worker.first_name} ${worker.last_name} image`}/>
                                    </picture>
                                    <label className="userpick__edit">
                                        <img src="/assets/icon--edit-bg-BVfPEI-f.svg" alt="Edit"/>
                                        <input type="file" onChange={(e) => handleImageChange(index, e)}
                                               accept="image/jpeg, image/png, image/jpg"/>
                                    </label>
                                </div>
                                {errors[index] && errors[index].photo && (
                                    <span className="error-message">{errors[index].photo}</span>
                                )}

                                <button
                                    className="button no-style"
                                    type="button"
                                    onClick={() => handleDeleteWorker(index)}
                                >
                                    Delete
                                </button>
                            </React.Fragment>
                        ))}

                        <button
                            className="button button-border"
                            type="button"
                            onClick={handleAddWorker}
                        >
                            Add New Worker
                        </button>
                        <button className="button button-black" type="submit">Save Workers</button>
                    </form>
                </div>
            </div>

            <CropImageModal
                show={showCropModal}
                onClose={() => setShowCropModal(false)}
                image={imageToCrop}
                aspect={currentAspectRatio}
                onComplete={handleCroppedImageComplete}
                isCircle={isCircleCrop}
            />
        </div>,
        document.body
    );
};

EditWorkersPopup.propTypes = {
    show: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    initialData: PropTypes.shape({
        workers: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.number,
            first_name: PropTypes.string,
            last_name: PropTypes.string,
            specializations: PropTypes.arrayOf(PropTypes.shape({
                name: PropTypes.string
            })),
            positions: PropTypes.arrayOf(PropTypes.shape({
                title: PropTypes.string
            })),
            photo: PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.instanceOf(File)
            ]),
        }))
    }),
};

EditWorkersPopup.defaultProps = {
    initialData: {
        workers: []
    }
};

export default EditWorkersPopup;
