import React, {useState, useEffect, useCallback} from 'react';
import ReactDOM from 'react-dom';
import InputWithLabel from '../Form/Input/InputWithLabel';
import MultiSelectInColumnWithLabelExtension from '../../components/MultiSelects/MultiSelectInColumnWithLabelExtension';
import api from '../../api';
import Cookies from 'js-cookie';
import PropTypes from 'prop-types';

export default function CreateAttorneyAndCompanyPopup({show, attorneyData, onClose}) {
    const currentYear = new Date().getFullYear();

    const [formData, setFormData] = useState({
        companyName: '',
        firstName: '',
        lastName: '',
        email: '',
        licenseYear: '',
        specializations: [],
        registrationNumber: '',
        phoneNumber: '',
        address: {
            country_id: '1',
            state_id: '',
            city_id: '',
            zip_code_id: '',
            street_address: ''
        }
    });

    const [errors, setErrors] = useState({});
    const [allSpecializations, setAllSpecializations] = useState([]);
    const [agree, setAgree] = useState(false);

    const [states, setStates] = useState([]);
    const [cities, setCities] = useState([]);
    const [zipCodes, setZipCodes] = useState([]);

    const [addressProcessed, setAddressProcessed] = useState(false);
    const [addressData, setAddressData] = useState(null);

    const [needToSetCity, setNeedToSetCity] = useState(false);
    const [needToSetZip, setNeedToSetZip] = useState(false);
    const [needToSetStreet, setNeedToSetStreet] = useState(false);

    const getRequestConfig = useCallback(() => {
        const token = localStorage.getItem('authToken');
        const csrfToken = Cookies.get('csrftoken');
        return {
            headers: {
                'Authorization': `Bearer ${token}`,
                'X-CSRFToken': csrfToken,
            },
            withCredentials: true,
        };
    }, []);

    const fetchStates = useCallback(async (country_id) => {
        try {
            const response = await api.get(`/states-filtered/?country_id=${country_id}`, getRequestConfig());
            setStates(response.data);
        } catch (error) {
            console.error('Error fetching states:', error);
        }
    }, [getRequestConfig]);

    const fetchCities = useCallback(async (state_id) => {
        try {
            const response = await api.get(`/cities-filtered/?state_id=${state_id}`, getRequestConfig());
            setCities(response.data);
        } catch (error) {
            console.error('Error fetching cities:', error);
        }
    }, [getRequestConfig]);

    const fetchZipCodes = useCallback(async (city_id) => {
        try {
            const response = await api.get(`/zip-codes/?city_id=${city_id}`, getRequestConfig());
            setZipCodes(response.data);
        } catch (error) {
            console.error('Error fetching zip codes:', error);
        }
    }, [getRequestConfig]);

    useEffect(() => {
        fetchStates('1');
    }, [fetchStates]);

    useEffect(() => {
        if (formData.address.state_id) {
            fetchCities(formData.address.state_id);
        } else {
            setCities([]);
            setFormData(prev => ({
                ...prev,
                address: {
                    ...prev.address,
                    city_id: '',
                    zip_code_id: '',
                    street_address: ''
                }
            }));
        }
    }, [formData.address.state_id, fetchCities]);

    useEffect(() => {
        if (formData.address.city_id) {
            fetchZipCodes(formData.address.city_id);
        } else {
            setZipCodes([]);
            setFormData(prev => ({
                ...prev,
                address: {
                    ...prev.address,
                    zip_code_id: '',
                    street_address: ''
                },
            }));
        }
    }, [formData.address.city_id, fetchZipCodes]);

    useEffect(() => {
        const fetchAllSpecializations = async () => {
            try {
                const response = await api.get('/specializations-full/');
                setAllSpecializations(response.data);
            } catch (error) {
                console.error('Error fetching all specializations:', error);
            }
        };

        fetchAllSpecializations();
    }, []);

    const handleChange = useCallback((e) => {
        const {name, value} = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    }, []);

    const handleAddressChange = (name, value) => {
        setFormData(prev => ({
            ...prev,
            address: {
                ...prev.address,
                [name]: value
            }
        }));
    };

    const handleSpecializationChange = (selectedSpecs) => {
        const codes = selectedSpecs.map(spec => spec.specialization_id);
        setFormData((prevData) => ({
            ...prevData,
            specializations: codes,
        }));
    };

    useEffect(() => {
        const processAttorneyAddress = async () => {
            if (attorneyData && attorneyData.address && attorneyData.address.trim() !== '' && !addressProcessed) {
                try {
                    const response = await api.post('/process-address/', {address: attorneyData.address}, getRequestConfig());
                    if (response.status >= 200 && response.status < 300) {
                        const addrData = response.data;

                        setAddressData(addrData);

                        setFormData(prev => ({
                            ...prev,
                            firstName: attorneyData.first_name || '',
                            lastName: attorneyData.last_name || '',
                            email: attorneyData.email || '',
                            phoneNumber: attorneyData.phone || '',
                            licenseYear: attorneyData.admission_date ? attorneyData.admission_date.split('-')[0] : '',
                            registrationNumber: attorneyData.bar_code ? attorneyData.bar_code.toString() : '',
                            companyName: attorneyData.full_name || ''
                        }));

                        const foundState = states.find(st => st.name.toLowerCase().trim() === addrData.state.toLowerCase().trim());
                        const stateId = foundState ? foundState.state_id : '';
                        if (stateId) {
                            setFormData(prev => ({
                                ...prev,
                                address: {
                                    ...prev.address,
                                    state_id: stateId
                                }
                            }));
                            setNeedToSetCity(true);
                        } else {
                            console.error('State not found for', addrData.state);
                        }
                    } else {
                        console.error('Error processing address:', response);
                    }
                } catch (error) {
                    console.error('Error processing address:', error);
                } finally {
                    setAddressProcessed(true);
                }
            }
        };
        if (states.length > 0) {
            processAttorneyAddress();
        }
    }, [attorneyData, addressProcessed, states, getRequestConfig]);

    useEffect(() => {
        if (needToSetCity && addressData && cities.length > 0) {
            const foundCity = cities.find(ct => ct.name.toLowerCase().trim() === addressData.city.toLowerCase().trim());
            const cityId = foundCity ? foundCity.city_id : '';
            if (cityId) {
                setFormData(prev => ({
                    ...prev,
                    address: {
                        ...prev.address,
                        city_id: cityId
                    }
                }));
                setNeedToSetCity(false);
                setNeedToSetZip(true);
            } else {
                console.error('City not found for', addressData.city);
            }
        }
    }, [needToSetCity, addressData, cities]);

    useEffect(() => {
        if (needToSetZip && addressData && zipCodes.length > 0) {
            const foundZip = zipCodes.find(z => z.zip_code === addressData.zip_code);
            const zipId = foundZip ? foundZip.id : '';
            if (zipId) {
                setFormData(prev => ({
                    ...prev,
                    address: {
                        ...prev.address,
                        zip_code_id: zipId
                    }
                }));
                setNeedToSetZip(false);
                setNeedToSetStreet(true);
            } else {
                console.error('ZIP not found for', addressData.zip_code);
            }
        }
    }, [needToSetZip, addressData, zipCodes]);

    useEffect(() => {
        if (needToSetStreet && addressData) {
            setFormData(prev => ({
                ...prev,
                address: {
                    ...prev.address,
                    street_address: addressData.street_address || ''
                }
            }));
            setNeedToSetStreet(false);
        }
    }, [needToSetStreet, addressData]);

    useEffect(() => {
        if (attorneyData && allSpecializations.length > 0 && attorneyData.practice_areas && attorneyData.practice_areas.length > 0) {
            const matchedSpecs = allSpecializations.filter(spec => {
                const paClean = attorneyData.practice_areas.map(pa => pa.toLowerCase().trim());
                return paClean.includes(spec.name.toLowerCase().trim());
            });

            if (matchedSpecs.length > 0) {
                const codes = matchedSpecs.map(spec => spec.specialization_id);
                setFormData(prevData => ({
                    ...prevData,
                    specializations: codes
                }));
            }
        }
    }, [attorneyData, allSpecializations]);

    const validateForm = useCallback(() => {
        const newErrors = {};

        if (!formData.companyName.trim()) {
            newErrors.companyName = 'Company name is required';
        }
        if (!formData.firstName.trim()) {
            newErrors.firstName = 'First name is required';
        }
        if (!formData.lastName.trim()) {
            newErrors.lastName = 'Last name is required';
        }
        if (!formData.email.trim()) {
            newErrors.email = 'Email is required';
        } else {
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (!emailRegex.test(formData.email)) {
                newErrors.email = 'Invalid email format';
            }
        }
        if (!formData.phoneNumber.trim()) {
            newErrors.phoneNumber = 'Phone number is required';
        } else {
            const phoneRegex = /^\+?[0-9\s\-()]{7,20}$/;
            if (!phoneRegex.test(formData.phoneNumber)) {
                newErrors.phoneNumber = 'Invalid phone number format';
            }
        }
        if (!formData.licenseYear) {
            newErrors.licenseYear = 'License year is required';
        } else {
            const year = Number(formData.licenseYear);
            if (isNaN(year) || year < 1900 || year > currentYear) {
                newErrors.licenseYear = `License year must be between 1900 and ${currentYear}`;
            }
        }
        if (!formData.registrationNumber.trim()) {
            newErrors.registrationNumber = 'Registration number is required';
        }
        if (!formData.specializations || formData.specializations.length === 0) {
            newErrors.specializations = 'At least one specialization must be selected';
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    }, [formData, currentYear]);

    const handleCreate = async (e) => {
        e.preventDefault();
        if (!validateForm()) {
            return;
        }

        const formDataToSend = new FormData();
        formDataToSend.append('name', formData.companyName);
        formDataToSend.append('attorney_first_name', formData.firstName);
        formDataToSend.append('attorney_last_name', formData.lastName);
        formDataToSend.append('attorney_email', formData.email);
        formDataToSend.append('attorney_phone', formData.phoneNumber);
        formDataToSend.append('attorney_license_year', formData.licenseYear);
        formDataToSend.append('attorney_registration_number', formData.registrationNumber);

        formData.specializations.forEach(specId => {
            formDataToSend.append('attorney_specializations', specId);
        });

        const addressPayload = {
            country_id: formData.address.country_id,
            state_id: formData.address.state_id,
            city_id: formData.address.city_id,
            zip_code_id: formData.address.zip_code_id,
            street_address: formData.address.street_address
        };
        formDataToSend.append('address', JSON.stringify(addressPayload));

        formDataToSend.append('foundation_year', '2020');
        formDataToSend.append('description', 'This is a sample law firm description that is longer than 120 characters. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt.');
        formDataToSend.append('email', formData.email);
        formDataToSend.append('phone_number', formData.phoneNumber);
        formDataToSend.append('status', 'active');
        formDataToSend.append('source', 'CreateLawFirmQuick');

        try {
            const config = getRequestConfig();
            const response = await api.post('/combined-creation/', formDataToSend, config);

            if (response.status === 201 || response.status === 200) {
                console.log('Attorney and Company created successfully');
                // Закрываем попап после успешного создания
                if (onClose) {
                    onClose();
                }
            } else {
                console.error('Error adding attorney and company:', response);
                setErrors({general: 'Error creating attorney and company. Please try again.'});
            }
        } catch (error) {
            console.error('Error adding attorney and company:', error.response ? error.response.data : error.message);
            setErrors({general: 'Error creating attorney and company. Please try again.'});
        }
    };

    const selectedSpecs = allSpecializations.filter(spec => formData.specializations.includes(spec.specialization_id));

    if (!show) return null;

    return ReactDOM.createPortal(
        <div className="modal visible">
            <div className="modal__overlay" style={{pointerEvents: 'none'}}></div>
            <div className="modal__content">
                <div className="modal__form">
                    <form className="content" onSubmit={handleCreate}>
                        <h2>Create Company and Attorney</h2>
                        {errors.general && (
                            <div className="error-message">
                                {errors.general}
                            </div>
                        )}

                        <InputWithLabel
                            name="companyName"
                            label="Company Name"
                            value={formData.companyName}
                            onChange={handleChange}
                            error={errors.companyName}
                        />
                        <InputWithLabel
                            name="firstName"
                            label="First Name"
                            value={formData.firstName}
                            onChange={handleChange}
                            error={errors.firstName}
                        />
                        <InputWithLabel
                            name="lastName"
                            label="Last Name"
                            value={formData.lastName}
                            onChange={handleChange}
                            error={errors.lastName}
                        />
                        <InputWithLabel
                            name="phoneNumber"
                            label="Phone Number"
                            value={formData.phoneNumber}
                            onChange={handleChange}
                            error={errors.phoneNumber}
                        />
                        <InputWithLabel
                            name="email"
                            label="Email"
                            value={formData.email}
                            onChange={handleChange}
                            error={errors.email}
                        />
                        <InputWithLabel
                            name="licenseYear"
                            label="License Year"
                            value={formData.licenseYear}
                            onChange={handleChange}
                            type="number"
                            min="1900"
                            max={currentYear}
                            error={errors.licenseYear}
                        />
                        <InputWithLabel
                            name="registrationNumber"
                            label="Registration Number"
                            value={formData.registrationNumber}
                            onChange={handleChange}
                            error={errors.registrationNumber}
                        />

                        <div className="form-group">
                            <label className="form-label">Specializations</label>
                            <MultiSelectInColumnWithLabelExtension
                                label=""
                                options={allSpecializations}
                                onChange={handleSpecializationChange}
                                placeholder="Select specializations"
                                initialValue={selectedSpecs}
                                maxSelectedLabels={3}
                            />
                            {errors.specializations && (
                                <span className="error-message">{errors.specializations}</span>
                            )}
                        </div>

                        <label className="form-label">Address</label>
                        <select
                            className={`input`}
                            name="state_id"
                            value={formData.address.state_id}
                            onChange={(e) => handleAddressChange('state_id', e.target.value)}
                        >
                            <option value="" disabled>Select State</option>
                            {states.map((state) => (
                                <option key={state.state_id} value={state.state_id}>
                                    {state.name}
                                </option>
                            ))}
                        </select>

                        {formData.address.state_id && (
                            <select
                                className={`input`}
                                name="city_id"
                                value={formData.address.city_id}
                                onChange={(e) => handleAddressChange('city_id', e.target.value)}
                            >
                                <option value="" disabled>Select City</option>
                                {cities.map((city) => (
                                    <option key={city.city_id} value={city.city_id}>
                                        {city.name}
                                    </option>
                                ))}
                            </select>
                        )}

                        {formData.address.city_id && (
                            <select
                                className={`input`}
                                name="zip_code_id"
                                value={formData.address.zip_code_id}
                                onChange={(e) => handleAddressChange('zip_code_id', e.target.value)}
                            >
                                <option value="" disabled>Select Zip Code</option>
                                {zipCodes.map((zip) => (
                                    <option key={zip.id} value={zip.id}>
                                        {zip.zip_code}
                                    </option>
                                ))}
                            </select>
                        )}

                        {formData.address.zip_code_id && (
                            <InputWithLabel
                                name="street_address"
                                label="Street Address"
                                value={formData.address.street_address}
                                onChange={(e) => handleAddressChange('street_address', e.target.value)}
                            />
                        )}

                        <div className="checkbox-container" style={{marginTop: '20px'}}>
                            <label className="label-checkbox">
                                <input
                                    type="checkbox"
                                    name="agreeToTerms"
                                    checked={agree}
                                    onChange={(e) => setAgree(e.target.checked)}
                                />
                                <div className="psevdocheckbox"></div>
                            </label>
                            <span className="text-left">
                                I agree to the{' '}
                                <a href="/terms-of-use" className="checkbox__link" target="_blank"
                                   rel="noopener noreferrer">
                                    Terms of Use
                                </a>{' '}
                                and{' '}
                                <a href="/privacy-policy" className="checkbox__link" target="_blank"
                                   rel="noopener noreferrer">
                                    Privacy Policy
                                </a>.
                            </span>
                        </div>

                        <button
                            type="submit"
                            className="button button-black"
                            disabled={!agree}
                        >
                            Create
                        </button>
                    </form>
                </div>
            </div>
        </div>,
        document.body
    );
}

CreateAttorneyAndCompanyPopup.propTypes = {
    show: PropTypes.bool.isRequired,
    attorneyData: PropTypes.object,
    onClose: PropTypes.func, // Добавили тип для onClose
};

CreateAttorneyAndCompanyPopup.defaultProps = {
    show: false,
    attorneyData: null,
    onClose: null,
};
