// components/PopupAttorney.js
import React, {useEffect, useState, useContext, useCallback} from 'react';
import api from '../api';
import './shore-more.css';
import {SearchContext} from "../context/SearchContext";
import EditAwardsPopup from "./popups/EditAwardsPopup";
import EditLanguagesPopup from "./popups/EditLanguagesPopup";
import {useUser} from "../context/UserContext";
import Cookies from "js-cookie";
import EditProfilePopup from "./popups/EditProfilePopup";
import EditEducationsPopup from "./popups/EditEducationsPopup";
import EditPreviousWorkplacesPopup from "./popups/EditPreviousWorkplacesPopup";
import EditSpecializationsPopup from "./popups/EditSpecializationsPopup";
import {useNotification} from "../context/NotificationContext";
import Notification from "./Notification";
import SendMessageModal from "./popups/SendMessageModal";
import CropImageModal from './popups/CropImageModal';
import {validateAndScaleImage} from '../utils/validateAndScaleImage';

const IMAGE_REQUIREMENTS = {
    'attorney_photo': {
        'min_width': 172,
        'min_height': 172,
        'aspect_ratio': 1.0,
        'ratio_tolerance': 0.05,
    },
    'background_attorney': {
        'min_width': 1440,
        'min_height': 280,
        'aspect_ratio': 5.14,
        'ratio_tolerance': 0.05,
    },
};

const PopupAttorney = ({visible, attorney, onClose, showSharePopup}) => {
    const [legalCompanies, setLegalCompanies] = useState([]);
    const [showAllCompanies, setShowAllCompanies] = useState(false);
    const [showAllPreviousWorkplaces, setShowAllPreviousWorkplaces] = useState(false);
    const [showAllEducation, setShowAllEducation] = useState(false);
    const [isFavorite, setIsFavorite] = useState(attorney.is_favorite);
    const {bannerHeight} = useContext(SearchContext);
    const [currentAttorney, setCurrentAttorney] = useState(attorney);
    const [showEditLanguagesPopup, setShowEditLanguagesPopup] = useState(false);
    const [showEditSpecializationsPopup, setShowEditSpecializationsPopup] = useState(false);
    const [showEditAwardsPopup, setShowEditAwardsPopup] = useState(false);
    const {showNotification} = useNotification();
    const [showEditProfilePopup, setShowEditProfilePopup] = useState(false);
    const [showEditEducationsPopup, setShowEditEducationsPopup] = useState(false);
    const [showEditPreviousWorkplacesPopup, setShowEditPreviousWorkplacesPopup] = useState(false);
    const {user} = useUser();
    const [showSendMessageModal, setShowSendMessageModal] = useState(false);
    const [isCircleCrop, setIsCircleCrop] = useState(false);

    // Состояния для кроппера
    const [showCropModal, setShowCropModal] = useState(false);
    const [imageToCrop, setImageToCrop] = useState(null);
    const [currentFieldToUpdate, setCurrentFieldToUpdate] = useState(null);
    const [currentAspectRatio, setCurrentAspectRatio] = useState(1.0);

    const getRequestConfig = () => {
        const token = localStorage.getItem('authToken');
        const csrfToken = Cookies.get('csrftoken');
        return {
            headers: {
                'Authorization': `Bearer ${token}`,
                'X-CSRFToken': csrfToken,
            },
            withCredentials: true,
        };
    };

    useEffect(() => {
        if (!attorney) return;

        const fetchAttorneyDetails = async (attorneyId) => {
            try {
                const response = await api.get(`/attorneys/${attorneyId}/`);
                setCurrentAttorney(response.data);
            } catch (error) {
                console.error('Error fetching attorney details:', error);
            }
        };

        fetchAttorneyDetails(attorney.attorney_id);
    }, [attorney]);

    const formatCompanyAddress = (address) => {
        if (!address) return 'Address not available';
        const {street_address, city, state, country, zip_code} = address;
        return `${street_address}, ${city.name}, ${state.name}, ${country.name}, ${zip_code.zip_code}`;
    };

    const googleMapsUrlForCompanyAddress = (address) => {
        const formattedAddress = formatCompanyAddress(address);
        return `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(formattedAddress)}`;
    };

    const formatPhoneNumber = (phoneNumber) => {
        let cleaned = ('' + phoneNumber).replace(/\D/g, '');
        if (!cleaned.startsWith('1')) {
            cleaned = '1' + cleaned;
        }
        const match = cleaned.match(/^(\d{1})(\d{3})(\d{3})(\d{4})$/);
        if (match) {
            return `+${match[1]} (${match[2]}) ${match[3]}-${match[4]}`;
        }
        return phoneNumber;
    };

    const formatAmount = (amount) => {
        return new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            maximumFractionDigits: 0
        }).format(amount);
    };

    const isCurrentUserAttorney = () => {
        if (!currentAttorney || !currentAttorney.user) {
            return false;
        }
        return user && user.id === currentAttorney.user;
    };

    const fetchAttorneyDetails = async (attorneyId) => {
        try {
            const response = await api.get(`/attorneys/${attorneyId}/`);
            setCurrentAttorney(response.data);
        } catch (error) {
            console.error('Error fetching attorney details:', error);
        }
    };

    const handleSaveProfile = async (updatedData) => {
        try {
            const formData = new FormData();
            Object.keys(updatedData).forEach(key => {
                if (
                    key !== 'addresses' &&
                    key !== 'specializations' &&
                    key !== 'languages' &&
                    key !== 'social_media' &&
                    key !== 'additional_phones'
                ) {
                    formData.append(key, updatedData[key]);
                }
            });

            if (updatedData.address && typeof updatedData.address === 'object') {
                formData.append('address', JSON.stringify(updatedData.address));
            }

            if (updatedData.specializations) {
                formData.append('specializations', JSON.stringify(updatedData.specializations));
            }
            if (updatedData.languages) {
                formData.append('languages', JSON.stringify(updatedData.languages));
            }
            if (updatedData.additional_phones) {
                formData.append('additional_phones', JSON.stringify(updatedData.additional_phones));
            }
            if (updatedData.social_media && Array.isArray(updatedData.social_media)) {
                formData.append('social_media', JSON.stringify(updatedData.social_media));
            }

            const config = getRequestConfig();
            const response = await api.patch(`/attorneys/${currentAttorney.attorney_id}/update/`, formData, config);

            if (response.status === 200) {
                await fetchAttorneyDetails(currentAttorney.attorney_id);
                handlerCloseEditProfilePopup();
            }
        } catch (error) {
            console.error('Error saving profile:', error);
        }
    };

    const handleSaveEducations = async (updatedData) => {
        try {
            const educationData = {
                educations: updatedData.educations.map(edu => ({
                    institution: edu.institution,
                    degree: edu.degree,
                    start_date: edu.start_date,
                    end_date: edu.end_date
                }))
            };

            const config = getRequestConfig();
            const response = await api.patch(`/attorneys/${currentAttorney.attorney_id}/educations/update/`, educationData, config);

            if (response.status === 200) {
                await fetchAttorneyDetails(currentAttorney.attorney_id);
                setShowEditEducationsPopup(false);
            }
        } catch (error) {
            console.error('Error saving educations:', error);
        }
    };

    const handlerShowEditLanguagesPopup = useCallback(() => {
        setShowEditLanguagesPopup(true);
    }, []);

    const handlerCloseEditLanguagesPopup = useCallback(() => {
        setShowEditLanguagesPopup(false);
    }, []);

    const handlerShowEditSpecializationsPopup = useCallback(() => {
        setShowEditSpecializationsPopup(true);
    }, []);

    const handlerCloseEditSpecializationsPopup = useCallback(() => {
        setShowEditSpecializationsPopup(false);
    }, []);

    const handlerShowEditAwardsPopup = useCallback(() => {
        setShowEditAwardsPopup(true);
    }, []);

    const handlerCloseEditAwardsPopup = useCallback(() => {
        setShowEditAwardsPopup(false);
    }, []);

    const handlerShowEditProfilePopup = useCallback(() => {
        setShowEditProfilePopup(true);
    }, []);

    const handlerCloseEditProfilePopup = useCallback(() => {
        setShowEditProfilePopup(false);
    }, []);

    const handlerShowEditEducationsPopup = useCallback(() => {
        setShowEditEducationsPopup(true);
    }, []);

    const handlerCloseEditEducationsPopup = useCallback(() => {
        setShowEditEducationsPopup(false);
    }, []);

    const handlerShowEditPreviousWorkplacesPopup = useCallback(() => {
        setShowEditPreviousWorkplacesPopup(true);
    }, []);

    const handlerCloseEditPreviousWorkplacesPopup = useCallback(() => {
        setShowEditPreviousWorkplacesPopup(false);
    }, []);

    const handleSaveSpecializations = async (updatedSpecializations) => {
        try {
            const specializationsToSave = updatedSpecializations.map(spec => spec.specialization_id);

            const config = getRequestConfig();
            const response = await api.patch(
                `/attorneys/${currentAttorney.attorney_id}/specializations/update/`,
                {specializations: specializationsToSave},
                config
            );

            if (response.status === 200) {
                await fetchAttorneyDetails(currentAttorney.attorney_id);
                handlerCloseEditSpecializationsPopup();
            }
        } catch (error) {
            console.error('Error saving specializations:', error);
            if (error.response && error.response.data && error.response.data.error) {
                alert(`Ошибка: ${error.response.data.error}`);
            } else {
                alert('Произошла неизвестная ошибка.');
            }
        }
    };

    const handleSavePreviousWorkplaces = async (updatedData) => {
        try {
            const config = getRequestConfig();
            const previousWorkplacesData = {
                previous_working_places: updatedData.previous_working_places.map(place => ({
                    name: place.name,
                    position: place.position,
                    start_date: place.start_date,
                    end_date: place.end_date
                }))
            };

            const response = await api.patch(`/attorneys/${currentAttorney.attorney_id}/previous-workplaces/update/`, previousWorkplacesData, config);

            if (response.status === 200) {
                await fetchAttorneyDetails(currentAttorney.attorney_id);
                setShowEditPreviousWorkplacesPopup(false);
            }
        } catch (error) {
            console.error('Error saving previous workplaces:', error);
        }
    };

    const updateAttorneyLanguages = async (attorneyId, languages, config) => {
        try {
            const response = await api.patch(`/attorneys/${attorneyId}/languages/`, {languages}, config);
            return response;
        } catch (error) {
            console.error('Error updating attorney languages:', error);
            throw error;
        }
    };

    const handleSaveLanguages = async (updatedData) => {
        try {
            const languagesToSave = updatedData.map(language => ({
                language_id: language.language_id,
                proficiency_level: language.proficiency_level
            }));

            const config = getRequestConfig();
            const response = await updateAttorneyLanguages(currentAttorney.attorney_id, languagesToSave, config);

            if (response.status === 200) {
                await fetchAttorneyDetails(currentAttorney.attorney_id);
                handlerCloseEditLanguagesPopup();
            }
        } catch (error) {
            console.error('Error saving languages:', error);
        }
    };

    useEffect(() => {
        if (!attorney) return;

        const checkFavoriteStatus = async () => {
            try {
                const token = localStorage.getItem('authToken');
                const config = {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    }
                };
                const response = await api.get(`/check-favorite-attorney/${attorney.attorney_id}/`, config);
                setIsFavorite(response.data.is_favorite);
            } catch (error) {
                console.error('Error checking favorite status:', error);
            }
        };

        checkFavoriteStatus();
    }, [attorney]);

    const handleAddToFavorites = async () => {
        try {
            const response = await api.post('/add-favorite-attorney/', {attorney_id: attorney.attorney_id});

            if (response.status === 200 || response.status === 201) {
                setIsFavorite(true);
            }
        } catch (error) {
            console.error('Error adding to favorites:', error);
        }
    };

    const handleRemoveFromFavorites = async () => {
        try {
            const response = await api.post('/remove-favorite-attorney/', {attorney_id: attorney.attorney_id});

            if (response.status === 200) {
                setIsFavorite(false);
            }
        } catch (error) {
            console.error('Error removing from favorites:', error);
        }
    };

    const handleToggleFavorites = () => {
        if (isFavorite) {
            handleRemoveFromFavorites();
        } else {
            handleAddToFavorites();
        }
    };

    const handleFieldUpdate = async (fieldName, value) => {
        const formData = new FormData();
        formData.append(fieldName, value);

        try {
            const config = getRequestConfig();
            const response = await api.patch(`/attorneys/${currentAttorney.attorney_id}/files/`, formData, config);

            if (response.status === 200) {
                await fetchAttorneyDetails(currentAttorney.attorney_id);
            }
        } catch (error) {
            console.error('Error updating field:', error);
        }
    };

    const handlePhotoChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            const tempURL = URL.createObjectURL(file);
            setImageToCrop(tempURL);
            setCurrentFieldToUpdate('photo');
            setCurrentAspectRatio(1.0); // например, для фото адвоката 1:1
            setIsCircleCrop(true); // Для фото адвоката делаем кроп кругом
            setShowCropModal(true);
        }
    };

    const handleBackgroundImageChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            const tempURL = URL.createObjectURL(file);
            setImageToCrop(tempURL);
            setCurrentFieldToUpdate('background_image');
            setCurrentAspectRatio(5.14); // например, для фонового изображения
            setIsCircleCrop(false); // Для фонового изображения оставляем прямоугольный кроп
            setShowCropModal(true);
        }
    };

    const handleCroppedImageComplete = async (croppedImageBlob) => {
        try {
            const file = new File([croppedImageBlob], 'cropped_image.jpg', {type: 'image/jpeg'});
            const requirements = (currentFieldToUpdate === 'photo')
                ? IMAGE_REQUIREMENTS['attorney_photo']
                : IMAGE_REQUIREMENTS['background_attorney'];

            const validatedAndScaledFile = await validateAndScaleImage(
                file,
                requirements.min_width,
                requirements.min_height,
                requirements.aspect_ratio,
                requirements.ratio_tolerance
            );

            await handleFieldUpdate(currentFieldToUpdate, validatedAndScaledFile);

        } catch (error) {
            showNotification('Image Validation Error', error, null, 'error');
        }

        setShowCropModal(false);
        setImageToCrop(null);
        setCurrentFieldToUpdate(null);
    };

    useEffect(() => {
        if (attorney) {
            const fetchLegalCompanies = async () => {
                try {
                    const response = await api.get(`/attorneys/${attorney.attorney_id}/legal-companies/`);
                    setLegalCompanies(response.data);
                } catch (error) {
                    console.error('Error fetching legal companies:', error);
                }
            };

            fetchLegalCompanies();
        }

        if (visible) {
            document.body.classList.add('fixed-height');
        } else {
            document.body.classList.remove('fixed-height');
        }

        return () => {
            document.body.classList.remove('fixed-height');
        };
    }, [attorney, visible]);

    if (!visible || !attorney) return null;

    const renderFreeConsultation = () => {
        if (currentAttorney.free_consultation) {
            return (
                <span className="vendor__status vendor__status-active">FREE CONSULTATION</span>
            );
        }
        return null;
    };

    const formattedAddress = currentAttorney.addresses && currentAttorney.addresses.length > 0
        ? currentAttorney.addresses.map(addr => `${addr.street_address}, ${addr.city.name}, ${addr.state.name}, ${addr.country.name}, ${addr.zip_code.zip_code}`).join('\n')
        : 'Address not available';

    const googleMapsUrl = `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(formattedAddress)}`;

    const companiesToShow = showAllCompanies ? legalCompanies : legalCompanies.slice(0, 3);
    const previousWorkPlacesToShow = showAllPreviousWorkplaces ? currentAttorney.previous_working_places : currentAttorney.previous_working_places.slice(0, 2);
    const educationToShow = currentAttorney.education && currentAttorney.education.length > 0
        ? currentAttorney.education.slice(0, 2)
        : [];

    const calculateDuration = (startDate, endDate) => {
        const start = new Date(startDate);
        const end = new Date(endDate);
        const diffInMonths = (end.getFullYear() - start.getFullYear()) * 12 + end.getMonth() - start.getMonth();
        const years = Math.floor(diffInMonths / 12);
        const months = diffInMonths % 12;

        if (years > 0) {
            return `${years} year${years > 1 ? 's' : ''}${months > 0 ? ` and ${months} month${months > 1 ? 's' : ''}` : ''} in this position`;
        }
        return `${months} month${months > 1 ? 's' : ''} in this position`;
    };

    const handleSaveAwards = async (formDataToSend) => {
        try {
            const config = getRequestConfig();
            const response = await api.patch(`/attorneys/${currentAttorney.attorney_id}/awards/`, formDataToSend, config);

            if (response.status === 200) {
                await fetchAttorneyDetails(currentAttorney.attorney_id);
                handlerCloseEditAwardsPopup();
                showNotification('Success', 'Awards updated successfully.', null, 'success');
            }
        } catch (error) {
            console.error('Error saving awards:', error);
            showNotification('Error', 'Failed to save awards.', null, 'error');
        }
    };

    const formatDate = (dateString) => {
        if (!dateString) return 'N/A';

        const [year, month, day] = dateString.split('-');
        const monthNames = [
            'January', 'February', 'March', 'April', 'May', 'June',
            'July', 'August', 'September', 'October', 'November', 'December'
        ];
        const formattedMonth = monthNames[parseInt(month, 10) - 1];
        return `${formattedMonth} ${parseInt(day, 10)}, ${year}`;
    };

    const handleSendMessageClick = () => {
        setShowSendMessageModal(true);
    };

    const handleCloseSendMessageModal = () => {
        setShowSendMessageModal(false);
    };

    return (
        <div className={`popup ${visible ? 'visible' : ''}`} id="attorney-type1"
             style={{top: `${bannerHeight + 50}px`}}>
            <Notification/>
            <button className="close js--hide-popup" onClick={onClose}>
                <img src="/assets/icon--close-CN6QlgAA.svg" alt="Close"/>
            </button>
            <div className="profile profile-pro">
                <div className="profile__headline profile__headline-attorney"
                     style={{background: `url(${currentAttorney.background_image})`}}>
                    <div className="container">
                        <div className="avatar">
                            {currentAttorney.photo && (
                                <picture>
                                    <source type="image/webp"
                                            srcSet={`${currentAttorney.photo}?format=webp`}/>
                                    <source type="image/jpg"
                                            srcSet={`${currentAttorney.photo}`}/>
                                    <img src={`${currentAttorney.photo}`}
                                         alt={`${currentAttorney.first_name} ${currentAttorney.last_name}`}/>
                                </picture>
                            )}
                            {isCurrentUserAttorney() && (
                                <label className="userpick__edit"><img src="/assets/icon--edit-bg-BVfPEI-f.svg"/>
                                    <input type="file" onChange={handlePhotoChange}/>
                                </label>
                            )}
                        </div>
                        {isCurrentUserAttorney() && (
                            <label className="bg__edit">
                                <div className="button no-style"><img src="/assets/icon--edit-Z9QfuMgm.svg"/> Edit
                                    Big Photo
                                </div>
                                <input type="file" onChange={handleBackgroundImageChange}/>
                            </label>
                        )}
                    </div>
                </div>
                <div className="profile__block">
                    <div className="container">
                        <div className="profile__header">
                            <div className="vendor__header">
                                <h1 className="vendor__name">{currentAttorney.first_name} {currentAttorney.last_name}</h1>
                                <button
                                    className={`favorites ${isFavorite ? 'added' : ''}`}
                                    onClick={handleToggleFavorites}
                                >
                                    <svg>
                                        <path
                                            fillRule="evenodd"
                                            clipRule="evenodd"
                                            d="M1.58733 3.10072C3.70393 0.940385 7.13582 0.940385 9.25246 3.10072L10.0323 3.89665L10.7475 3.16683C12.8641 1.0065 16.296 1.0065 18.4127 3.16683C20.5293 5.32717 20.5292 8.82998 18.4127 10.9904L10.4695 19.0901C10.4418 19.1184 10.5003 19.068 10.4695 19.0901C10.2068 19.2849 9.79324 19.2991 9.5566 19.0576L1.58757 10.9239C-0.52903 8.76353 -0.529268 5.26111 1.58733 3.10072Z"></path>
                                    </svg>
                                </button>
                                <div className="vendor__attorney">
                                    {currentAttorney.license_year && `Licensed in ${currentAttorney.license_year}`}
                                    {currentAttorney.total_won_amount && ` • Total won amount: ${formatAmount(currentAttorney.total_won_amount)} `}
                                    {renderFreeConsultation()}
                                </div>
                            </div>
                            {isCurrentUserAttorney() && (
                                <button className="button no-style" onClick={handlerShowEditProfilePopup}>
                                    <img src="/assets/icon--edit-Z9QfuMgm.svg" alt="Edit"/> Edit profile
                                </button>
                            )}
                        </div>
                        <div className="profile__content userdata">
                            <div className="col-2">
                                <div className="data">
                                    <p>{currentAttorney.description}</p>
                                </div>
                                <div className="profile__contacts">
                                    <ul className="contacts">
                                        {currentAttorney.addresses && currentAttorney.addresses.length > 0 && (
                                            <li className="contacts__el">
                                                <div className="contacts__label">Address</div>
                                                <div className="contacts__value">
                                                    <a href={googleMapsUrl} target="_blank"
                                                       rel="noopener noreferrer">{formattedAddress}</a>
                                                </div>
                                            </li>
                                        )}
                                        {currentAttorney.working_hours && currentAttorney.working_hours.length > 0 && (
                                            <li className="contacts__el">
                                                <div className="contacts__label">Working Hours</div>
                                                <div className="contacts__value">
                                                    <ul className="hours">
                                                        {attorney.working_hours.map((wh, index) => (
                                                            <li key={index}>
                                                                <span>{wh.day_of_week}:</span><span>{wh.start_time} - {wh.end_time}</span>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </div>
                                            </li>
                                        )}
                                        {currentAttorney.email && (
                                            <li className="contacts__el">
                                                <div className="contacts__label">Email</div>
                                                <div className="contacts__value">
                                                    {isCurrentUserAttorney() ? (
                                                        <a href={`mailto:${currentAttorney.email}`}>{currentAttorney.email}</a>
                                                    ) : (
                                                        <button
                                                            onClick={handleSendMessageClick}
                                                            type='button'
                                                            className='button button-black'
                                                            style={{
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                justifyContent: 'center'
                                                            }}
                                                        >
                                                            Send Message
                                                        </button>
                                                    )}
                                                </div>
                                            </li>
                                        )}
                                        {currentAttorney.phone_number && (
                                            <li className="contacts__el">
                                                <div className="contacts__label">Phone</div>
                                                <div className="contacts__value">
                                                    <a href={`tel:${currentAttorney.phone_number}`}>{formatPhoneNumber(currentAttorney.phone_number)}</a>
                                                </div>
                                            </li>
                                        )}
                                        {currentAttorney.additional_phones.length > 0 && (
                                            currentAttorney.additional_phones.map((phone, index) => (
                                                <li key={index} className="contacts__el">
                                                    <div className="contacts__label">{phone.phone_type.name}</div>
                                                    <div className="contacts__value">
                                                        <a href={`tel:${phone.phone_number}`}>{formatPhoneNumber(phone.phone_number)}</a>
                                                    </div>
                                                </li>
                                            ))
                                        )}
                                        {currentAttorney.web_site && (
                                            <li className="contacts__el">
                                                <div className="contacts__label">Web Site</div>
                                                <div className="contacts__value">
                                                    <a href={currentAttorney.web_site} target="_blank"
                                                       rel="noopener noreferrer">{currentAttorney.web_site}</a>
                                                </div>
                                            </li>
                                        )}
                                        {currentAttorney.social_media && currentAttorney.social_media.length > 0 && (
                                            <li className="contacts__el">
                                                <div className="contacts__label">Social Media</div>
                                                <div className="contacts__value">
                                                    <ul className="social">
                                                        {currentAttorney.social_media.map((sm, index) => (
                                                            <li key={index}><a href={sm.url} target="_blank"
                                                                               rel="noopener noreferrer"><img
                                                                src={sm.platform.icon}
                                                                alt={sm.platform.name}/></a></li>
                                                        ))}
                                                    </ul>
                                                </div>
                                            </li>
                                        )}
                                    </ul>
                                    <button onClick={() => showSharePopup({
                                        companyName: `${currentAttorney.first_name} ${currentAttorney.last_name}`,
                                        telephone: currentAttorney.phone_number,
                                        address: currentAttorney.addresses.map(addr => `${addr.street_address}, ${addr.city.name}, ${addr.state.name}, ${addr.country.name}, ${addr.postal_code}`).join('\n'),
                                        specializations: currentAttorney.specializations.map(spec => spec.name)
                                    })} type='button' className='profile__share button no-style'>
                                        Share Profile
                                        <img src="/assets/icon--share.svg" alt="Share Icon"/>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {(isCurrentUserAttorney() || (attorney.specializations && attorney.specializations.length > 0)) && (
                    <div className="profile__block">
                        <div className="container">
                            <div className="profile__header">
                                <h2>Types of services provided by the attorney</h2>
                                {isCurrentUserAttorney() && (
                                    <button className="button no-style" onClick={handlerShowEditSpecializationsPopup}>
                                        <img src="/assets/icon--edit-Z9QfuMgm.svg" alt="Edit Specializations"/>
                                        {(currentAttorney.specializations.length === 0) ? 'Add Types of Services' : 'Edit Types of Services'}
                                    </button>
                                )}
                            </div>
                            <div className="profile__content">
                                <ul className="params__list">
                                    {currentAttorney.specializations.map((spec, index) => (
                                        <li key={index}>{spec.name}</li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                )}
                {(isCurrentUserAttorney() || currentAttorney.languages.length > 0) && (
                    <div className="profile__block">
                        <div className="container">
                            <div className="profile__header"><h2>Languages</h2>
                                {isCurrentUserAttorney() && (
                                    <button className="button no-style" onClick={handlerShowEditLanguagesPopup}>
                                        <img src="/assets/icon--edit-Z9QfuMgm.svg" alt="Edit Regions"/>
                                        {(currentAttorney.languages.length === 0) ? 'Add languages' : 'Edit Languages'}
                                    </button>
                                )}
                            </div>
                            <div className="profile__content">
                                <ul className="params__list">
                                    {currentAttorney.languages.map((lang, index) => (
                                        <li key={index}>{lang.name} ({lang.proficiency_level})</li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                )}
                {legalCompanies.length > 0 && (
                    <div className="profile__block">
                        <div className="container">
                            <div className="profile__header"><h2>Current Workplaces</h2></div>
                            <div className="profile__content">
                                <ul>
                                    {companiesToShow.map((company, index) => (
                                        <li className="vendor vendor-verified" key={index}>
                                            <div className="container">
                                                <div className="justify">
                                                    <div className="vendor__data" style={{cursor: 'default'}}>
                                                        <div className="vendor__header">
                                                            <h4 className="vendor__name">{company.name}</h4>
                                                            {company.is_verified && (
                                                                <div className="verified"><img
                                                                    src="assets/icon--verified-CxOX3a18.svg"
                                                                    alt="Verified"/></div>
                                                            )}
                                                            <span
                                                                className="vendor__attorney">{company.attorneys.length} attorneys on staff</span>
                                                            <p className="vendor__description">{company.description}</p>
                                                        </div>
                                                    </div>
                                                    <div className="vendor__contacts">
                                                        {company.address && (
                                                            <div className="row">
                                                                <a className="vendor__link"
                                                                   href={googleMapsUrlForCompanyAddress(company.address)}
                                                                   target="_blank"
                                                                   rel="noopener noreferrer">{formatCompanyAddress(company.address)}</a>
                                                                <span className="vendor__label">Address</span>
                                                            </div>
                                                        )}
                                                        {company.phone_number && (
                                                            <div className="row">
                                                                <a className="vendor__link"
                                                                   href={`tel:${company.phone_number}`}>{formatPhoneNumber(company.phone_number)}</a>
                                                                <span className="vendor__label">Phone</span>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    ))}
                                    {!showAllCompanies && legalCompanies.length > 3 && (
                                        <li className="show-all">
                                            <div className="container" style={{textAlign: 'center'}}>
                                                <button onClick={() => setShowAllCompanies(true)}>Show More Law
                                                    Companies • {legalCompanies.length - 3} ({legalCompanies.length})
                                                </button>
                                            </div>
                                        </li>
                                    )}
                                </ul>
                            </div>
                        </div>
                    </div>
                )}
                {(isCurrentUserAttorney() || currentAttorney.previous_working_places.length > 0) && (
                    <div className="profile__block">
                        <div className="container">
                            <div className="profile__header"><h2>Previous Workplaces</h2>
                                {isCurrentUserAttorney() && (
                                    <button className="button no-style"
                                            onClick={handlerShowEditPreviousWorkplacesPopup}>
                                        <img src="/assets/icon--edit-Z9QfuMgm.svg" alt="Edit Regions"/>
                                        {(currentAttorney.previous_working_places.length === 0) ? 'Add Previous Workplace' : 'Edit Previous Workplaces'}
                                    </button>
                                )}
                            </div>
                            <div className="profile__content">
                                <div className="col-2 decor-list">
                                    {previousWorkPlacesToShow.map((place, index) => (
                                        <div className="vendor decor-list__el" key={index}>
                                            <div className="vendor__header">
                                                <h4 className="vendor__name">{place.name}</h4>
                                                <span
                                                    className="vendor__attorney">{`${place.start_date} - ${place.end_date}`}</span>
                                                <span
                                                    className="vendor__attorney">{calculateDuration(place.start_date, place.end_date)}</span>
                                                <span className="vendor__attorney">{place.position}</span>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                {!showAllPreviousWorkplaces && attorney.previous_working_places.length > 2 && (
                                    <div className="show-all">
                                        <div className="container" style={{textAlign: 'center'}}>
                                            <button onClick={() => setShowAllPreviousWorkplaces(true)}>Show More
                                                Previous Workplaces
                                                • {attorney.previous_working_places.length - 2} ({attorney.previous_working_places.length})
                                            </button>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                )}
                {(isCurrentUserAttorney() || attorney.education.length > 0) && (
                    <div className="profile__block">
                        <div className="container">
                            <div className="profile__header"><h2>Education</h2>
                                {isCurrentUserAttorney() && (
                                    <button className="button no-style" onClick={handlerShowEditEducationsPopup}>
                                        <img src="/assets/icon--edit-Z9QfuMgm.svg" alt="Edit Regions"/>
                                        {(currentAttorney.education.length === 0) ? 'Add Education' : 'Edit Educations'}
                                    </button>
                                )}
                            </div>
                            <div className="profile__content">
                                <div className="col-2 decor-list">
                                    {educationToShow.map((edu, index) => (
                                        <div className="vendor decor-list__el" key={index}>
                                            <div className="vendor__header">
                                                <h4 className="vendor__name">{edu.institution}</h4>
                                                <span
                                                    className="vendor__attorney">{`${formatDate(edu.start_date)} - ${formatDate(edu.end_date)}`}</span>
                                                <span className="vendor__attorney">{edu.degree}</span>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                {!showAllEducation && attorney.education.length > 2 && (
                                    <div className="show-all">
                                        <div className="container" style={{textAlign: 'center'}}>
                                            <button onClick={() => setShowAllEducation(true)}>Show More Education
                                                • {attorney.education.length - 2} ({attorney.education.length})
                                            </button>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                )}
                {(isCurrentUserAttorney() || currentAttorney.awards.length > 0) && (
                    <div className="profile__block">
                        <div className="container">
                            <div className="profile__header"><h2>Awards and Articles</h2>
                                {isCurrentUserAttorney() && (
                                    <button className="button no-style" onClick={handlerShowEditAwardsPopup}>
                                        <img src="/assets/icon--edit-Z9QfuMgm.svg" alt="Edit Regions"/>
                                        {(currentAttorney.awards.length === 0) ? 'Add Awards' : 'Edit Awards'}
                                    </button>
                                )}
                            </div>
                            <div className="profile__content">
                                <div className="col-2 decor-list">
                                    {currentAttorney.awards.map((award, index) => (
                                        <div key={index} className="vendor vendor-award decor-list__el">
                                            <div className="vendor__award">
                                                {award.image && <img src={award.image} alt={award.title}/>}
                                            </div>
                                            <div className="vendor__header">
                                                <h4 className="vendor__name">{award.title}</h4>
                                                <span className="vendor__attorney">{award.subtitle}</span>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
            {showEditLanguagesPopup && (
                <EditLanguagesPopup
                    show={showEditLanguagesPopup}
                    closeEditLanguagesPopup={handlerCloseEditLanguagesPopup}
                    initialData={currentAttorney}
                    onSave={handleSaveLanguages}
                />
            )}
            {showEditPreviousWorkplacesPopup && (
                <EditPreviousWorkplacesPopup
                    show={showEditPreviousWorkplacesPopup}
                    onClose={handlerCloseEditPreviousWorkplacesPopup}
                    initialData={currentAttorney}
                    onSave={handleSavePreviousWorkplaces}
                />
            )}
            {showEditAwardsPopup && (
                <EditAwardsPopup
                    show={showEditAwardsPopup}
                    onClose={handlerCloseEditAwardsPopup}
                    initialData={currentAttorney}
                    onSave={handleSaveAwards}
                />
            )}
            {showEditProfilePopup && (
                <EditProfilePopup
                    show={showEditProfilePopup}
                    closeEditProfilePopup={handlerCloseEditProfilePopup}
                    initialData={currentAttorney}
                    onSave={handleSaveProfile}
                    isAttorney={true}
                />
            )}
            {showEditEducationsPopup && (
                <EditEducationsPopup
                    show={showEditEducationsPopup}
                    onClose={handlerCloseEditEducationsPopup}
                    initialData={currentAttorney}
                    onSave={handleSaveEducations}
                />
            )}
            {showEditSpecializationsPopup && (
                <EditSpecializationsPopup
                    show={showEditSpecializationsPopup}
                    onClose={handlerCloseEditSpecializationsPopup}
                    initialData={currentAttorney}
                    onSave={handleSaveSpecializations}
                />
            )}
            {showSendMessageModal && (
                <SendMessageModal
                    show={showSendMessageModal}
                    recipient={currentAttorney}
                    recipientType="attorney"
                    onClose={handleCloseSendMessageModal}
                />
            )}

            <CropImageModal
                show={showCropModal}
                onClose={() => setShowCropModal(false)}
                image={imageToCrop}
                aspect={currentAspectRatio}
                onComplete={handleCroppedImageComplete}
                isCircle={isCircleCrop}
            />
        </div>
    );
};

export default PopupAttorney;
