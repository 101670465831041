// src/api/externalApi.js

import axios from 'axios';

const externalApi = axios.create({
    baseURL: 'https://lawyers.lawyer/api/',
    // Добавьте другие настройки, если необходимо
});

export default externalApi;
