import React, { useContext, useEffect } from "react";
import { SearchContext } from "../../context/SearchContext";

const HeaderIndexVendorsTitle = ({ scrollOffset }) => {
    const { searchResults, cityForTitle, companiesForTitle, totalResults, searchSended } = useContext(SearchContext);

    useEffect(() => {
    }, [searchResults, cityForTitle, companiesForTitle]);

    return (
        <>
            {!searchSended || searchResults.length === 0 ? (
                <div className="accordeon__el">
                    <div className={"accordeon__header"}>
                        <div className="header-content">
                            <div className="container">
                                <div className="justify">
                                    <h4>Popular Law Firms in Los Angeles, CA</h4>
                                    {/*<h4>Popular Law Firms in {cityForTitle}</h4>*/}
                                    {(companiesForTitle && companiesForTitle.length > 0) && (
                                        <ul className="header-content__list">
                                            <li><span className="counter">{companiesForTitle.length} companies</span></li>
                                        </ul>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <div className="accordeon__el">
                    <div className={"accordeon__header"}>
                        <div className="header-content">
                            <div className="container">
                                <div className="justify">
                                    <h4>Law Companies in your area</h4>
                                    <ul className="header-content__list">
                                        <li><span className="counter">{totalResults} companies</span></li>
                                        {/*<li className="show">*/}
                                        {/*    <button>Show all</button>*/}
                                        {/*</li>*/}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

export default HeaderIndexVendorsTitle;
