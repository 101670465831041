// src/components/ActivateTokenAccount.js

import React, {useState, useEffect} from 'react';
import {useParams, useNavigate} from 'react-router-dom';
import api from '../../api'; // Your Axios instance
import {useUser} from '../../context/UserContext'; // Your existing UserContext
import {useNotification} from '../../context/NotificationContext'; // Import NotificationContext
import getLogoUrl from '../../utils/getLogoUrl'; // Utility to get image URL
import './ActivateTokenAccount.css'; // Импортируйте CSS для стилей

const ActivateTokenAccount = () => {
    // Получаем токен из параметров URL
    const {token} = useParams();
    const navigate = useNavigate();
    const {setAuthTokens, setUser} = useUser();
    const {showNotification} = useNotification();
    const [loading, setLoading] = useState(false); // Состояние загрузки
    const [agree, setAgree] = useState(false); // Состояние соглашения
    const [activationImage, setActivationImage] = useState(''); // Изображение для страницы активации
    const [attorneyData, setAttorneyData] = useState(null); // Данные адвоката

    useEffect(() => {
        // Функция для получения изображения активации
        const fetchActivationImage = async () => {
            try {
                const response = await api.get('/random-signup-photo/');
                if (response.data.image) {
                    const imageUrl = getLogoUrl(response.data.image);
                    setActivationImage(imageUrl);
                } else {
                    setActivationImage('/assets/sign-in-image.png');
                }
            } catch (error) {
                console.error('Error fetching activation image:', error);
                setActivationImage('/assets/sign-in-image.png');
            }
        };

        // Функция для получения данных адвоката
        const fetchAttorneyData = async () => {
            if (!token || token.trim() === '') {
                showNotification(
                    'Invalid Token',
                    'The activation token is missing or invalid.',
                    null,
                    'error'
                );
                navigate('/sign-up/');
                return;
            }

            try {
                const response = await api.get(`/get-attorney-by-token/${token}/`);
                if (response.status >= 200 && response.status < 300) {
                    setAttorneyData(response.data.attorney);
                } else {
                    showNotification(
                        'Invalid Token',
                        'The activation token is invalid or has expired.',
                        null,
                        'error'
                    );
                    navigate('/sign-up/');
                }
            } catch (error) {
                console.error('Error fetching attorney data:', error);
                showNotification(
                    'Error',
                    'An error occurred while fetching attorney data.',
                    null,
                    'error'
                );
                navigate('/sign-up/');
            }
        };

        fetchActivationImage();
        fetchAttorneyData();
    }, []);

    // Handler for account activation
    const handleActivate = async () => {
        if (!agree) {
            showNotification(
                'Agreement Required',
                'You must agree to the Terms of Use and Privacy Policy.',
                null,
                'error'
            );
            return;
        }

        if (!token || token.trim() === '') {
            showNotification(
                'Invalid Token',
                'The activation token is missing or invalid.',
                null,
                'error'
            );
            navigate('/sign-up/');
            return;
        }

        setLoading(true);

        try {
            console.log('Token from URL:', token);

            const activationUrl = `/activate-token/${token}/`;
            console.log('Activation URL:', activationUrl);

            const response = await api.get(activationUrl);
            console.log('Activation response status:', response.status);
            console.log('Activation response data:', response.data);

            if (response.status >= 200 && response.status < 300) {
                // Notification for successful activation
                showNotification(
                    'Activation Successful!',
                    response.data.detail || 'Your account has been activated.',
                    null,
                    'success'
                );

                // Saving tokens
                const {access, refresh} = response.data.tokens || {};
                if (access && refresh) {
                    setAuthTokens({access, refresh});
                    localStorage.setItem('access_token', access);
                    localStorage.setItem('refresh_token', refresh);
                    console.log('Tokens saved successfully.');
                } else {
                    console.warn('Tokens not found in the response.');
                    showNotification(
                        'Activation Warning',
                        'Activation succeeded, but tokens are missing.',
                        null,
                        'error'
                    );
                }

                // Fetching user data
                try {
                    const userResponse = await api.get('/user/');
                    console.log('User response status:', userResponse.status);
                    console.log('User response data:', userResponse.data);
                    if (userResponse.status >= 200 && userResponse.status < 300) {
                        setUser(userResponse.data);
                        console.log("User successfully authenticated.");
                    } else {
                        console.warn('Failed to fetch user data:', userResponse);
                        showNotification(
                            'Authentication Warning',
                            'Account activated, but failed to fetch user data.',
                            null,
                            'warning'
                        );
                    }
                } catch (userError) {
                    console.error('Error fetching user data:', userError);
                    showNotification(
                        'User Data Error',
                        'Account activated, but an error occurred while fetching user data.',
                        null,
                        'error'
                    );
                }

                // Redirecting to profile
                setTimeout(() => {
                    navigate('/profile/');
                }, 3000);
            } else {
                // Notification for activation failure
                showNotification(
                    'Activation Failed',
                    response.data.detail || 'Failed to activate your account.',
                    null,
                    'error'
                );

                // Redirecting to sign-up page
                setTimeout(() => {
                    navigate('/sign-up/');
                }, 3000);
            }
        } catch (error) {
            console.error('Error activating account:', error);
            if (error.response) {
                console.error('Error response status:', error.response.status);
                console.error('Error response data:', error.response.data);
                showNotification(
                    'Activation Error',
                    error.response.data.detail || 'An error occurred during activation.',
                    null,
                    'error'
                );
            } else {
                showNotification(
                    'Server Error',
                    'An error occurred while activating your account. Please try again later.',
                    null,
                    'error'
                );
            }

            // Redirecting to sign-up page
            setTimeout(() => {
                navigate('/sign-up/');
            }, 3000);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="main-form-container">
            <div
                className="main-form__img"
                style={{
                    backgroundColor: '#f5f5f5',
                    backgroundImage: `url(${activationImage})`, // Используем полученное случайное изображение
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: '50% 50%',
                    backgroundSize: 'cover',
                }}
            ></div>

            <div className="main-form__right">
                <form className="main-form__content" onSubmit={(e) => e.preventDefault()}>
                    <div className="scroll-wrap">
                        <h2>Account Activation</h2>

                        {attorneyData && (
                            <div className="attorney-card">
                                <img
                                    src={attorneyData.photo}
                                    alt="Attorney Photo"
                                    onError={(e) => {
                                        e.target.src = '/assets/logo-img.svg';
                                    }} // Замена на дефолтное изображение при ошибке
                                    loading="lazy" // Ленивая загрузка изображения
                                />
                                <div className="attorney-info">
                                    <h3>{attorneyData.first_name} {attorneyData.last_name}</h3>
                                    <p>{attorneyData.address}</p>
                                    <p>Bar Number: {attorneyData.bar_code}</p>
                                </div>
                            </div>
                        )}

                        <div className="checkbox-container">
                            <label className="label-checkbox">
                                <input
                                    type="checkbox"
                                    name="agreeToTerms"
                                    checked={agree}
                                    onChange={(e) => setAgree(e.target.checked)}
                                />
                                <div className="psevdocheckbox"></div>
                            </label>
                            <span className="text-left">
    {attorneyData ? (
        `I, ${attorneyData.first_name} ${attorneyData.last_name}, agree to AI Revolution LLC `
    ) : (
        'I agree to our '
    )}
                                <a href="/terms-of-use" className="checkbox__link" target="_blank"
                                   rel="noopener noreferrer">
        Terms of Use
    </a>
                                &nbsp;and&nbsp;
                                <a href="/privacy-policy" className="checkbox__link" target="_blank"
                                   rel="noopener noreferrer">
        Privacy Policy
    </a>.
</span>

                        </div>

                        <button
                            className="button button-black"
                            onClick={handleActivate}
                            disabled={loading}
                        >
                            {loading ? 'Activating...' : 'Activate Account'}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default ActivateTokenAccount;
