// src/components/User/ForgotPassword.js

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import InputWithLabel from "../Form/Input/InputWithLabel";
import api from '../../api';
import { useNotification } from '../../context/NotificationContext'; // Импортируем хук уведомлений

const ForgotPassword = () => {
    // Состояние формы и ошибок
    const [formData, setFormData] = useState({
        email: ''
    });
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);

    const { showNotification } = useNotification(); // Получаем функцию showNotification из контекста уведомлений
    const navigate = useNavigate();

    // Обработчик изменения полей формы
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };


    // Функция валидации формы
    const validateForm = () => {
        const newErrors = {};

        if (!formData.email) {
            newErrors.email = 'Email is required.';
        } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
            newErrors.email = 'Email is invalid.';
        }

        return newErrors;
    };

    // Обработчик отправки формы
    const handleSubmit = async (e) => {
        e.preventDefault();
        setErrors({});
        const validationErrors = validateForm();

        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);

            // Собираем все сообщения об ошибках
            const errorMessages = Object.values(validationErrors).join(' ');

            // Отображаем уведомление об ошибке
            showNotification(
                'Please correct the errors in the form.',
                errorMessages, // Передаем собранные сообщения об ошибках
                null, // Можно добавить дополнительное сообщение, если необходимо
                'error'
            );

            return;
        }

        setLoading(true);

        try {
            const emailLower = formData.email.toLowerCase();

            const response = await api.post('/password-reset/', {
                email: emailLower,
            });

            if (response.status === 200) {
                // Отображаем уведомление об успешной отправке
                showNotification(
                    'Password reset link has been sent to your email.',
                    'Please check your inbox to proceed.',
                    null,
                    'success'
                );

                // Перенаправление на страницу входа через 5 секунд
                setTimeout(() => {
                    navigate('/sign-in');
                }, 5000);
            } else {
                // Обработка неожиданных статусов ответа
                showNotification(
                    'Password reset failed. Please try again.',
                    null,
                    null,
                    'error'
                );
            }
        } catch (error) {
            if (error.response && error.response.data) {
                if (error.response.data.error === 'User with this email does not exist.') {
                    showNotification(
                        'User with this email does not exist.',
                        'Please verify the email address and try again.',
                        null,
                        'error'
                    );
                    setErrors({ email: 'User with this email does not exist.' });
                } else if (error.response.data.error === 'Email is not provided.') {
                    showNotification(
                        'Please enter your email.',
                        'The email field cannot be empty.',
                        null,
                        'error'
                    );
                    setErrors({ email: 'Email is not provided.' });
                } else {
                    showNotification(
                        'Something went wrong. Please try again later.',
                        null,
                        null,
                        'error'
                    );
                }
            } else {
                // Отображаем уведомление о сетевой ошибке
                showNotification(
                    'Network error.',
                    'Please check your connection.',
                    null,
                    'error'
                );
            }
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            {/* Рендеринг контейнера формы */}
            <div className="main-form-container">
                <div
                    className="main-form__img"
                    style={{
                        backgroundColor: '#fff',
                        backgroundImage: "url('/assets/sign-in-image.png')",
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: '50% 50%',
                        backgroundSize: 'cover',
                    }}
                ></div>

                <div className="main-form__right">
                    <form className="main-form__content" onSubmit={handleSubmit}>
                        <div className="scroll-wrap">
                            <h2>Forgot Password</h2>

                            <InputWithLabel
                                label='Enter your email'
                                name='email'
                                value={formData.email}
                                onChange={handleChange}
                                type='email'
                                hasError={!!errors.email} // Используем hasError для отображения ошибки
                            />

                            <button className="button button-black" type="submit" disabled={loading}>
                                {loading ? 'Sending...' : 'Send to email'}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
};

export default ForgotPassword;
