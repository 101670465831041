import React, {useState, useEffect, useCallback} from 'react';
import InputWithLabel from "../Form/Input/InputWithLabel";
import api from '../../api';
import Cookies from 'js-cookie';
import ReactDOM from 'react-dom'; // Импортируем ReactDOM для порталов

// Компонент для быстрого создания юридической компании
const CreateLawFirmQuick = ({show, onClose, userId, existingProfile}) => {
    const currentYear = new Date().getFullYear();

    // Инициализация состояния формы
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone_number: '',
        website: '',
        foundation_year: '',
        address: {
            country_id: '1', // По умолчанию США
            state_id: '',
            city_id: '',
            zip_code_id: '',
            street_address: '',
        },
        attorneys: [],
        status: 'inactive', // Начальный статус
    });

    // Состояния для списка штатов, городов и почтовых индексов
    const [states, setStates] = useState([]);
    const [cities, setCities] = useState([]);
    const [zipCodes, setZipCodes] = useState([]);

    // Состояния для обработки ошибок и загрузки
    const [errors, setErrors] = useState({});
    const [isLoading, setIsLoading] = useState(false);

    // Состояние для списка адвокатов
    const [myAttorneys, setMyAttorneys] = useState([]);

    // Функция для получения конфигурации запроса с CSRF и токеном
    const getRequestConfig = useCallback(() => {
        const token = localStorage.getItem('authToken');
        const csrfToken = Cookies.get('csrftoken');

        return {
            headers: {
                'Authorization': `Bearer ${token}`,
                'X-CSRFToken': csrfToken,
            },
            withCredentials: true,
        };
    }, []);

    // Функции для загрузки данных (штаты, города, почтовые индексы)
    const fetchStates = useCallback(async (country_id) => {
        try {
            const response = await api.get(`/states-filtered/?country_id=${country_id}`, getRequestConfig());
            setStates(response.data);
            console.log('Загружены штаты:', response.data);
        } catch (error) {
            console.error('Ошибка при получении штатов:', error);
        }
    }, [getRequestConfig]);

    const fetchCities = useCallback(async (state_id) => {
        try {
            const response = await api.get(`/cities-filtered/?state_id=${state_id}`, getRequestConfig());
            setCities(response.data);
            console.log('Загружены города:', response.data);
        } catch (error) {
            console.error('Ошибка при получении городов:', error);
        }
    }, [getRequestConfig]);

    const fetchZipCodes = useCallback(async (city_id) => {
        try {
            const response = await api.get(`/zip-codes/?city_id=${city_id}`, getRequestConfig());
            setZipCodes(response.data);
            console.log('Загружены почтовые индексы:', response.data);
        } catch (error) {
            console.error('Ошибка при получении почтовых индексов:', error);
        }
    }, [getRequestConfig]);

    // Функция для загрузки только первого адвоката и предзаполнения формы
    const fetchFirstAttorney = useCallback(async () => {
        setIsLoading(true);
        try {
            const response = await api.get(`/user-attorneys/?page=1&page_size=1`, getRequestConfig());
            const firstAttorney = response.data.results[0];

            console.log('First Attorney:', firstAttorney);

            if (firstAttorney) {
                setMyAttorneys([firstAttorney]);

                // Проверяем, есть ли адреса у адвоката
                const primaryAddress = firstAttorney.addresses && firstAttorney.addresses.length > 0
                    ? firstAttorney.addresses[0]
                    : null;

                console.log('Primary Address:', primaryAddress);

                setFormData(prevData => ({
                    ...prevData,
                    attorneys: [firstAttorney.attorney_id], // Устанавливаем только одного адвоката
                    name: `${firstAttorney.first_name} ${firstAttorney.last_name}`,
                    email: firstAttorney.email || prevData.email,
                    phone_number: firstAttorney.phone_number || prevData.phone_number,
                    foundation_year: firstAttorney.license_year ? String(firstAttorney.license_year) : prevData.foundation_year, // Используем license_year
                    address: primaryAddress
                        ? {
                            country_id: primaryAddress.country.country_id
                                ? String(primaryAddress.country.country_id)
                                : prevData.address.country_id,
                            state_id: primaryAddress.state.state_id
                                ? String(primaryAddress.state.state_id)
                                : '',
                            city_id: primaryAddress.city.city_id
                                ? String(primaryAddress.city.city_id)
                                : '',
                            zip_code_id: primaryAddress.zip_code.id
                                ? String(primaryAddress.zip_code.id)
                                : '',
                            street_address: primaryAddress.street_address || '',
                        }
                        : {
                            country_id: '',
                            state_id: '',
                            city_id: '',
                            zip_code_id: '',
                            street_address: '',
                        },
                }));

                // Загрузка зависимых данных для адреса
                if (primaryAddress && primaryAddress.country.country_id) {
                    await fetchStates(primaryAddress.country.country_id);
                }

                if (primaryAddress && primaryAddress.state.state_id) {
                    await fetchCities(primaryAddress.state.state_id);
                }

                if (primaryAddress && primaryAddress.city.city_id) {
                    await fetchZipCodes(primaryAddress.city.city_id);
                }
            }
        } catch (error) {
            console.error('Ошибка при получении первого адвоката:', error.response ? error.response.data : error.message);
        } finally {
            setIsLoading(false);
        }
    }, [fetchStates, fetchCities, fetchZipCodes, getRequestConfig]);

    // Получение первого адвоката при монтировании компонента
    useEffect(() => {
        if (show) {
            fetchFirstAttorney();
        }
    }, [fetchFirstAttorney, show]);

    // Загрузка штатов при изменении страны
    useEffect(() => {
        if (formData.address.country_id) {
            fetchStates(formData.address.country_id);
        }
    }, [fetchStates, formData.address.country_id]);

    // Загрузка городов при изменении штата
    useEffect(() => {
        if (formData.address.state_id) {
            fetchCities(formData.address.state_id);
        } else {
            setCities([]);
            setFormData(prevData => ({
                ...prevData,
                address: {
                    ...prevData.address,
                    city_id: '',
                    zip_code_id: '',
                    street_address: '',
                },
            }));
        }
    }, [formData.address.state_id, fetchCities]);

    // Загрузка почтовых индексов при изменении города
    useEffect(() => {
        if (formData.address.city_id) {
            fetchZipCodes(formData.address.city_id);
        } else {
            setZipCodes([]);
            setFormData(prevData => ({
                ...prevData,
                address: {
                    ...prevData.address,
                    zip_code_id: '',
                    street_address: '',
                },
            }));
        }
    }, [formData.address.city_id, fetchZipCodes]);

    // Обработка изменений в основных полях формы
    const handleChange = (e) => {
        const {name, value} = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value
        }));
    };

    // Обработка изменений в полях адреса
    const handleAddressChange = (name, value) => {
        setFormData((prevData) => ({
            ...prevData,
            address: {
                ...prevData.address,
                [name]: value
            }
        }));
    };

    // Обработка выбора адвоката (только первого)
    const handleAttorneySelection = (attorneyId) => {
        setFormData((prevData) => ({
            ...prevData,
            attorneys: [attorneyId], // Всегда устанавливаем только одного адвоката
        }));
    };

    // Валидация данных формы
    const validateForm = useCallback(() => {
        const newErrors = {};

        // Валидация имени компании
        if (!formData.name.trim()) {
            newErrors.name = 'Company name is required.';
        }

        // Валидация email
        if (!formData.email.trim()) {
            newErrors.email = 'Email is required.';
        } else {
            // Простая проверка формата email
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (!emailRegex.test(formData.email)) {
                newErrors.email = 'Invalid email format.';
            }
        }

        // Валидация номера телефона
        if (!formData.phone_number.trim()) {
            newErrors.phone_number = 'Phone number is required.';
        }

        // Валидация года основания
        if (!formData.foundation_year.trim()) {
            newErrors.foundation_year = 'Foundation year is required.';
        } else {
            const year = parseInt(formData.foundation_year, 10);
            if (isNaN(year) || year < 1800 || year > currentYear) {
                newErrors.foundation_year = `Foundation year must be between 1800 and ${currentYear}.`;
            }
        }

        // Валидация веб-сайта
        const domainContainsDotRegex = /\..+$/;
        if (formData.website && !domainContainsDotRegex.test(formData.website)) {
            newErrors.website = 'Website must contain a dot followed by a domain, like ".com".';
        }

        // Валидация адреса
        const addressErrors = {};

        if (!formData.address.country_id) {
            addressErrors.country_id = 'Country is required.';
        }

        if (!formData.address.state_id) {
            addressErrors.state_id = 'State is required.';
        }

        if (!formData.address.city_id) {
            addressErrors.city_id = 'City is required.';
        }

        if (!formData.address.zip_code_id) {
            addressErrors.zip_code_id = 'Zip Code is required.';
        }

        if (!formData.address.street_address.trim()) {
            addressErrors.street_address = 'Street and House Number is required.';
        }

        if (Object.keys(addressErrors).length > 0) {
            newErrors.address = addressErrors;
        }

        // Валидация адвокатов: должен быть выбран хотя бы один
        if (formData.attorneys.length === 0) {
            newErrors.attorneys = 'At least one attorney must be selected.';
        }

        setErrors(newErrors);

        return Object.keys(newErrors).length === 0;
    }, [formData, currentYear]);

    // Функция для создания юридической компании
    const handleCreateLawFirm = async () => {
        // Устанавливаем статус как 'active'
        const dataToSend = {...formData, status: 'active'};

        if (!validateForm()) {
            return;
        }

        setIsLoading(true);

        const formDataToSend = new FormData();
        formDataToSend.append('name', dataToSend.name);
        formDataToSend.append('email', dataToSend.email);
        formDataToSend.append('phone_number', dataToSend.phone_number);
        formDataToSend.append('website', dataToSend.website);
        formDataToSend.append('foundation_year', dataToSend.foundation_year);
        formDataToSend.append('status', dataToSend.status);
        formDataToSend.append('user_id', userId);
        formDataToSend.append('address', JSON.stringify(dataToSend.address));

        dataToSend.attorneys.forEach(attorney_id => {
            formDataToSend.append('attorneys', attorney_id);
        });

        formDataToSend.append('source', 'CreateLawFirmQuick');

        // Генерация описания (около 150 символов, на английском языке)
        const attorney = myAttorneys[0];
        const firstName = attorney ? attorney.first_name : 'Our';
        const lastName = attorney ? attorney.last_name : 'Attorney';
        const specialization = (attorney && attorney.specializations && attorney.specializations.length > 0)
            ? attorney.specializations[0].name
            : 'various legal areas';
        const year = formData.foundation_year || currentYear;

        const description = `This Law Firm was created by attorney ${firstName} ${lastName} in ${year}. Specializing in ${specialization}, we are committed to providing exceptional legal services.`.slice(0, 300);

        formDataToSend.append('description', description);

        try {
            const config = getRequestConfig();
            let response;
            if (existingProfile && existingProfile.id) {
                // Обновляем существующий профиль
                response = await api.patch(`/legal-companies-draft/${existingProfile.id}/update/`, formDataToSend, config);
            } else {
                // Создаем новый профиль
                response = await api.post('/legal-companies/create/', formDataToSend, config);
            }

            if (response.status === 200 || response.status === 201) {
                // Успешно создано/обновлено, закрывае м окно
                onClose();
            } else {
                console.error('Ошибка при создании юридической компании:', response);
                setErrors({general: 'Error creating law firm. Please try again.'});
            }
        } catch (error) {
            console.error('Ошибка при создании юридической компании:', error.response ? error.response.data : error.message);
            setErrors({general: 'An unexpected error occurred. Please try again.'});
        } finally {
            setIsLoading(false);
        }
    };

    // Предотвращение отправки формы при нажатии клавиши Enter
    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
        }
    };

    // Рендеринг компонента
    if (!show) return null;

    return ReactDOM.createPortal(
        <>
            {show && (
                <div className="modal visible" id="create-law-firm-quick">
                    <div className="modal__overlay" onClick={onClose}></div>
                    <div className="modal__content">
                        <button onClick={onClose} className="button no-style close js--close-modal">
                            <img src="/assets/icon--close-CN6QlgAA.svg" alt="Close"/>
                        </button>
                        <div className="modal__form">
                            <form className="content" onSubmit={(e) => {
                                e.preventDefault();
                            }} onKeyDown={handleKeyDown}>
                                <h2>{existingProfile ? 'Edit Law Firm Profile' : 'Create Law Firm Profile'}</h2>

                                {/* Общие ошибки */}
                                {errors.general && (
                                    <div className="error-message">
                                        {errors.general}
                                    </div>
                                )}

                                {/* Поле Название компании */}
                                <InputWithLabel
                                    label="Company Name"
                                    name="name"
                                    value={formData.name}
                                    onChange={handleChange}
                                    error={errors.name}
                                    readOnly={!!myAttorneys[0]} // Сделать поле только для чтения, если оно предзаполнено
                                />

                                {/* Поле Email */}
                                <InputWithLabel
                                    label="Email"
                                    name="email"
                                    type="email"
                                    value={formData.email}
                                    onChange={handleChange}
                                    error={errors.email}
                                />

                                {/* Поле Номер телефона */}
                                <InputWithLabel
                                    label="Phone Number"
                                    name="phone_number"
                                    type="tel"
                                    value={formData.phone_number}
                                    onChange={handleChange}
                                    error={errors.phone_number}
                                />

                                {/* Поле Веб-сайт */}
                                <InputWithLabel
                                    label="Website"
                                    name="website"
                                    value={formData.website}
                                    onChange={handleChange}
                                    error={errors.website}
                                />

                                {/* Поле Год основания */}
                                <InputWithLabel
                                    label="Foundation Year"
                                    name="foundation_year"
                                    type="number"
                                    value={formData.foundation_year}
                                    onChange={handleChange}
                                    min="1800"
                                    max={currentYear}
                                    error={errors.foundation_year}
                                    readOnly={!!myAttorneys[0]} // Сделать поле только для чтения, если оно предзаполнено
                                />
                                {myAttorneys[0] && (
                                    <small className="info-text">
                                        Foundation Year is derived from the attorney's license year.
                                    </small>
                                )}

                                <span>Address</span>

                                {/* Поле Страна */}
                                <select
                                    className={`input ${errors.address?.country_id ? 'input-error' : ''}`}
                                    name="country_id"
                                    value={formData.address.country_id}
                                    onChange={(e) => handleAddressChange('country_id', e.target.value)}
                                >
                                    <option value="" disabled>Choose country</option>
                                    <option value="1">United States</option>
                                </select>
                                {errors.address?.country_id && (
                                    <span className="error-text">{errors.address.country_id}</span>
                                )}

                                {/* Поле Штат */}
                                <select
                                    className={`input ${errors.address?.state_id ? 'input-error' : ''}`}
                                    name="state_id"
                                    value={formData.address.state_id}
                                    onChange={(e) => handleAddressChange('state_id', e.target.value)}
                                >
                                    <option value="" disabled>Select State</option>
                                    {states.map((state) => (
                                        <option key={state.state_id} value={state.state_id}>
                                            {state.name}
                                        </option>
                                    ))}
                                </select>
                                {errors.address?.state_id && (
                                    <span className="error-text">{errors.address.state_id}</span>
                                )}

                                {/* Поле Город */}
                                {formData.address.state_id && (
                                    <select
                                        className={`input ${errors.address?.city_id ? 'input-error' : ''}`}
                                        name="city_id"
                                        value={formData.address.city_id}
                                        onChange={(e) => handleAddressChange('city_id', e.target.value)}
                                    >
                                        <option value="" disabled>Select City</option>
                                        {cities.map((city) => (
                                            <option key={city.city_id} value={city.city_id}>
                                                {city.name}
                                            </option>
                                        ))}
                                    </select>
                                )}
                                {formData.address.state_id && errors.address?.city_id && (
                                    <span className="error-text">{errors.address.city_id}</span>
                                )}

                                {/* Поле Почтовый индекс */}
                                {formData.address.city_id && (
                                    <select
                                        className={`input ${errors.address?.zip_code_id ? 'input-error' : ''}`}
                                        name="zip_code_id"
                                        value={formData.address.zip_code_id}
                                        onChange={(e) => handleAddressChange('zip_code_id', e.target.value)}
                                    >
                                        <option value="" disabled>Select Zip Code</option>
                                        {zipCodes.map((zip) => (
                                            <option key={zip.id} value={zip.id}>
                                                {zip.zip_code}
                                            </option>
                                        ))}
                                    </select>
                                )}
                                {formData.address.city_id && errors.address?.zip_code_id && (
                                    <span className="error-text">{errors.address.zip_code_id}</span>
                                )}

                                {/* Поле Улица и номер дома */}
                                {formData.address.zip_code_id && (
                                    <InputWithLabel
                                        label="Street and House Number"
                                        name="street_address"
                                        value={formData.address.street_address}
                                        onChange={(e) => handleAddressChange('street_address', e.target.value)}
                                        error={errors.address?.street_address}
                                    />
                                )}

                                {/* Список адвокатов */}
                                <div className="attorneys-list">
                                    <h3>Attorneys:</h3>
                                    {isLoading && (
                                        <p>Loading attorneys...</p>
                                    )}
                                    {!isLoading && myAttorneys.length > 0 && (
                                        <ul className="attorneys__list">
                                            {myAttorneys.map((attorney) => (
                                                attorney.attorney_id ? (
                                                    <li className="attorney" key={attorney.attorney_id}>
                                                        <div className="attorney__avatar">
                                                            <div className="avatar">
                                                                <picture>
                                                                    <img
                                                                        src={attorney.photo}
                                                                        alt={`${attorney.first_name} ${attorney.last_name}`}
                                                                        className="attorney-photo"
                                                                        loading="lazy"
                                                                    />
                                                                </picture>
                                                            </div>
                                                        </div>
                                                        <div className="attorney__content">
                                                            <div>
                                                                <h3 className="attorney__name">{`${attorney.first_name} ${attorney.last_name}`}</h3>
                                                            </div>
                                                            <ul className="params__list">
                                                                {attorney.specializations && attorney.specializations.slice(0, 3).map((spec) => (
                                                                    <li key={spec.specialization_id}>{spec.name}</li>
                                                                ))}
                                                                {attorney.specializations && attorney.specializations.length > 3 && (
                                                                    <li>+{attorney.specializations.length - 3} more</li>
                                                                )}
                                                            </ul>
                                                        </div>
                                                        <label className="label-checkbox">
                                                            <input
                                                                type="checkbox"
                                                                checked={formData.attorneys.includes(attorney.attorney_id)}
                                                                onChange={() => handleAttorneySelection(attorney.attorney_id)}
                                                            />
                                                            <div className="psevdocheckbox"></div>
                                                        </label>
                                                    </li>
                                                ) : null
                                            ))}
                                        </ul>
                                    )}
                                    {errors.attorneys && (
                                        <span className="error-message">{errors.attorneys}</span>
                                    )}
                                </div>

                                <button
                                    className="button button-black create-profile-button"
                                    type="button"
                                    onClick={handleCreateLawFirm}
                                    disabled={isLoading}
                                >
                                    {isLoading ? 'Creating...' : 'Create Law Firm'}
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            )}
        </>,
        document.body // Портал в body
    );

};

export default CreateLawFirmQuick;
