import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import InputWithLabel from '../Form/Input/InputWithLabel';
import api from '../../api';
import ReactDOM from 'react-dom';

// Импортируем модалку кропа (предполагается, что она уже реализована вами)
// и функцию валидации/масштабирования изображения.
import CropImageModal from '../popups/CropImageModal';
import { validateAndScaleImage } from '../../utils/validateAndScaleImage';

// Предполагаем наличие объектa с требованиями к изображениям наград:
// Например, как для логотипа Law Firm (456x256, aspect_ratio и tolerance)
const IMAGE_REQUIREMENTS = {
    award_image: {
        min_width: 456,
        min_height: 256,
        aspect_ratio: 456/256,
        ratio_tolerance: 0.05,
    },
};

const EditAwardsPopup = ({ show, onClose, onSave, initialData }) => {
    const [formData, setFormData] = useState({
        awards: initialData?.awards || []
    });

    const [errors, setErrors] = useState({});

    // Состояния для кроппера
    const [showCropModal, setShowCropModal] = useState(false);
    const [imageToCrop, setImageToCrop] = useState(null);
    const [currentAwardIndex, setCurrentAwardIndex] = useState(null);

    useEffect(() => {
        if (show) {
            setErrors({});
        }
    }, [show]);

    const getAwardImageUrl = (image) => {
        if (image instanceof File) {
            return URL.createObjectURL(image);
        }
        return image || '/assets/logo--img-Bjfxg2Ti2.svg';
    };

    const handleAwardChange = (index, e) => {
        const { name, value } = e.target;
        const updatedAwards = formData.awards.map((award, i) => {
            if (i === index) {
                return { ...award, [name]: value };
            }
            return award;
        });

        setFormData((prevData) => ({
            ...prevData,
            awards: updatedAwards
        }));
    };

    // Когда пользователь выбирает новый файл для награды
    // Мы открываем модалку кроппера, где он сможет подогнать изображение.
    const handleImageChange = (index, e) => {
        const file = e.target.files[0];
        if (file) {
            // Открываем модальное окно кроппера
            setImageToCrop(URL.createObjectURL(file));
            setCurrentAwardIndex(index);
            setShowCropModal(true);
        }
    };

    // Функция будет вызвана после завершения кропа и масштабирования
    // Возвращает готовый File, соответствующий требованиям
    const handleCroppedImageComplete = async (croppedImageBlob) => {
        try {
            const file = new File([croppedImageBlob], 'cropped_award_image.jpg', { type: 'image/jpeg' });
            const requirements = IMAGE_REQUIREMENTS.award_image;

            await validateAndScaleImage(
                file,
                requirements.min_width,
                requirements.min_height,
                requirements.aspect_ratio,
                requirements.ratio_tolerance
            );

            // Обновляем награду с новым файлом изображения
            const updatedAwards = formData.awards.map((award, i) => {
                if (i === currentAwardIndex) {
                    return { ...award, image: file };
                }
                return award;
            });

            setFormData((prevData) => ({
                ...prevData,
                awards: updatedAwards
            }));
        } catch (error) {
            console.error('Image Validation Error', error);
            // Вы можете добавить уведомление или алерт пользователю
        }

        // Закрываем модалку кропа
        setShowCropModal(false);
        setImageToCrop(null);
        setCurrentAwardIndex(null);
    };

    const handleAddAward = () => {
        setFormData((prevData) => ({
            ...prevData,
            awards: [...prevData.awards, { id: null, title: '', subtitle: '', image: '' }]
        }));
    };

    const handleDeleteAward = (index) => {
        const updatedAwards = formData.awards.filter((_, i) => i !== index);
        setFormData((prevData) => ({
            ...prevData,
            awards: updatedAwards
        }));
        setErrors((prevErrors) => {
            const updatedErrors = { ...prevErrors };
            delete updatedErrors[index];
            return updatedErrors;
        });
    };

    const validateForm = () => {
        const newErrors = {};

        formData.awards.forEach((award, index) => {
            const awardErrors = {};

            if (!award.title || award.title.trim() === '') {
                awardErrors.title = 'Award title is required.';
            }

            if (!award.subtitle || award.subtitle.trim() === '') {
                awardErrors.subtitle = 'Award subtitle is required.';
            }

            if (Object.keys(awardErrors).length > 0) {
                newErrors[index] = awardErrors;
            }
        });

        setErrors(newErrors);

        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!validateForm()) {
            return;
        }

        const formDataToSend = new FormData();
        let hasValidAward = false;

        for (let index = 0; index < formData.awards.length; index++) {
            const award = formData.awards[index];

            if (award.title.trim() === '' && award.subtitle.trim() === '') continue;

            if (award.id) {
                formDataToSend.append(`awards[${index}][id]`, award.id);
            }

            formDataToSend.append(`awards[${index}][title]`, award.title);
            formDataToSend.append(`awards[${index}][subtitle]`, award.subtitle);

            if (award.image instanceof File) {
                formDataToSend.append(`awards[${index}][image]`, award.image);
            } else if (!award.id && !award.image) {
                // Загрузка изображения по умолчанию
                const response = await fetch('/assets/logo--img-Bjfxg2Ti2.svg');
                const blob = await response.blob();
                const defaultImageFile = new File([blob], 'logo--img-Bjfxg2Ti2.svg', { type: 'image/svg+xml' });
                formDataToSend.append(`awards[${index}][image]`, defaultImageFile);
            }

            hasValidAward = true;
        }

        if (hasValidAward) {
            await onSave(formDataToSend);
        } else {
            console.error("No valid awards to save");
        }
    };

    if (!show) return null;

    return ReactDOM.createPortal(
        <div className="modal visible" id="edit-awards">
            <div className="modal__overlay" onClick={onClose}></div>
            <div className="modal__content">
                <button onClick={onClose} className="button no-style close js--close-modal" aria-label="Close Popup">
                    <img src="/assets/icon--close-CN6QlgAA.svg" alt="Close" />
                </button>
                <div className="modal__form">
                    <form className="content" onSubmit={handleSubmit}>
                        <h2>Edit Awards</h2>
                        {formData.awards.map((award, index) => (
                            <React.Fragment key={index}>
                                <InputWithLabel
                                    name="title"
                                    value={award.title}
                                    onChange={(e) => handleAwardChange(index, e)}
                                    label="Award Or Article"
                                    error={errors[index]?.title}
                                />
                                <InputWithLabel
                                    name="subtitle"
                                    value={award.subtitle}
                                    onChange={(e) => handleAwardChange(index, e)}
                                    label="Additional Information"
                                    error={errors[index]?.subtitle}
                                />

                                <div className="profile__logo">
                                    <picture>
                                        <img src={getAwardImageUrl(award.image)} alt={`${award.title} image`} />
                                    </picture>
                                    <label className="userpick__edit">
                                        <img src="/assets/icon--edit-bg-BVfPEI-f.svg" alt="Edit" />
                                        <input type="file" onChange={(e) => handleImageChange(index, e)} />
                                    </label>
                                </div>

                                <button
                                    className="button no-style"
                                    type="button"
                                    onClick={() => handleDeleteAward(index)}
                                >
                                    Delete
                                </button>
                            </React.Fragment>
                        ))}

                        <button
                            className="button button-border"
                            type="button"
                            onClick={handleAddAward}
                        >
                            Add New Award
                        </button>

                        <button className="button button-black" type="submit">Save Awards</button>
                    </form>
                </div>
            </div>

            {/* Модальное окно для кропа (открывается при выборе файла) */}
            {showCropModal && (
                <CropImageModal
                    show={showCropModal}
                    onClose={() => { setShowCropModal(false); setImageToCrop(null); }}
                    image={imageToCrop}
                    aspect={IMAGE_REQUIREMENTS.award_image.aspect_ratio}
                    // Для наград можно использовать прямоугольное соотношение сторон,
                    // например aspect = 456/256.
                    // cropShape="rect" по умолчанию. Если надо, можно явно указать:
                    cropShape="rect"
                    onComplete={handleCroppedImageComplete}
                />
            )}

        </div>,
        document.body
    );
};

EditAwardsPopup.propTypes = {
    show: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    initialData: PropTypes.shape({
        awards: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
            title: PropTypes.string,
            subtitle: PropTypes.string,
            image: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(File)]),
        })),
    }),
};

export default EditAwardsPopup;
