// components/popups/CropImageModal.js
import React, {useState, useCallback} from 'react';
import Cropper from 'react-easy-crop';
import getCroppedImg from '../../utils/getCroppedImg';
import './CropImageModal.css';

const CropImageModal = ({show, onClose, image, aspect, onComplete, isCircle = false}) => {
    const [crop, setCrop] = useState({x: 0, y: 0});
    const [zoom, setZoom] = useState(1);
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
    const [isSaving, setIsSaving] = useState(false); // Добавляем состояние загрузки

    const onCropComplete = useCallback((_, croppedArea) => {
        setCroppedAreaPixels(croppedArea);
    }, []);

    const handleSave = async () => {
        setIsSaving(true); // Показываем состояние загрузки
        try {
            const croppedImage = await getCroppedImg(image, croppedAreaPixels);
            await onComplete(croppedImage);
            // После успешной обработки и onComplete можно закрыть модалку
            onClose();
        } catch (e) {
            console.error(e);
        } finally {
            // В любом случае, после завершения операции скрываем лоудер
            setIsSaving(false);
        }
    };

    if (!show) return null;

    const increaseZoom = () => setZoom(prev => Math.min(prev + 0.1, 3));
    const decreaseZoom = () => setZoom(prev => Math.max(prev - 0.1, 1));

    return (
        <div className="crop-modal-overlay">
            <div className="crop-modal">
                <div className="crop-container">
                    <Cropper
                        image={image}
                        crop={crop}
                        zoom={zoom}
                        aspect={aspect}
                        onCropChange={setCrop}
                        onZoomChange={setZoom}
                        cropShape={isCircle ? "round" : "rect"}
                        onCropComplete={onCropComplete}
                    />
                    {isSaving && (
                        <div className="loader-overlay">
                            <div className="loader">Saving...</div>
                        </div>
                    )}
                </div>
                <div className="crop-controls">
                    <div className="zoom-controls">
                        <button className="zoom-button" onClick={decreaseZoom} title="Zoom Out">
                            <svg width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2"
                                 strokeLinecap="round" strokeLinejoin="round">
                                <line x1="5" y1="12" x2="19" y2="12"></line>
                            </svg>
                        </button>
                        <span className="zoom-percentage">{(zoom * 100).toFixed(0)}%</span>
                        <button className="zoom-button" onClick={increaseZoom} title="Zoom In">
                            <svg width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2"
                                 strokeLinecap="round" strokeLinejoin="round">
                                <line x1="12" y1="5" x2="12" y2="19"></line>
                                <line x1="5" y1="12" x2="19" y2="12"></line>
                            </svg>
                        </button>
                    </div>
                    <div className="action-buttons">
                        <button className="button" onClick={onClose} disabled={isSaving}>Cancel</button>
                        <button className="button button-black" onClick={handleSave} disabled={isSaving}>
                            {isSaving ? 'Saving...' : 'Save'}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CropImageModal;
