import React, {useCallback} from 'react';

// Вы используете глобальный CSS, поэтому дополнительный импорт не требуется

export default function InputWithLabel({
                                           name,
                                           value,
                                           onChange,
                                           type = 'text',
                                           error, // Пропс для сообщения об ошибке
                                           label,
                                           min,
                                           max,
                                           step,
                                           showInfo,
                                           showDelete,
                                           onDelete,
                                           disabled, // Добавляем пропс disabled

                                       }) {
    const handlerChange = useCallback((e) => {
        onChange(e);
    }, [onChange]);

    // Определяем, есть ли ошибка
    const hasError = Boolean(error);

    // Проверяем, нужно ли отображать класс 'filled'
    const isFilled = value && value.toString().length > 0;

    return (
        <label className="input-label">
            <input
                name={name}
                onChange={handlerChange}
                className={`input ${isFilled ? 'filled' : ''} ${hasError ? 'input-error' : ''}`}
                type={type}
                value={value}
                min={type === 'number' ? min : undefined}
                max={type === 'number' ? max : undefined}
                step={type === 'number' ? step : undefined}
                placeholder={type === 'date' ? label : undefined} // Добавляем placeholder только для типа 'date'
                disabled={disabled} // Передаём пропс disabled в input
            />
            {/* Отображаем span с меткой только если тип не 'date' */}
            {type !== 'date' && <span className="placeholder">{label}</span>}

            {showInfo && (
                <div className="help js--show-info" data-text="Add Icon">
                    <img src="/assets/icon--info-8nUm4QuK.svg" alt="Info"/>
                </div>
            )}
            {showDelete && (
                <div className="help js--show-info" data-text="Add Icon">
                    <button
                        type="button"
                        className="button no-style"
                        onClick={onDelete}
                        style={{
                            backgroundColor: 'transparent',
                        }}
                    >
                        <img src="/assets/icon--delete-gray.svg" alt="Delete"/>
                    </button>
                </div>
            )}

            {/* Отображаем сообщение об ошибке */}
            {error && <span className="error-message">{error}</span>}
        </label>
    );
}
