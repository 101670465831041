// src/components/pages/Accessibility.js

import React from 'react';
import LegalPage from './LegalPage';

export default function Accessibility() {
    return (
        <div>
            <LegalPage pageType="accessibility" />
        </div>
    );
}
