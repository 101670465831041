import React, {useState, useEffect, useCallback} from 'react';
import {Route, Routes, useLocation, useNavigate} from 'react-router-dom';
import Header from './components/Header/Header';
import MainContent from './components/MainContent';
import Popup from './components/Popup';
import PopupAttorney from './components/PopupAttorney';
import PopupVendor from './components/Vendors/PopupVendor';
import {Helmet} from 'react-helmet';
import {SearchProvider} from './context/SearchContext';
import api from './api';
import FeedbackPopup from "./components/popups/FeedbackPopup";
import SendOkPopup from "./components/popups/SendOkPopup";
import SharePopup from "./components/popups/SendSharePopup";
import PageAbout from './components/pages/PageAbout';
import PageLawFirms from './components/pages/PageLawFirms';
import PageVendors from './components/pages/PageVendors';
import SignIn from "./components/User/SignIn";
import UserProfile from "./components/User/UserProfile";
import ForgotPassword from "./components/User/ForgotPassword"; // Импортируйте ваш компонент
import ResetPassword from './components/User/ResetPassword';
import SignUp from "./components/User/SignUp";
import {NotificationProvider} from "./context/NotificationContext"; // Добавьте этот импорт
import Notification from './components/Notification';
import ActivateAccount from "./components/User/ActivateAccount";
import ResendActivationEmail from "./components/User/ResendActivationEmail";
import PrivateRoute from "./components/ProtectedRoute";
import PrivacyPolicy from "./components/pages/PrivacyPolicy";
import TermsOfUse from "./components/pages/TermsOfUse";
import CookiePolicy from "./components/pages/CookiePolicy";
import DMCAPolicy from "./components/pages/DMCAPolicy";
import AttorneyAdvRules from "./components/pages/AttorneyAdvRules";
import SecurityPolicy from "./components/pages/SecurityPolicy";
import {GoogleOAuthProvider} from '@react-oauth/google';
import ProtectedRoute from "./components/ProtectedRoute";
import ActivateTokenAccount from "./components/User/ActivateTokenAccount";
import VerifyEmail from "./components/User/VerifyEmail";
import Accessibility from "./components/pages/Accessibility";
import VerifyPromoEmailToken from "./components/User/VerifyPromoEmailToken";
import VerifyNewPromoEmailToken from "./components/User/VerifyNewPromoEmailToken";


const App = () => {
    const [popupVisible, setPopupVisible] = useState(false);
    const [popupData, setPopupData] = useState(null);
    const [selectedAttorney, setSelectedAttorney] = useState(null);
    const [selectedVendor, setSelectedVendor] = useState(null);
    const location = useLocation();
    const navigate = useNavigate();
    const [historyStack, setHistoryStack] = useState([]);
    const [isIndexPage, setIsIndexPage] = useState(true);
    const [showFeedbackPopup, setShowFeedbackPopup] = useState(false);
    const [showSendOkPopup, setShowSendOkPopup] = useState(false);
    const [showSharePopup, setShowSharePopup] = useState(false);
    const [currentURL, setCurrentURL] = useState('');
    const [companyName, setCompanyName] = useState('');
    const [telephone, setTelephone] = useState('');
    const [address, setAddress] = useState('');
    const [specializations, setSpecializations] = useState([]);
    const [menuKey, setMenuKey] = useState(0);
    const [attorneyOpenedFromPopup, setAttorneyOpenedFromPopup] = useState(false);
    const [popupOrigin, setPopupOrigin] = useState(null);

    const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID; // Используем правильную переменную окружения
    // const clientId = '697800044810-sodjq08oq2q05k5sj0e04rugvj5gkmp4.apps.googleusercontent.com'; // Используем правильную переменную окружения

    useEffect(() => {
        const indexPages = ['/', '/bio', '/law-firms', '/vendors'];
        setIsIndexPage(indexPages.includes(location.pathname));
    }, [location]);

    const handleShowPopup = (id, type, origin = null) => {
        setPopupVisible(false);  // Закрыть любой открытый попап сначала
        setPopupOrigin(origin);  // Установить происхождение попапа


        if (type === 'company') {
            navigate(`/?companyId=${id}`);
            api.get(`/legal-companies/${id}/`)
                .then(response => {
                    setPopupData(response.data);
                    setCompanyName(response.data.name);
                    setTelephone(response.data.phone_number);
                    setAddress(`${response.data.address.street_address}, ${response.data.address.city.name}, ${response.data.address.state.name}, ${response.data.address.country.name}, ${response.data.address.zip_code.zip_code}`);
                    setSpecializations(response.data.specializations.map(spec => spec.name));
                    setPopupVisible(true);
                    // setHistoryStack(prev => [...prev, location.pathname + location.search]);
                    // setCurrentURL(window.location.href);
                    setSelectedAttorney(null);
                    setSelectedVendor(null);
                })
                .catch(error => console.error('Error fetching company data:', error));
        } else if (type === 'attorney') {
            navigate(`/?attorneyId=${id}`);
            api.get(`/attorneys/${id}/`)
                .then(response => {
                    setSelectedAttorney(response.data);
                    setCompanyName(`${response.data.first_name} ${response.data.last_name}`);
                    setTelephone(response.data.phone_number);
                    setAddress(response.data.addresses.map(addr => `${addr.street_address}, ${addr.city.name}, ${addr.state.name}, ${addr.country.name}, ${addr.zip_code.zip_code}`).join('\n'));
                    setSpecializations(response.data.specializations.map(spec => spec.name));
                    setPopupVisible(true);
                    setHistoryStack(prev => [...prev, location.pathname + location.search]);
                    setCurrentURL(window.location.href);
                    setPopupData(null);
                    setSelectedVendor(null);
                })
                .catch(error => console.error('Error fetching attorney data:', error));
        } else if (type === 'vendor') {
            navigate(`/?vendorId=${id}`);
            api.get(`/vendors/${id}/`)
                .then(response => {
                    setSelectedVendor(response.data);
                    setCompanyName(response.data.name);
                    setTelephone(response.data.phone_number);
                    setAddress(`${response.data.address.street_address}, ${response.data.address.city.name}, ${response.data.address.state.name}, ${response.data.address.country.name}, ${response.data.address.zip_code.zip_code}`);
                    setSpecializations(response.data.languages.map(lang => lang.name));
                    setPopupVisible(true);
                    setHistoryStack(prev => [...prev, location.pathname + location.search]);
                    setCurrentURL(window.location.href);
                    setPopupData(null);
                    setSelectedAttorney(null);
                })
                .catch(error => console.error('Error fetching vendor data:', error));
        }
    };

    const handleHidePopup = () => {

        if (selectedAttorney && attorneyOpenedFromPopup) {
            // Закрываем PopupAttorney и снова открываем Popup
            setSelectedAttorney(null);
            setAttorneyOpenedFromPopup(false);
            setPopupVisible(true);

            // Удаляем параметры attorneyId и fromPopup из URL
            const params = new URLSearchParams(location.search);
            params.delete('attorneyId');
            params.delete('fromPopup');
            navigate(`/?${params.toString()}`);
        } else {
            // Оригинальный код закрытия попапов
            setPopupVisible(false);
            setPopupData(null);
            setSelectedAttorney(null);
            setSelectedVendor(null);
            setCompanyName('');
            setTelephone('');
            setAddress('');
            setSpecializations([]);

            if (popupOrigin === 'law-firms') {
                navigate('/law-firms');
                setPopupOrigin(null); // Сбросить состояние
            } else if (historyStack.length > 0) {
                navigate(historyStack[historyStack.length - 1]);
                setHistoryStack(prev => prev.slice(0, -1));
            } else {
                navigate('/');
            }
        }
    };

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const attorneyId = params.get('attorneyId');
        const companyId = params.get('companyId');
        const vendorId = params.get('vendorId');
        const fromPopup = params.get('fromPopup') === 'true';

        if (attorneyId) {
            api.get(`/attorneys/${attorneyId}/`)
                .then(response => {
                    setSelectedAttorney(response.data);
                    setCompanyName(`${response.data.first_name} ${response.data.last_name}`);
                    setTelephone(response.data.phone_number);
                    setAddress(response.data.addresses.map(addr => `${addr.street_address}, ${addr.city.name}, ${addr.state.name}, ${addr.country.name}, ${addr.zip_code.zip_code}`).join('\n'));
                    setSpecializations(response.data.specializations.map(spec => spec.name));
                    setPopupVisible(true);
                    setCurrentURL(window.location.href);
                    setPopupData(null);
                    setSelectedVendor(null);
                    setAttorneyOpenedFromPopup(fromPopup); // Устанавливаем флаг
                })
                .catch(error => console.error('Error fetching attorney data:', error));
        } else {
            setSelectedAttorney(null);
            setAttorneyOpenedFromPopup(false); // Сбрасываем флаг
        }

        if (companyId) {
            api.get(`/legal-companies/${companyId}/`)
                .then(response => {
                    setPopupData(response.data);
                    setCompanyName(response.data.name);
                    setTelephone(response.data.phone_number);
                    setAddress(`${response.data.address.street_address}, ${response.data.address.city.name}, ${response.data.address.state.name}, ${response.data.address.country.name}, ${response.data.address.zip_code.zip_code}`);
                    setSpecializations(response.data.specializations.map(spec => spec.name));
                    setPopupVisible(true);
                    setCurrentURL(window.location.href);
                })
                .catch(error => console.error('Error fetching company data:', error));
        } else {
            setPopupData(null);
        }

        if (vendorId) {
            api.get(`/vendors/${vendorId}/`)
                .then(response => {
                    setSelectedVendor(response.data);
                    setCompanyName(response.data.name);
                    setTelephone(response.data.phone_number);
                    setAddress(`${response.data.address.street_address}, ${response.data.address.city.name}, ${response.data.address.state.name}, ${response.data.address.country.name}, ${response.data.address.zip_code.zip_code}`);
                    setSpecializations(response.data.languages.map(lang => lang.name));
                    setPopupVisible(true);
                    setCurrentURL(window.location.href);
                })
                .catch(error => console.error('Error fetching vendor data:', error));
        } else {
            setSelectedVendor(null);
        }
    }, [location.search]);

    const handlerShowSendOkPopup = useCallback(() => {
        setShowFeedbackPopup(false);
        setShowSendOkPopup(true);
    }, []);

    const handlerCloseOk = useCallback(() => {
        setShowSendOkPopup(false);
    }, []);

    const handlerShowFeebackPopup = useCallback((e) => {
        e.preventDefault();
        setShowFeedbackPopup(true);
    }, []);

    const handlerCloseFeedbackPopup = useCallback(() => {
        setShowFeedbackPopup(false);
    }, []);


    const handlerShowShare = useCallback(() => {
        setShowSharePopup(true);
    }, []);

    const handlerCloseShare = useCallback(() => {
        setShowSharePopup(false);
    }, []);

    return (
        <GoogleOAuthProvider clientId={clientId}>
            <SearchProvider>
                <NotificationProvider>
                    <div className={"page" + (isIndexPage ? ' index' : '')}>
                        <Helmet>
                            <meta charSet="utf-8"/>
                            <title>{popupData ? popupData.name : selectedVendor ? selectedVendor.name : selectedAttorney ? `${selectedAttorney.first_name} ${selectedAttorney.last_name}` : "Law Firm"}</title>
                            <meta property="og:title"
                                  content={popupData ? popupData.name : selectedVendor ? selectedVendor.name : selectedAttorney ? `${selectedAttorney.first_name} ${selectedAttorney.last_name}` : "Law Firm"}/>
                            <meta property="og:description"
                                  content={popupData ? popupData.description : selectedVendor ? selectedVendor.description : selectedAttorney ? selectedAttorney.description : "Description"}/>
                            <meta property="og:image"
                                  content={popupData ? popupData.logo : selectedVendor ? selectedVendor.logo : selectedAttorney ? selectedAttorney.photo : "default.jpg"}/>
                            <meta property="og:url" content={window.location.href}/>
                        </Helmet>
                        <Header showFeedbackPopup={handlerShowFeebackPopup} showSharePopup={handlerShowShare}
                                isIndexPage={isIndexPage}
                                isPopupActive={popupVisible || showFeedbackPopup || showSendOkPopup || showSharePopup}
                        />
                        <header className="header header-inner min">
                            <Notification/> {/* Рендерим Notification глобально */}
                        </header>

                        <Routes>
                            <Route path="/"
                                   element={<MainContent onShowPopup={handleShowPopup}
                                                         isIndexPage={isIndexPage}/>}/>
                            <Route path="/bio" element={<PageAbout/>}/>
                            <Route path="/law-firms" element={<PageLawFirms onShowPopup={handleShowPopup}/>}/>
                            <Route path="/vendors" element={<PageVendors onShowPopup={handleShowPopup}/>}/>
                            <Route path="/sign-in" element={<SignIn/>}/>
                            <Route path="/sign-up" element={<SignUp/>}/>
                            {/*<Route element={<ProtectedRoute/>}>*/}
                            <Route path="/profile" element={<UserProfile onShowPopup={handleShowPopup}/>}/>
                            {/*</Route>*/}
                            <Route path="/forgot-password" element={<ForgotPassword/>}/>
                            <Route path="/reset-password/:uid/:token" element={<ResetPassword/>}/>
                            <Route path="/activate/:uidb64/:token/" element={<ActivateAccount/>}/>
                            <Route path="/activate-email-token/:token/" element={<ActivateTokenAccount/>}/>
                            <Route path="/resend-activation-email" element={<ResendActivationEmail/>}/>
                            <Route path="/privacy-policy" element={<PrivacyPolicy/>}/>
                            <Route path="/terms-of-use" element={<TermsOfUse/>}/>
                            <Route path="/cookie-policy" element={<CookiePolicy/>}/>
                            <Route path="/dmca-policy" element={<DMCAPolicy/>}/>
                            <Route path="/attorney-adv-rules" element={<AttorneyAdvRules/>}/>
                            <Route path="/security-policy" element={<SecurityPolicy/>}/>
                            <Route path="/accessibility" element={<Accessibility/>}/>
                            <Route path="/verify-email/:token/" element={<VerifyEmail/>}/>
                            <Route path="/activate-promo-email-token/:token" element={<VerifyPromoEmailToken/>}/>
                            <Route path="/activate-new-promo-email-token/:token" element={<VerifyNewPromoEmailToken/>}/>

                        </Routes>
                        {popupVisible && !!popupData && (
                            <Popup
                                visible={popupVisible}
                                data={popupData}
                                origin={popupOrigin} // Передаём origin
                                onClose={handleHidePopup}
                                showSharePopup={() => handlerShowShare({
                                    companyName,
                                    telephone,
                                    address,
                                    specializations
                                })}
                                handleShowPopup={handleShowPopup} // Передаем функцию в Popup
                            />

                        )}
                        {popupVisible && selectedAttorney && (
                            <PopupAttorney
                                visible={popupVisible}
                                attorney={selectedAttorney}
                                onClose={handleHidePopup}
                                showSharePopup={() => handlerShowShare({
                                    companyName: `${selectedAttorney.first_name} ${selectedAttorney.last_name}`,
                                    telephone: selectedAttorney.phone_number,
                                    address: selectedAttorney.addresses.map(addr => `${addr.street_address}, ${addr.city.name}, ${addr.state.name}, ${addr.country.name}, ${addr.zip_code.zip_code}`).join('\n'),
                                    specializations: selectedAttorney.specializations.map(spec => spec.name)
                                })}
                            />
                        )}
                        {popupVisible && selectedVendor && (
                            <PopupVendor
                                visible={popupVisible}
                                vendor={selectedVendor}
                                onClose={handleHidePopup}
                                showSharePopup={() => handlerShowShare({
                                    companyName: selectedVendor.name,
                                    telephone: selectedVendor.phone_number,
                                    address: `${selectedVendor.address.street_address}, ${selectedVendor.address.city.name}, ${selectedVendor.address.state.name}, ${selectedVendor.address.country.name}, ${selectedVendor.address.zip_code.zip_code}`,
                                    specializations: selectedVendor.languages.map(lang => lang.name)
                                })}
                                // onEditProfile={handlerShowEditProfilePopup}
                            />
                        )}
                        {showFeedbackPopup &&
                            <FeedbackPopup show={showFeedbackPopup} showSendOkPopup={handlerShowSendOkPopup}
                                           closeFeedbackPopup={handlerCloseFeedbackPopup}/>}
                        {showSendOkPopup && <SendOkPopup closePopup={handlerCloseOk}/>}
                        {showSharePopup &&
                            <SharePopup closePopup={handlerCloseShare} shareAddress={currentURL}
                                        copyEmbed='Embed code'
                                        companyName={companyName} telephone={telephone} address={address}
                                        specializations={specializations}/>}

                    </div>
                </NotificationProvider>
            </SearchProvider>
        </GoogleOAuthProvider>

    );
};

export default App;